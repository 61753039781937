import React from 'react';
import { Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@mui/material';

interface PropsType {
  children: React.ReactNode;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  sx?: any;
  formik: any;
}

const getNestedValue = (obj: any, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const FormSelect: React.FC<PropsType> = ({
  formik,
  name,
  label,
  required = false,
  sx = {},
  children,
  disabled = false,
  multiple = false,
  ...other
}) => {
  const fieldProps = formik.getFieldProps(name);
  const error = Boolean(getNestedValue(formik.touched, name) && getNestedValue(formik.errors, name));
  const helperText = getNestedValue(formik.touched, name) && getNestedValue(formik.errors, name);
  const ariaDescribedBy = error ? `${name}-helper-text` : undefined;

  return (
    <FormControl
      error={error}
      disabled={disabled}
      sx={{
        mx: 1,
        ...sx, // Merge any custom styles passed as props
      }}
    >
      <InputLabel id={`${name}-label`}>{required ? `${label} *` : label}</InputLabel>
      <Select
        id={name}
        labelId={`${name}-label`}
        name={name}
        label={required ? `${label} *` : label} // Add asterisk if required
        multiple={multiple}
        data-cy={name}
        aria-describedby={ariaDescribedBy}
        {...fieldProps}
        {...other}
      >
        {children}
      </Select>
      {error && (
        <FormHelperText id={ariaDescribedBy}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormSelect;
