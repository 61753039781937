import {
  Button,
  Dialog,
  DialogActions,
  FormHelperText,
  TextField
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { emptyObject } from '../../../helper-functions';
import DialogTitle from '../../DialogTitle';
import { btn_style, clinic_style } from './addHealthTargets_style';

// this return the Cancel and Save btns at the bottom of modal.
function DisplayActionBtns(props) {
  const {
    toggleModal, handleSubmit, handleReset, err, validDate,
  } = props;
  const { i18n, t } = useTranslation();
  return (
    <DialogActions>
      <Button
        onClick={() => {
          handleReset();
          toggleModal(true);
        }}
        color="primary"
        style={btn_style.msgCancelDialogBtn}
        data-cy="cancelBtn"
      >
        {t('Cancel')}
      </Button>
      <Button
        color="primary"
        autoFocus
        onClick={() => {
          handleSubmit();
          toggleModal(false);
        }}
        disabled={err || !validDate}
      >
        {t('Save')}
      </Button>
    </DialogActions>
  );
}

// this return the form inputs AND validates
const InputGroup = (props) => {
  const {
    configArr, form, setVal, latest,
  } = props;
  const isValid = true;
  const { i18n, t } = useTranslation();

  // validation for min and max
  // needs to be a int or float, does not allow 10.0.something
  //
  const validateType = (event, name, type) => {
    // Trim string and replace commas
    const trimmed = event.target.value.replace(',', '.').trim();
  
    // Unary validation +StrVal
    const unariVal = +trimmed;
  
    if (trimmed !== '') {
      if (Number.isNaN(unariVal)) {
        if (type === 'lowerRange_1') {
          form.values[name].isMinValid = false;
        }
        if (type === 'upperRange_1') {
          form.values[name].isMaxValid = false;
        }
      } else {
        if (type === 'lowerRange_1') {
          form.values[name].isMinValid = true;
        }
        if (type === 'upperRange_1') {
          form.values[name].isMaxValid = true;
        }
      }
    }
  
    if (unariVal === 0) {
      if (type === 'lowerRange_1') {
        form.values[name].isMinValid = true;
      }
      if (type === 'upperRange_1') {
        form.values[name].isMaxValid = true;
      }
    }
  };
  

  // handle values for both min max fields
  const handleChange = (event, name, type) => {
    if (form.values[name][type].length < 8) {
      form.values[name][type] = event.target.value.replace(',', '.').trim();

      setVal({ ...form.values });
      validateType(event, name, type);
    }
  };

  /// validates onBlur to avoid having error as soon as we type
  const handleBlur = (event, name, type) => {
    if (form.values[name].upperRange_1 && form.values[name].lowerRange_1) {
      if (form.values[name].upperRange_1 - form.values[name].lowerRange_1 < 0) {
        form.values[name].isMaxValid = false;
      }
      if (form.values[name].upperRange_1 - form.values[name].lowerRange_1 >= 0) {
        form.values[name].isMaxValid = true;
      }
    }
    setVal({ ...form.values });
  };

  return (
    <div style={clinic_style.textWrapper}>
      {configArr.map((el, idx) => (
        <div style={clinic_style.contentBlock} key={idx}>
          <label
            htmlFor={`M_${el.short_code}`}
            style={isValid ? clinic_style.titleLabel : clinic_style.titleError}
          >
            {i18n.resolvedLanguage === 'en' ? el.name_en : el.name_fr}
            <div style={clinic_style.unitTitle}>
              {' '}
              {el.unit ? el.unit : '----'}
              {' '}
            </div>
            <div style={clinic_style.flexWrap}>
              <TextField
                data-cy={`M_${el.short_code}_min`}
                placeholder="----"
                value={form.values[`M_${el.short_code}`].lowerRange_1}
                name={`${el.short_code}_min`}
                onChange={(e, name) => handleChange(e, `M_${el.short_code}`, 'lowerRange_1')}
                error={!form.values[`M_${el.short_code}`].isMinValid}
                variant="outlined"
                margin="dense"
                InputProps={{
                  style: !isValid ? clinic_style.textInputFieldError : clinic_style.textInputField,
                }}
              />
              <span style={clinic_style.unit}>Min</span>
              <TextField
                data-cy={`M_${el.short_code}_max`}
                placeholder="----"
                value={form.values[`M_${el.short_code}`].upperRange_1}
                name={`${el.short_code}_max`}
                onChange={(e, name) => handleChange(e, `M_${el.short_code}`, 'upperRange_1')}
                onBlur={(e, name) => handleBlur(e, `M_${el.short_code}`, 'upperRange_1')}
                error={!form.values[`M_${el.short_code}`].isMaxValid}
                variant="outlined"
                margin="dense"
                InputProps={{
                  style: !isValid ? clinic_style.textInputFieldError : clinic_style.textInputField,
                }}
              />
              <span style={clinic_style.unit}>Max</span>
            </div>
            {!form.values[`M_${el.short_code}`].isMinValid
              || !form.values[`M_${el.short_code}`].isMaxValid ? (
                <FormHelperText
                  id={`M_${el.short_code}_helper`}
                  data-cy={`M_${el.short_code}_helper`}
                  style={clinic_style.errorDateSelect}
                >
                  {t('Invalid format')}
                </FormHelperText>
              ) : (
                <div style={clinic_style.latest} data-cy={`M_${el.short_code}_init`}>
                  {' '}
                  {t('Current')}
                  :
                  {'  '}
                  <span style={clinic_style.latestMin} data-cy={`M_${el.short_code}_initMin`}>
                    {latest[`M_${el.short_code}`] ? (
                      <span style={{}}>
                        {' '}
                        {latest[`M_${el.short_code}`].target_min}
                        {' '}
                      </span>
                    ) : (
                      '----'
                    )}
                    {' '}
                    Min
                    {' '}
                    {latest[`M_${el.short_code}`] ? (
                      <span style={{}}>
                        {' '}
                        {latest[`M_${el.short_code}`].target_max}
                      </span>
                    ) : (
                      '----'
                    )}
                    {' '}
                    Max
                    {' '}
                  </span>
                  {' '}
                </div>
              )}
          </label>
        </div>
      ))}
    </div>
  );
};

// this returns the Modal with both component up here.
const AddLabThreshWrapper = (props) => {
  const {
    configArr,
    form,
    setVal,
    handleSubmit,
    handleReset,
    handleDateChange,
    selectedDate,
    validDate,
    err,
    latest,
  } = props;

  const [open, setOpen] = useState(false);
  const { i18n, t } = useTranslation();
  const { edit } = useSelector((state) => state.permissions);

  // resets is a boolean if you want to close and keep value or close and reset values.
  const handleOpen = (resets) => {
    setOpen(!open);
    if (resets) {
      handleReset();
      handleDateChange(moment());
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpen()}
        disabled={!edit}
      >
        {!emptyObject(latest) ? t('Edit Health Targets') : t('Add Health Targets')}
      </Button>
      {open && (
        <Dialog
          open={open}
          onBackdropClick={handleOpen}
          disableEnforceFocus
          maxWidth={false}
          cy-test="Dialog"
        >
          <DialogTitle onClose={handleOpen}>
            {!emptyObject(latest) ? t('Edit Health Targets') : t('Add Health Targets')}
          </DialogTitle>
          <div style={clinic_style.contentWrapper}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                label="Date"
                name="time"
                disableFuture
                value={moment(selectedDate)}
                onChange={(date) => handleDateChange(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <InputGroup configArr={configArr} form={form} setVal={setVal} latest={latest} />
          </div>
          <DisplayActionBtns
            toggleModal={handleOpen}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
            validDate={validDate}
            dirty={form.dirty}
            err={err}
          />
        </Dialog>
      )}
    </>
  );
};

export default AddLabThreshWrapper;
