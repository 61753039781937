import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import RuleIcon from '@mui/icons-material/Rule';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  Box, Divider, IconButton,
  Tooltip, Typography,
} from '@mui/material';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useNavigate, Link } from 'react-router-dom';
import { greyboxApiActions } from '../../../redux/api';
import { QuestionnaireManagementClinicType } from '../../../types';

interface QuestionnairesDetailsProps {
  patientUuid: string,
  questionnaireClinic: QuestionnaireManagementClinicType;
  index: number;
  setTestId: (id: number) => void;
  moveSection: (dragIndex: number, hoverIndex: number) => void;
  orders: number[];
  refetch: () => void;
}

const QuestionnaireDetails = (props: QuestionnairesDetailsProps) => {
  const { questionnaireManagementClinic } = greyboxApiActions;
  const { t } = useTranslation();
  const {
    patientUuid, questionnaireClinic, index, moveSection, orders,
    refetch, setTestId,
  } = props;
  const [updateSectionOrder] = questionnaireManagementClinic.update();
  const [copyQuestionnaire] = questionnaireManagementClinic.add();
  const [deleteQuestionnaire] = questionnaireManagementClinic.delete();
  const navigate = useNavigate();
  const ref = React.useRef(null);
  const { questionnaire } = questionnaireClinic;

  const handleCopy = () => {
    copyQuestionnaire({
      id: `${questionnaireClinic.id}/copy_questionnaireclinic`,
    });
  };

  const [{ handlerId }, drop] = useDrop({
    accept: 'questionnaire',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveSection(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item: any) {
      updateSectionOrder({
        id: questionnaireClinic.id,
        body: { order: orders[item.index] },
      }).then(() => {
        refetch();
      });
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'questionnaire',
    item: () => ({ id: questionnaireClinic.id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Box
      sx={{
        p: 2,
        mb: 1,
        opacity,
        cursor: isDragging ? 'grabbing' : 'grab',
        borderRadius: 2,
        border: (theme) => (`1px solid ${theme.palette.divider}`),
      }}
      ref={ref}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <DragHandleIcon sx={{ mr: 1 }} />
        <Typography variant="h6">{questionnaire.name}</Typography>
        <Box sx={{ ml: 'auto' }}>
          <Tooltip title={t('Edit')}>
            <IconButton onClick={() => navigate(`/clinic-settings/questionnaires/${questionnaireClinic.id}`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('Create a copy')}>
            <IconButton onClick={handleCopy}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('Test')}>
            <IconButton
              disabled={!questionnaireClinic.active || questionnaireClinic.questions_count === 0}
              onClick={() => setTestId(questionnaire.id)}
            >
              <RuleIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Link to={`/clinic-settings/questionnaires/${questionnaireClinic.id}/questions`} style={{ textDecoration: 'none' }}>
        <Typography variant="body2" sx={{ color: 'text.secondary', '&:hover': { textDecoration: 'underline' } }}>
          {questionnaireClinic.questions_count}
          {' '}
          {questionnaireClinic.questions_count > 1 ? t('questions') : t('question')}
        </Typography>
      </Link>
      <Divider sx={{ my: 1 }} />
      <Box sx={{ fontFamily: (theme) => theme.typography.fontFamily, px: 1, fontSize: '1rem' }}>
        <ReactMarkdown>{questionnaire.description}</ReactMarkdown>
      </Box>
    </Box>
  );
};

export default QuestionnaireDetails;
