import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import { ALERT_CONFIGS, countActiveAlerts, mergeActiveAndClearedAlerts } from './utils';

const AlertAreaChart = ({ type , patientUuid}) => {
  const dateRange = useSelector((state) => state.patientProfileDateRange);
  const { startDate, range } = dateRange;
  const endDate = dateRange.endDate || moment();
  const { clinic } = useSelector((state) => state.clinic);
  const selector = greyboxApiActions[type];
  const clearedAlerts = selector.list({
    patient: patientUuid,
    status: 'cleared',
    clinic: clinic.id,
    last_modify__range: range,
  });
  const activeAlerts = selector.list({ status: 'active', patient: patientUuid, clinic: clinic.id });

  if (activeAlerts.isFetching || clearedAlerts.isFetching) {
    return <Skeleton variant="rect" width="100%" height="35vh" />;
  }

  const alerts = mergeActiveAndClearedAlerts(activeAlerts.data, []);
  let parsedAlerts = countActiveAlerts(alerts, startDate, endDate);

  if (parsedAlerts.length === 0 && activeAlerts.data.length > 0) {
    parsedAlerts = [{
      x: startDate,
      y: activeAlerts.data.length,
    },
    {
      x: endDate,
      y: activeAlerts.data.length,
    }];
  }

  const discrete = [];
  parsedAlerts.forEach((a, i) => {
    if (a.status === 'cleared') {
      if (discrete.length === 0
        || !moment(a.x).isSame(moment(discrete[discrete.length - 1].x), 'minute')) {
        discrete.push({
          seriesIndex: 0,
          x: a.x,
          dataPointIndex: i,
          fillColor: 'white',
          size: 5,
          strokeColor: '#09bbd0',
          strokeWidth: 3,
          shape: 'circle',
        });
      }
    }
  });

  const options = {
    series: [{
      name: ALERT_CONFIGS[type].pieLabel,
      data: parsedAlerts,
    }],
    title: {
      text: ALERT_CONFIGS[type].label,
      align: 'left',
    },
    stroke: {
      curve: 'stepline',
    },
    colors: ['#09bbd0'],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      min: 0,
    },
    legend: {
      horizontalAlign: 'left',
    },
    tooltip: {
      x: {
        format: 'dd MMM HH:mm',
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={options.series}
      type="area"
      height="100%"
    />
  );
};

export default AlertAreaChart;
