import {
  Box,
  Button,
  Popover,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertCircle, FiInfo } from "react-icons/fi";
import { XIcon } from '../../../tk-ui';

// Helper function to create sentences based on the operator
const createSentence = (operator, t) => {
  let opSentence = null;
  if (operator === '<=') opSentence = t('Should not be over');
  if (operator === '=') opSentence = '';
  if (operator === '<') opSentence = t('Should be below');
  if (operator === '>=') opSentence = t('Should not be lower than');
  if (operator === '>') opSentence = t('Should be over');

  return opSentence;
};

// Component for rendering warnings
const ContraWarning = ({
  molecule,
  target,
  unit,
  operator,
  latestValue,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: 'relative',
        mt: '12px',
        width: 'calc(100% - 15px)',
        height: '52px',
        bgcolor: '#363636',
        pl: '15px',
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      key={molecule}
      cy-data="sectionWrapperHover"
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '4px',
          height: '52px',
          background: '#f49342',
          borderTopLeftRadius: '2px',
          borderBottomLeftRadius: '2px',
        }}
        cy-data="warningBar"
      />
      <Box cy-data="warningIconWrapper">
        <Typography
          sx={{
            fontSize: '15px',
            color: '#f49342',
            minWidth: '345px',
            fontWeight: 400,
          }}
          cy-data="warningTitle"
        >
          {molecule}:{' '}
          <Box
            component="span"
            sx={{ fontWeight: 500 }}
            cy-data="latestValue"
          >
            {latestValue === true
              ? t('Positive diagnosis')
              : `${latestValue} ${unit}`}
          </Box>
        </Typography>
      </Box>

      <Typography
        sx={{
          fontSize: '13px',
          color: '#e7eaed',
          width: '300px',
          fontWeight: 400,
        }}
        cy-data="recommendations"
      >
        {createSentence(operator, t)}{' '}
        <Box
          component="span"
          sx={{ fontWeight: 500, color: 'white' }}
          cy-data="warningTarget"
        >
          {target} {unit}
        </Box>
      </Typography>
    </Box>
  );
};

// Component for rendering clear statuses
const ContraClear = ({
  molecule,
  target,
  unit,
  operator,
  latestValue,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: 'relative',
        mt: '12px',
        width: 'calc(100% - 15px)',
        height: '52px',
        bgcolor: '#363636',
        pl: '15px',
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      key={molecule}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '4px',
          height: '52px',
          background: '#b5e2a1',
          borderTopLeftRadius: '2px',
          borderBottomLeftRadius: '2px',
        }}
      />
      <Box sx={{ justifyContent: 'center' }}>
        <Typography
          sx={{
            fontSize: '15px',
            color: '#b5e2a1',
            minWidth: '345px',
            fontWeight: 400,
          }}
        >
          {molecule}:{' '}
          <Box component="span" sx={{ fontWeight: 500 }}>
            {latestValue === false
              ? t('Negative diagnosis')
              : `${latestValue} ${unit}`}
          </Box>
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: '13px',
          color: '#e7eaed',
          width: '300px',
          fontWeight: 400,
        }}
      >
        {createSentence(operator, t)}{' '}
        <Box component="span" sx={{ fontWeight: 500, color: 'white' }}>
          {target} {unit}
        </Box>
      </Typography>
    </Box>
  );
};

// Function to determine which component to render based on data
const qualityCheck = (
  el,
  molecule,
  target,
  unit,
  operator,
  latestValue,
  t,
) => {
  if (!el.displayWarning && el.currentPatientValue) {
    return (
      <ContraClear
        molecule={molecule}
        target={target}
        unit={unit}
        operator={operator}
        latestValue={latestValue}
      />
    );
  }
  if (el.displayWarning) {
    return (
      <ContraWarning
        molecule={molecule}
        target={target}
        unit={unit}
        operator={operator}
        latestValue={latestValue}
      />
    );
  }
  return null;
};

// Main Contraindications component
const Contraindications = (props) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    medName = '',
    elevation = 0,
    onCards,
    indexBox,
    contraindications,
    warning,
    drugclass,
    btnStyle,
  } = props;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!contraindications) return null;
  const warningCount = contraindications.filter(
    (el) => el.displayWarning === true,
  ).length;

  return (
    <>
      <Popover
        id="contraindications-popper"
        cy-data="popover"
        open={open}
        elevation={elevation}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableRestoreFocus
        PaperProps={{
          sx: {
            transform: 'translateY(-8px) translateX(0px)',
            boxSizing: 'border-box',
          },
        }}
      >
        <Box
          sx={{
            minWidth: '330px',
            minHeight: '80px',
            p: '12px',
            bgcolor: '#454545',
            cursor: onCards ? 'pointer' : 'default',
          }}
          role="button"
          tabIndex={0}
          onClick={() => {
            !onCards && handleClose();
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '8px',
              right: '7px',
              cursor: 'pointer',
            }}
            onClick={handleClose}
            role="button"
            tabIndex={0}
          >
            <XIcon fills="#babdbf" />
          </Box>

          <Typography
            sx={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '1.19',
              textAlign: 'left',
              color: '#fafafa',
            }}
            cy-data="headerText"
          >
            {drugclass ? `${drugclass[i18n.resolvedLanguage]} -` : null}{' '}
            {t('Caution')}
            {medName ? (
              <Box
                component="span"
                sx={{
                  fontSize: '13px',
                  textTransform: 'capitalize',
                  opacity: 0.5,
                }}
              >
                {' '}
                - {medName.toLowerCase()}{' '}
              </Box>
            ) : null}
          </Typography>
          <Box>
            {contraindications &&
              contraindications.map((el, index) => (
                <Box key={index}>
                  {qualityCheck(
                    el,
                    el.name[i18n.resolvedLanguage],
                    el.var2,
                    el.unit,
                    el.op,
                    el.currentPatientValue,
                    t,
                  )}
                </Box>
              ))}
          </Box>
        </Box>
      </Popover>
      {onCards ? (
        warning ? (
          <Button
            aria-owns={open ? 'contraindications-popper' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            variant='contained'
            color='warning'
            disableElevation
            startIcon={<FiAlertCircle />}
            sx={{my:1}}
          >
            {warningCount > 1 ? t('CAUTIONS') : t('CAUTION')}
          </Button>
        ) : (
          <Button
            aria-owns={open ? 'contraindications-popper' : undefined}
            cy-data={`contraindications-btn${indexBox}`}
            aria-haspopup="true"
            onClick={handleClick}
            variant='contained'
            color='success'
            disableElevation
            startIcon={<FiInfo />}
          >
            {warningCount > 1 ? t('CAUTIONS') : t('CAUTION')}
          </Button>
        )
      ) : (
        <Box
          component="span"
          aria-owns={open ? 'table-popper' : undefined}
          cy-data={`contraindications-btn${indexBox}`}
          aria-haspopup="true"
          role="button"
          tabIndex={0}
          onClick={handleClick}
          sx={btnStyle}
        />
      )}
    </>
  );
};

export default Contraindications;
