import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import { RichFormText } from '../../form-inputs';
import UnsavedWarning from '../../modals/UnsavedWarning';

const useStyles = makeStyles((theme) => ({
  spacer: {
    marginBottom: theme.spacing(2),
  },
  editorContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2, 2, 2, 3),
    minHeight: '100px',
    marginBottom: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  dialog: {
    overflow: 'hidden',
  },
  content: {
    height: 'auto !important',
    maxHeight: 'calc(100vh - 400px) !important',
  },
  field: {
    width: '50%',
  },
  readOnly: {
    minHeight: '100px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    padding: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
  },
}));

const ClinicalNoteModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { clinicalImpression } = greyboxApiActions;
  const [updateImpression, updateResult] = clinicalImpression.update();
  const [createImpression, createResult] = clinicalImpression.add();
  const [unsavedOpen, setUnsavedOpen] = useState(false);
  const { accountId } = useSelector((state) => state.user);
  const {
    patientUuid,
    open,
    handleClose,
    note = null,
  } = props;

  const isNewNote = !note;
  const isLoading = createResult.isLoading || updateResult.isLoading;

  const statusOptions = [{
    value: 'in-progress',
    label: t('In Progress'),
  },
  {
    value: 'completed',
    label: t('Completed'),
  },
  {
    value: 'entered-in-error',
    label: t('Entered in Error'),
  }];

  const formik = useFormik({
    initialValues: {
      summary: isNewNote ? '' : note.summary,
      description: isNewNote ? '' : note.description,
      status: isNewNote ? 'in-progress' : note.status,
    },
    validationSchema: yup.object({
      summary: yup.string().required(t('Summary is required')),
      description: yup.string().required(t('Description is required')),
    }),
    onSubmit: (values) => {
      const body = {
        ...values,
        assessor: {
          reference: `Practitioner/${accountId}`,
        },
      };

      if (isNewNote) {
        body.subject = {
          reference: `Patient/${patientUuid}`,
        };
        createImpression({ body }).then(() => {
          handleClose(true);
        });
      } else {
        updateImpression({ id: note.id, body }).then(() => {
          handleClose(true);
        });
      }
    },
  });

  const handleUnsavedChanges = () => {
    if (formik.dirty) {
      setUnsavedOpen(true);
    } else {
      handleClose(false);
    }
  };

  const handleUnsavedClose = (shouldClose) => {
    if (shouldClose) {
      handleClose(false);
    }
    setUnsavedOpen(false);
  };

  return (
    open && (
      <>
        <Dialog
          open={open}
          onClose={() => handleUnsavedChanges()}
          maxWidth="md"
          className={classes.dialog}
          disableEnforceFocus
          fullWidth
        >
          <DialogTitle className={classes.title}>
            {isNewNote ? t('Create note') : t('Edit note')}
          </DialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent className={classes.content} dividers>
              <TextField
                label={t('Summary')}
                id="summary"
                name="summary"
                required
                sx={{ mb: 1 }}
                className={classes.spacer}
                inputProps={
                  { readOnly: !isNewNote && note.status === 'completed' }
                }
                fullWidth
                variant="outlined"
                value={formik.values.summary}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.summary && Boolean(formik.errors.summary)}
                helperText={formik.touched.summary && formik.errors.summary}
              />
              <div className={classes.spacer}>
                {!isNewNote && note.status === 'completed' ? (
                  <div className={classes.readOnly}>
                    <ReactMarkdown>
                      {note.description}
                    </ReactMarkdown>
                  </div>
                ) : (
                  <RichFormText
                    formik={formik}
                    name="description"
                    label={`${t('Description')}*`}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                )}
              </div>
              <TextField
                select
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                label={t('Status')}
                id="status"
                name="status"
                className={classes.field}
                inputProps={
                  { readOnly: !isNewNote && note.status === 'completed' }
                }
                value={formik.values.status}
                variant="outlined"
                onChange={formik.handleChange}
              >
                {statusOptions.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleUnsavedChanges()} disabled={isLoading}>
                {t('Cancel')}
              </Button>
              <LoadingButton loading={isLoading} type="submit">
                {t('Save')}
              </LoadingButton>
            </DialogActions>
          </form>
        </Dialog>
        {unsavedOpen && (
          <UnsavedWarning handleClose={handleUnsavedClose} open={unsavedOpen} />
        )}
      </>
    )
  );
};

export default ClinicalNoteModal;
