import TrashIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikTextField from '../../form-inputs/FormikTextField';
import ContactTelecom from './ContactTelecom';

const ContactForm = ({ contact, index, removeContact }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box sx={{
      p: 1,
      border: `1px solid ${theme.palette.divider}`,
      mb: 1,
      borderRadius: '5px',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={() => removeContact(index)}>
          <TrashIcon />
        </IconButton>
      </Box>
      <Box sx={{ 
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr', 
        gap: 2, // Adjust gap as needed
        mb: 2 
      }}>
        <FormikTextField name={`contact.${index}.firstName`} required label={t('First Name')} />
        <FormikTextField name={`contact.${index}.lastName`} required label={t('Last Name')} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <FormikTextField name={`contact.${index}.relationship`} required label={t('Relationship')} />
      </Box>
      <ContactTelecom contactIndex={index} />
    </Box>
  );
};

export default ContactForm;
