import React from 'react';
import { TextField, InputAdornment, Tooltip } from '@mui/material';
import QuestionIcon from '@mui/icons-material/HelpOutline';
import { useField } from 'formik';

const FormikTextField = ({
  name,
  label,
  tooltipText,
  required = false,
  disabled = false,
  type = 'text',
  ...props // Use spread operator to pass all additional props
}) => {
  const [field, meta] = useField(name);

  const error = Boolean(meta.touched && meta.error);
  const helperText = meta.touched && meta.error;
  const ariaDescribedBy = error ? `${name}-helper-text` : undefined;

  return (
    <TextField
      id={name}
      data-cy={name}
      {...field}
      {...props} // Pass additional props directly to TextField
      value={field.value || ''}
      label={required ? `${label} *` : label}
      disabled={disabled}
      type={type}
      error={error}
      helperText={helperText}
      variant="outlined"
      InputLabelProps={{
        shrink: type === 'date' || type === 'time' ? true : undefined, // Conditionally apply shrink if type is 'date'
        ...props.InputLabelProps, // Allow customization of InputLabelProps
      }}
      InputProps={{
        endAdornment: (
          tooltipText && (
            <InputAdornment position="end" sx={{ cursor: 'help' }}>
              <Tooltip title={tooltipText} arrow>
                <QuestionIcon />
              </Tooltip>
            </InputAdornment>
          )
        ),
        ...props.InputProps, // Ensure InputProps can be overridden if passed
      }}
      FormHelperTextProps={{ id: ariaDescribedBy, ...props.FormHelperTextProps }} // Allow customization of helper text
    />
  );
};

export default FormikTextField;
