import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  MenuItem,
  TextField, useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { greyboxApiActions } from '../../redux/api';
import { setCurrentClinic } from '../../redux/clinic';


const parseData = (data, companies) => {
  if (!data.results) return [];

  return (data.results.map((item) => ({
    id: item.uuid,
    key: `${item.id}-${item.company[0]}`,
    label: `${item.firstName} ${item.lastName}`,
    company: companies.find((company) => company.id === item.company[0])?.name,
    companyId: item.company[0],
  })));
};

const SearchAccountFieldDeskTop = ({ }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { account, company } = greyboxApiActions;
  const { access } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const [query, setQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 350);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);  // Ref to access the input element

  const companies = company.list({});

  const { data = {}, isFetching, isError } = account.list(
    {
      page_size: 10,
      search: debouncedQuery,
      acc_type: 'P',
    },
    {
      skip: access === 'P' || !debouncedQuery,
    },
  );

  const handleClick = (accountId, companyId) => {
    setQuery('');

    if (companyId && companyId !== clinic.id) {
      dispatch(setCurrentClinic(companies.data.find((item) => item.id === companyId)));
    }
    navigate(`/patient-profile/${accountId}/overview`);
  };

  const handleInputChange = (event, newInputValue) => {
    setQuery(newInputValue);
  };

  useEffect(() => {
    if (query) {
      setOpen(true);
    }
    else {
      setOpen(false);
      inputRef.current?.blur();
    }
  }, [query]);

  return (
    access === 'PT' && (
      <Autocomplete
        freeSolo
        open={open}
        sx={{
          mt: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
        options={parseData(data, companies.data)}
        filterOptions={(x) => x}
        blurOnSelect
        clearOnBlur
        handleHomeEndKeys
        onInputChange={handleInputChange}
        noOptionsText={t('No patient found')}
        loadingText={`${t('Loading')}...`}
        loading={isFetching}
        renderOption={(props, option) => (
          <MenuItem key={option.key} onClick={() => handleClick(option.id, option.companyId)}>
            {option.label}
            {option.company && (
              <Chip sx={{ ml: 'auto' }} label={option.company} size="small" />
            )}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef} // Set the ref to the input element
            size="small"
            placeholder={t('Search patient')}
            variant="outlined"
            sx={{
              width: '250px', // Smaller width for non-focus state
              margin: 'auto',
              transition: 'width 0.3s ease-in-out',
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px', // Ensure border-radius is applied to the correct element
              },
              '&:focus-within': {
                width: '450px', // Expanded width on focus
              },
              '@media (max-width: 600px)': {
                width: '100%', // Adjust width for mobile
                '&:focus-within': {
                  width: '100%', // Adjust width for mobile on focus
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <SearchIcon
                  sx={{
                    mr: 1,
                    color: 'grey.500', // Adjust the color
                    fontSize: '1.2rem', // Adjust the size
                    verticalAlign: 'middle', // Align the icon with the text
                  }}
                />
              ),
            }}
          />
        )}
      />
    )
  );
};

const SearchAccountField = ({ isButton = false, setOpen }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isButton) {
    return (
      <IconButton sx={{ mr: 1 }} onClick={() => setOpen(true)}>
        <SearchIcon />
      </IconButton>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isMobile && (
        <IconButton onClick={() => setOpen(false)} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
      )}
      <Box sx={{ flexGrow: 1, mr: 2 }}>
        <SearchAccountFieldDeskTop />
      </Box>
    </Box>
  );
};

export default SearchAccountField;
