import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
    Button, TextField, Box, Collapse, Typography, Select, MenuItem
} from '@mui/material';
import { Add, Edit, Delete, ExpandMore, ExpandLess } from '@mui/icons-material';
import WorkflowEditor from './WorkflowEditor'; // Make sure to import your WorkflowEditor component

const WorkflowDetails = ({ workflow, handleChange }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false); // Collapsed by default
    const [editing, setEditing] = useState(null);
    const [inputs, setInputs] = useState(workflow.input);

    useEffect(() => {
        setInputs(workflow.input)
    }, [workflow])

    const [inputData, setInputData] = useState({
        inputName: '',
        inputType: '',
        inputValue: ''
    });

    const inputTypeChoices = ['datetime', 'number', 'string', 'duration'];

    const handleToggleExpand = () => {
        setExpanded(!expanded);
    };

    const handleDialogOpen = (index = null) => {
        if (index !== null) {
            const inputKey = Object.keys(inputs)[index];
            const input = inputs[inputKey];
            setInputData({
                inputName: input.display?.en || inputKey,
                inputType: input.type,
                inputValue: input.value || ''
            });
        } else {
            setInputData({
                inputName: '',
                inputType: '',
                inputValue: ''
            });
        }
        setEditing(index);
        setOpen(true);
        if (!expanded) {
            setExpanded(true);
        }
    };

    const handleDialogClose = () => {
        setOpen(false);
        setEditing(null);
    };

    const handleAddOrEdit = () => {
        const newInputs = { ...inputs };
        if (editing !== null) {
            const inputKey = Object.keys(inputs)[editing];
            newInputs[inputKey] = {
                ...newInputs[inputKey],
                type: inputData.inputType,
                value: inputData.inputValue,
                display: {
                    ...newInputs[inputKey].display,
                    en: inputData.inputName
                }
            };
        } else {
            const newKey = inputData.inputName.toLowerCase().replace(/\s+/g, '_');
            newInputs[newKey] = {
                type: inputData.inputType,
                value: inputData.inputValue,
                display: {
                    en: inputData.inputName
                }
            };
        }
        setInputs(newInputs);
        handleDialogClose();
    };

    const handleDelete = (index) => {
        const newInputs = { ...inputs };
        const inputKey = Object.keys(inputs)[index];
        delete newInputs[inputKey];
        setInputs(newInputs);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Paper elevation={3} sx={{ padding: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ marginRight: 1 }}>{t('Inputs')}</Typography>
                        <Button variant="contained" startIcon={<Add />} onClick={() => handleDialogOpen()}>
                            {t('Add')}
                        </Button>
                    </Box>
                    <IconButton onClick={handleToggleExpand}>
                        {expanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Input')}</TableCell>
                                    <TableCell>{t('Type')}</TableCell>
                                    <TableCell>{t('Value')}</TableCell>
                                    <TableCell align="right">{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(inputs).map((key, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{inputs[key].display?.en || key}</TableCell>
                                        <TableCell>{inputs[key].type}</TableCell>
                                        <TableCell>{inputs[key].value || ''}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => handleDialogOpen(index)}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(index)}>
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Collapse>
            </Paper>

            <Dialog open={open} onClose={handleDialogClose}>
                <DialogTitle>{editing !== null ? t('Edit Input') : t('Add Input')}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="inputName"
                        label={t('Input Name')}
                        type="text"
                        fullWidth
                        value={inputData.inputName}
                        onChange={handleInputChange}
                    />
                    <Select
                        margin="dense"
                        name="inputType"
                        label={t('Input Type')}
                        fullWidth
                        value={inputData.inputType}
                        onChange={handleInputChange}
                    >
                        {inputTypeChoices.map((type) => (
                            <MenuItem key={type} value={type}>
                                {t(type)}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        margin="dense"
                        name="inputValue"
                        label={t('Input Value')}
                        type="text"
                        fullWidth
                        value={inputData.inputValue}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>{t('Cancel')}</Button>
                    <Button onClick={handleAddOrEdit}>{editing !== null ? t('Save') : t('Add')}</Button>
                </DialogActions>
            </Dialog>

            <Paper elevation={3} sx={{ padding: 2 }}>
                <WorkflowEditor inputs={inputs} existingWorkflow={workflow} handleChange={handleChange} />
            </Paper>
        </Box>
    );
};

export default WorkflowDetails;
