import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import {
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { greyboxApiActions } from '../../../../redux/api';
import { getQuestionText } from './utils';

const QuestionsTableRow = ({
  question, moveRow, index,
}) => {
  const [hover, setHover] = React.useState(false);
  const { questionManagement } = greyboxApiActions;
  const [updateQuestionOrder] = questionManagement.update();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const QUESTION_TYPES = {
    MC: t('Multiple Choice'),
    SA: t('Short Answer'),
    TF: t('True/False'),
    RA: t('Ranking'),
  };

  const ref = React.useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'question',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveRow(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item) {
      const hoverIndex = index;
      const formData = new FormData();
      formData.append('order', hoverIndex + 1);

      updateQuestionOrder({
        id: question.question.id,
        body: { question: { order: item.index } },
      });
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'question',
    item: () => ({ id: question.id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  const handleClick = () => {
    navigate(`${location.pathname}/${question.question.id}`);
  };

  return (
    <TableRow
      hover
      ref={ref}
      data-handler-id={handlerId}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{ height: 68, cursor: isDragging ? 'grabbing' : 'grab' }}
    >
      <TableCell sx={{ width: 30 }}>
        <DragHandleIcon />
      </TableCell>
      <TableCell sx={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 400,
        whiteSpace: 'nowrap',
      }}
      >
        {getQuestionText(question)}
      </TableCell>
      <TableCell sx={{ width: 110 }}>
        {QUESTION_TYPES[question.question.type_of_q]}
      </TableCell>
      <TableCell align="right" sx={{ width: 50 }}>
        {hover && (
          <Tooltip title={t('Edit')}>
            <IconButton size="small" onClick={() => handleClick()}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default QuestionsTableRow;
