import { Add, Delete, Edit } from '@mui/icons-material';
import {
    Box,
    Button,
    IconButton,
    Paper,
    Skeleton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { greyboxApiActions } from '../../redux/api';


const WorkflowManager = () => {
    const { workflow } = greyboxApiActions;
    const { clinic } = useSelector((state) => state.clinic);
    const { data, isLoading, isFetching } = workflow.list({
        clinic: clinic.id,
    });

    return (
        <Box width="100%">
            <Button
                variant="contained"
                color="primary"
                startIcon={<Add />}
                component={Link}
                to="add"
            >
                Add Workflow
            </Button>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Enabled</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Updated Date</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(isLoading || isFetching) ? (
                            Array.from(new Array(5)).map((_, index) => (
                                <TableRow key={index}>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                    <TableCell><Skeleton variant="text" /></TableCell>
                                </TableRow>
                            ))
                        ) : (
                            data.map((workflow) => (
                                <TableRow key={workflow.id}>
                                    <TableCell>{workflow.name}</TableCell>
                                    <TableCell>
                                        <Switch checked={workflow.enabled} disabled />
                                    </TableCell>
                                    <TableCell>{format(new Date(workflow.created), 'yyyy-MM-dd')}</TableCell>
                                    <TableCell>{format(new Date(workflow.updated), 'yyyy-MM-dd')}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            component={Link}
                                            to={`edit/${workflow.id}`}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            color="secondary"
                                        // onClick={() => handleDelete(workflow.id)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default WorkflowManager;

