import { Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import i18n from '../../i18n';


export const Hin = ({ formik }) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;
  const { t } = useTranslation();
  // Local state for hinExpDisplay
  const [hinExpDisplay, setHinExpDisplay] = useState(() => {
    if (values.hin_exp) {
      // Assuming values.hin_exp is in 'YYYY-MM-DD' format
      const dateParts = values.hin_exp.split('-');
      if (dateParts.length >= 2) {
        const [year, month] = dateParts;
        return `${year} ${month}`;
      }
    }
    return '';
  });

  // Update hinExpDisplay if values.hin_exp changes externally
  useEffect(() => {
    if (values.hin_exp) {
      const dateParts = values.hin_exp.split('-');
      if (dateParts.length >= 2) {
        const [year, month] = dateParts;
        const newDisplay = `${year} ${month}`;
        if (newDisplay !== hinExpDisplay) {
          setHinExpDisplay(newDisplay);
        }
      }
    } else {
      if (hinExpDisplay !== '') {
        setHinExpDisplay('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.hin_exp]);

  // Custom onChange handler to convert letters to uppercase
  const handleHinNumberChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();
    const strippedValue = uppercaseValue.replace(/\s+/g, '');
    setFieldValue(name, strippedValue);
  };

  // Custom onChange handler for hin_exp using useState
  const handleHinExpChange = (e) => {
    const value = e.target.value;
    setHinExpDisplay(value);

    // If the value is empty, clear hin_exp
    if (!value.trim()) {
      setFieldValue('hin_exp', null);
      return;
    }

    // Parse the value to transform into 'YYYY-MM-DD'
    const regex = /^(\d{4}) (\d{2})$/;
    const match = regex.exec(value);
    if (match) {
      const [, yearStr, monthStr] = match;
      const year = parseInt(yearStr, 10);
      const month = parseInt(monthStr, 10);

      if (year >= 1000 && year <= 9999 && month >= 1 && month <= 12) {
        // Get the last day of the month
        const lastDay = new Date(year, month, 0); // Day 0 of next month
        const formattedDate = lastDay.toISOString().split('T')[0]; // 'YYYY-MM-DD'
        setFieldValue('hin_exp', formattedDate);
      } else {
        //invalid date for validation
        setFieldValue('hin_exp', '1111');
      }
    } else {
      setFieldValue('hin_exp', null);
    }
  };


  return (
    <Box display="flex" alignItems="start" width="100%" >
      {/* Health Insurance Number Input */}
      <Box flex={3} px={1}>
        <InputMask
          mask="aaaa 9999 9999"
          value={values.hin_number}
          onChange={handleHinNumberChange}
          name="hin_number"
          maskChar=""
          formatChars={{
            'a': '[A-Za-z]',
            '9': '[0-9]',
          }}
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              label={t("Health Insurance Number")}
              placeholder="ABCD 1234 5678"
              error={touched.hin_number && Boolean(errors.hin_number)}
              helperText={touched.hin_number && errors.hin_number}
              fullWidth
            />
          )}
        </InputMask>
      </Box>

      {/* Expiration Date Input */}
      <Box flex={1} pr={1}>
        <InputMask
          mask="9999 99"
          value={hinExpDisplay}
          onChange={handleHinExpChange}
          maskChar=""
        >
          {(inputProps) => (
            <TextField
              {...inputProps}
              label={t("Expiration Date")}
              placeholder="YYYY MM"
              error={touched.hin_exp && Boolean(errors.hin_exp)}
              helperText={touched.hin_exp && errors.hin_exp}
              fullWidth
            />
          )}
        </InputMask>
      </Box>
    </Box>
  );
};

Hin.validationSchema = Yup.object({
  hin_number: Yup.string()
    .nullable()
    .matches(/^[A-Za-z]{4}\d{4}\d{4}$/, i18n.t('Invalid health insurance number')),
  hin_exp: Yup.string()
    .nullable()
    .test('is-valid-date', i18n.t('Invalid expiration date'), function (value) {
      if (!value) {
        // If hin_exp is null or empty, it's valid since the field is optional
        return true;
      }

      // Check if value is in 'YYYY-MM-DD' format
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      if (!regex.test(value)) {
        return this.createError({ message: i18n.t('Expiration date must be in YYYY MM format') });
      }

      const date = new Date(value);
      if (isNaN(date.getTime())) {
        return this.createError({ message: i18n.t('Invalid date') });
      }

      // Optional: Check if date is not in the past
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Set time to midnight
      if (date < currentDate) {
        return this.createError({ message: i18n.t('Expiration date cannot be in the past') });
      }

      return true;
    }),
});

