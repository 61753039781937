import { LoadingButton } from '@mui/lab';
import {
  Box, Button, MenuItem, Typography,
  Divider, FormControlLabel, Checkbox, IconButton, Tooltip, LinearProgress,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { greyboxApiActions } from '../../../../redux/api';
import DeleteDialog from '../../../DeleteDialog';
import {
  QuestionManagementType, QuestionType, QuestionnaireType,
} from '../../../../types';
import { FormSelect, FormTextField, LocaleWrapper } from '../../../form-inputs';
import {
  localizeFields, getMcFields, usedLetters, getUnusedLetters, parsePointConfig,
  parseFormikPointConfig, getSAFields, getRAFields, addCustomFields,
} from './utils';
import Row from '../../../Row';
import { LANGUAGES } from '../../../../../locales/languages';

interface QuestionFormProps {
  questionnaire: QuestionnaireType;
  handleClose?: () => void;
  edit?: boolean;
}

export interface FormType {
  question_text: string;
  type: QuestionType;
  trueValue?: number;
  falseValue?: number;
  ans_type?: string;
  max_length?: number;
  allow_empty?: boolean;
  question_text_en: string;
  question_text_fr: string;
  question_text_es: string;
  question_text_de: string;
  question_text_it: string;
  question_text_pt: string;
  start_label?: string;
  start_label_en?: string;
  start_label_fr?: string;
  start_label_es?: string;
  start_label_de?: string;
  start_label_it?: string;
  start_label_pt?: string;
  end_label?: string;
  end_label_en?: string;
  end_label_fr?: string;
  end_label_es?: string;
  end_label_de?: string;
  end_label_it?: string;
  end_label_pt?: string;
  start_range?: string;
  end_range?: string;
  increment?: string;
  mc_question_choice_other?: boolean;
  multi_select?: boolean;
  min?: number;
  max?: number;
}

const QuestionForm = ({ questionnaire, handleClose = null, edit = false }: QuestionFormProps) => {
  const { t } = useTranslation();
  const { questionManagement } = greyboxApiActions;
  const { questionId } = useParams<{ id: string }>();
  const { data, isLoading } = questionManagement.get(questionId, { skip: !questionId }) as {
    data: QuestionManagementType;
    isLoading: boolean;
  };
  const navigate = useNavigate();

  const showPointConfig = edit && questionnaire.has_score;

  const [addQuestion] = questionManagement.add();
  const [editQuestion] = questionManagement.update();
  const [deleteQuestion] = questionManagement.delete();

  const [usedMcLetters, setUsedMcLetters] = React.useState<string[]>([]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleDelete = async () => {
    await deleteQuestion(questionId);
    history.back();
  };

  const SA_ANSWER_TYPES = [
    {
      id: 'text',
      label: t('Text'),
    },
    {
      id: 'number',
      label: t('Number'),
    },
    {
      id: 'date',
      label: t('Date'),
    },
    {
      id: 'email',
      label: t('Email'),
    },
    {
      id: 'tel',
      label: t('Phone'),
    },
  ];

  const formik = useFormik({
    initialValues: {
      ...localizeFields('question_text', data),
      ...getMcFields(data),
      ...parsePointConfig(data?.point_config),
      ...getSAFields(data),
      ...getRAFields(data),
      type: data?.question.type_of_q || 'TF',
    } as FormType,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const lowerType = values.type.toLowerCase();
      const texts = Object.keys(values).reduce((acc, key) => {
        if (key.includes('question_text') && values[key]) {
          acc[`${lowerType}_${key}`] = values[key];
        }
        return acc;
      }, {});

      texts[`${lowerType}_question_text`] = values.question_text_en;
      const pointConfig = parseFormikPointConfig(values);

      const baseBody: Partial<QuestionManagementType> = {
        ...texts,
        question: {
          type_of_q: values.type,
          id: data?.question.id,
          questionnaire: questionnaire.id,
        },
        point_config: pointConfig,
        resourcetype: `${values.type}_Question`,
      };

      const body = addCustomFields(baseBody, values);

      if (edit) {
        editQuestion({ id: questionId, body }).then(() => {
          setSubmitting(false);
        });
      } else {
        addQuestion({ body }).then(() => {
          setSubmitting(false);
          if (handleClose) {
            handleClose();
          }
        });
      }
    },
  });

  useEffect(() => {
    if (edit && data?.question.type_of_q === 'MC') {
      setUsedMcLetters(usedLetters(data));
    }
  }, [data, edit]);

  const handleNewChoice = () => {
    const unused = getUnusedLetters(usedMcLetters);
    const letter = unused[0];
    setUsedMcLetters([...usedMcLetters, letter]);
  };

  const handleRemoveChoice = (letter: string) => {
    setUsedMcLetters(usedMcLetters.filter((l) => l !== letter));
    formik.setFieldValue(`mc_question_choice_${letter}`, null);
    formik.setFieldValue(`${letter.toUpperCase()}Value`, null);
    LANGUAGES.forEach((lang) => {
      formik.setFieldValue(`mc_question_choice_${letter}_${lang.code}`, null);
    });
  };

  if (isLoading) {
    return (
      <Box height="20vh">
        <LinearProgress />
      </Box>
    );
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <LocaleWrapper name="question_text">
          <FormTextField
            name="question_text"
            sx={{ width: '100%' }}
            label={t('Question')}
            formik={formik}
            required
          />
        </LocaleWrapper>
        {!edit && (
          <Row>
            <FormSelect name="type" label={t('Type')} formik={formik} required>
              <MenuItem value="TF">{t('True/False')}</MenuItem>
              <MenuItem value="RA">{t('Rating')}</MenuItem>
              <MenuItem value="MC">{t('Multiple Choices')}</MenuItem>
              <MenuItem value="SA">{t('Short Answer')}</MenuItem>
            </FormSelect>
          </Row>
        )}
        {
          /* RA Question */
          formik.values.type === 'RA' && (
            <>
              <LocaleWrapper name="start_label">
                <FormTextField
                  label={t('Start Label')}
                  sx={{ width: '100%' }}
                  name="start_label"
                  formik={formik}
                />
              </LocaleWrapper>
              <LocaleWrapper name="end_label">
                <FormTextField
                  label={t('End Label')}
                  sx={{ width: '100%' }}
                  name="end_label"
                  formik={formik}
                />
              </LocaleWrapper>
              <Divider sx={{ my: 1 }} />
              <Row>
                <FormTextField
                  required
                  label={t('Start Value')}
                  name="start_range"
                  formik={formik}
                  type="number"
                />
                <FormTextField
                  required
                  label={t('End Value')}
                  name="end_range"
                  formik={formik}
                  type="number"
                />
              </Row>
              <Row>
                <FormTextField
                  required
                  label={t('Increment')}
                  name="increment"
                  formik={formik}
                  type="number"
                />
              </Row>
            </>
          )
        }
        {
          /* TF Question */
          formik.values.type === 'TF' && showPointConfig && (
            <Row>
              <FormTextField
                type="number"
                label={t('Score for yes')}
                name="trueValue"
                formik={formik}
              />
              <FormTextField
                type="number"
                label={t('Score for no')}
                name="falseValue"
                formik={formik}
              />
            </Row>
          )
        }
        {
          /* SA Question */
          formik.values.type === 'SA' && (
            <>
              {
                edit && (
                  <Row>
                    <FormControlLabel
                      sx={{ mx: 1 }}
                      control={<Checkbox checked={formik.values.allow_empty} onChange={formik.handleChange} name="allow_empty" />}
                      label={t('Allow Empty')}
                    />
                  </Row>
                )
              }
              <Row>
                <FormSelect name="ans_type" label={t('Answer Type')} formik={formik} required>
                  {SA_ANSWER_TYPES.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.label}
                    </MenuItem>
                  ))}
                </FormSelect>
                {formik.values.ans_type === 'text' && (
                  <FormTextField
                    label={t('Max Length')}
                    name="max_length"
                    formik={formik}
                    type="number"
                  />
                )}
              </Row>
              {formik.values.ans_type === 'number' && (
                <Row>
                  <FormTextField
                    label={t('Min number')}
                    name="min"
                    formik={formik}
                    type="number"
                  />
                  <FormTextField
                    label={t('Max number')}
                    name="max"
                    formik={formik}
                    type="number"
                  />
                </Row>
              )}
            </>
          )
        }
        {
          /* MC Question */
          formik.values.type === 'MC' && edit && (
            <>
              <Row>
                <FormControlLabel
                  sx={{ mx: 1 }}
                  control={<Checkbox checked={formik.values.mc_question_choice_other} onChange={formik.handleChange} name="mc_question_choice_other" />}
                  label={t('Has Other')}
                />
                <FormControlLabel
                  sx={{ mx: 1 }}
                  control={<Checkbox checked={formik.values.multi_select} onChange={formik.handleChange} name="multi_select" />}
                  label={t('Can Select Multiple')}
                />
              </Row>
              {
                usedMcLetters.map((letter, index) => (
                  <Box
                    key={letter}
                    sx={{
                      mb: 1,
                      borderRadius: 1,
                      p: 1,
                      border: (theme) => `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Tooltip title={t('Remove choice')}>
                        <IconButton
                          size="small"
                          sx={{ mr: 1 }}
                          onClick={() => handleRemoveChoice(letter)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Typography variant="h6">
                        {t('Choice')}
                        {' '}
                        {index + 1}
                      </Typography>
                    </Box>
                    <LocaleWrapper name={`mc_question_choice_${letter}`}>
                      <FormTextField
                        label={`${t('Choice')} ${index + 1}`}
                        sx={{ width: '100%' }}
                        name={`mc_question_choice_${letter}`}
                        formik={formik}
                      />
                    </LocaleWrapper>
                    {showPointConfig && (
                      <Row>
                        <FormTextField
                          type="number"
                          label={t('Value')}
                          name={`${letter.toUpperCase()}Value`}
                          formik={formik}
                        />
                      </Row>
                    )}
                  </Box>
                ))
              }
              <Button onClick={() => handleNewChoice()} variant="outlined">
                {t('Add Choice')}
              </Button>
            </>
          )
        }

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          { edit && (
            <>
              <LoadingButton
                variant="contained"
                color="error"
                sx={{ mr: 1 }}
                onClick={() => setDeleteOpen(true)}
              >
                {t('Delete')}
              </LoadingButton>
              <Button
                variant="contained"
                disabled={!formik.dirty}
                onClick={formik.handleReset}
              >
                {t('Reset')}
              </Button>
            </>
          )}
          <LoadingButton
            variant="contained"
            loading={formik.isSubmitting}
            sx={{ ml: 1 }}
            type="submit"
            disabled={!formik.dirty}
          >
            {t('Save')}
          </LoadingButton>
        </Box>
      </form>
      <DeleteDialog open={deleteOpen} handleClose={() => setDeleteOpen(false)} deleteItem={handleDelete} />
    </>
  );
};

export default QuestionForm;
