import {
  Box,
  Checkbox,
  Chip,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip, Typography, // Added Tooltip import
  useTheme,
} from '@mui/material';
import moment from 'moment';
import MuiMarkdown from 'mui-markdown';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoArrowForwardCircleOutline } from 'react-icons/io5';
import { MdInfoOutline, MdLink, MdLinkOff } from 'react-icons/md'; // Added MdInfoOutline
import { RxCheckCircled } from 'react-icons/rx';
import { TbClockDown, TbGraph, TbGraphOff } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { getComparator, stableSort } from '../../../../helper-functions';
import { Sorted, Sorting, Tooltips } from '../../../tk-ui';
import MedicationActions from './MedicationActions';
import { formatDosageToMarkdown } from './utils';

const EnhancedTableHead = (props) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const cellSettings = [
    { id: 'name', align: 'left', label: t('Medication'), sortable: false, minWidth: '150px' },
    { id: 'has_request_orchestration', align: 'left', label: t('Prescribed'), sortable: false, minWidth: '80px' },
    { id: 'statusName', align: 'left', label: t('Titration status'), sortable: false, minWidth: '100px' },
    { id: 'dosage', align: 'left', label: t('Dosage'), sortable: false, minWidth: '100px', hideOnSmallScreens: true },
    { id: 'startDate', align: 'left', label: t('Start'), sortable: true, minWidth: '80px', hideOnSmallScreens: true },
    { id: 'endDate', align: 'left', label: t('End'), sortable: true, minWidth: '80px', hideOnSmallScreens: true },
  ];

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all medications' }}
            color="primary"
          />
        </TableCell>
        {cellSettings.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={headCell.sortable && orderBy === headCell.id ? order : false}
            sx={{
              border: '1px solid',
              borderColor: 'divider',
              display: headCell.hideOnSmallScreens ? { xs: 'none', sm: 'table-cell' } : 'table-cell',
              fontSize: 14,
              fontWeight: 500,
              padding: 1,
              minWidth: headCell.minWidth,
              color: orderBy === headCell.id ? 'primary.main' : 'text.primary',
              cursor: headCell.sortable ? 'pointer' : 'default',
            }}
            onClick={headCell.sortable ? createSortHandler(headCell.id) : undefined}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {headCell.label}
              {headCell.sortable && (
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 0.5 }}>
                  {orderBy === headCell.id ? (
                    <Box
                      sx={{
                        transform: order === 'desc' ? 'rotate(180deg)' : 'none',
                        transition: 'all 200ms ease',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Sorted />
                    </Box>
                  ) : (
                    <Sorting />
                  )}
                </Box>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const MedicationTable = (props) => {
  const { meds, isHistory, selected, setSelected, isFetching } = props;

  const { uuid } = useParams();
  const { i18n, t } = useTranslation();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(isHistory ? 'endDate' : 'startDate');
  const lang = i18n.resolvedLanguage;
  const theme = useTheme();

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (selected.length === 0 && event.target.checked) {
      const newSelecteds = meds.map((n) => n.uuid);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    setOrderBy(isHistory ? 'endDate' : 'startDate');
  }, [isHistory]);

  const handleClick = (uuid) => {
    setSelected((prevSelected) => {
      if (prevSelected.includes(uuid)) {
        return prevSelected.filter((item) => item !== uuid);
      } else {
        return [...prevSelected, uuid];
      }
    });
  };

  // Update locale settings for moment.js
  useEffect(() => {
    moment.updateLocale('en', {
      relativeTime: {
        past: '%s late',
      },
    });

    moment.updateLocale('fr', {
      relativeTime: {
        past: '%s de retard',
      },
    });
  }, []);

  // Number of skeleton rows to display
  const skeletonRows = 5;

  return (
    <Paper>
      <MedicationActions
        medications={meds}
        isHistory={isHistory}
        selected={selected}
        setSelected={setSelected}
        uuid={uuid}
      />
      <TableContainer>
        <Table aria-labelledby="tableTitle" aria-label="enhanced table">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            isHistory={isHistory}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={meds.length}
          />
          <TableBody>
            {isFetching ? (
              // Render skeleton rows while fetching
              Array.from({ length: skeletonRows }).map((_, index) => (
                <TableRow key={index} data-cy="skeletonRow">
                  <TableCell padding="checkbox" sx={{ border: '1px solid', borderColor: 'divider' }}>
                    <Skeleton variant="rectangular" width={24} height={24} />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      padding: '5px 0 3px 7px',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'text.primary',
                    }}
                  >
                    <Skeleton variant="text" width="80%" height={20} />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      padding: '0 9px',
                      textAlign: 'center',
                    }}
                  >
                    <Skeleton variant="text" width="50%" height={20} />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      padding: '0 9px',
                    }}
                  >
                    <Skeleton variant="text" width="60%" height={20} />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      padding: '0 9px',
                      display: { xs: 'none', sm: 'table-cell' },
                    }}
                  >
                    <Skeleton variant="text" width="70%" height={20} />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      padding: '0 9px',
                      display: { xs: 'none', sm: 'table-cell' },
                    }}
                  >
                    <Skeleton variant="text" width="50%" height={20} />
                  </TableCell>
                  <TableCell
                    sx={{
                      border: '1px solid',
                      borderColor: 'divider',
                      padding: '0 9px',
                      display: { xs: 'none', sm: 'table-cell' },
                    }}
                  >
                    <Skeleton variant="text" width="50%" height={20} />
                  </TableCell>
                </TableRow>
              ))
            ) : meds.length > 0 ? (
              stableSort(meds, getComparator(order, orderBy)).map((row, index) => {
                const isItemSelected = selected.includes(row.uuid);
                const labelId = `enhanced-table-checkbox-${index}`;

                // Prepare ingredient details for tooltip
                const ingredientDetails = (
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    {row.ingredient.map((molecule) => (
                      <Typography
                        key={molecule.drugUuid}
                        variant="body2"
                        sx={{ textTransform: 'capitalize', color: 'common.white' }}
                      >
                        {lang === 'en' ? molecule.molecule : molecule.moleculeFr}{' '}
                        {molecule.dosageUnit === 'nan' ? (
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{ textTransform: 'lowercase', fontWeight: 400, color: 'common.white' }}
                          >
                            ({lang === 'en' ? molecule.strength : molecule.strengthFr})
                          </Typography>
                        ) : (
                          <Typography
                            component="span"
                            variant="caption"
                            sx={{ textTransform: 'lowercase', fontWeight: 400, color: 'common.white' }}
                          >
                            ({lang === 'en' ? molecule.strength : molecule.strengthFr} /{' '}
                            {lang === 'en' ? molecule.dosageUnit : molecule.dosageUnitFr})
                          </Typography>
                        )}
                      </Typography>
                    ))}
                  </Box>
                );

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.uuid}
                    selected={isItemSelected}
                    data-cy="tableRow"
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onClick={() => handleClick(row.uuid)}
                        inputProps={{ 'aria-labelledby': labelId }}
                        color="primary"
                        data-cy="currentMedCheckbox"
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      data-cy="cellWrapper"
                      sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        padding: 1,
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'text.primary',
                        minWidth: '150px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                        role="button"
                        tabIndex={0}
                        onClick={() => handleClick(row.uuid)}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5
                          }}
                        >
                          <Box sx={{ fontWeight: 500, pr: 0.5}}>{row.name}</Box>
                          <Tooltip title={ingredientDetails} placement="top" arrow disableInteractive>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Box
                                component={MdInfoOutline}
                                sx={{
                                  color: theme.palette.action.active,
                                  fontSize: 18,
                                }}
                              />
                            </Box>
                          </Tooltip>
                          {!isHistory && (
                            <Tooltips
                              text={
                                row.isNotSuggested
                                  ? t('Non-suggested medication')
                                  : t('Suggested medication')
                              }
                              delayTime={200}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box
                                  component={RxCheckCircled}
                                  sx={{
                                    color: row.isNotSuggested
                                      ? theme.palette.warning.light
                                      : theme.palette.success.main,
                                    fontSize: 18,
                                  }}
                                />
                              </Box>
                            </Tooltips>
                          )}
                          {row.lastActiveDay && (
                            <Tooltips text={t('Last day')} delayTime={200}>
                              <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <Box
                                  component={TbClockDown}
                                  sx={{
                                    color: theme.palette.warning.main,
                                    fontSize: 18,
                                  }}
                                />
                              </Box>
                            </Tooltips>
                          )}
                          {row.nextDoseDate && row.nextDose && !isHistory && (
                            <Tooltips
                              text={
                                <>
                                  <div style={{ paddingBottom: '2px' }}>
                                    {`${t('Next Step')}: + ${row.nextDose} ${row.unit}`}
                                  </div>
                                  <div>{moment(row.nextDoseDate).fromNow()}</div>
                                </>
                              }
                              delayTime={200}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center'}}>
                                <Box
                                  component={IoArrowForwardCircleOutline}
                                  sx={{
                                    color:
                                      row.nextDoseTime > 1
                                        ? theme.palette.info.main
                                        : theme.palette.warning.main,
                                    fontSize: 18,
                                  }}
                                />
                              </Box>
                            </Tooltips>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        padding: 1,
                        minWidth: '80px',
                      }}
                      onClick={() => handleClick(row.uuid)}
                    >
                      {row.has_request_orchestration ? (
                        <Chip
                          icon={<MdLink />}
                          label={t('Included in Prescription')}
                          color="success"
                          variant="outlined"
                        />
                      ) : (
                        <Chip
                          icon={<MdLinkOff />}
                          label={t('Not in Prescription')}
                          sx={{
                            borderColor: 'text.disabled',
                            color: 'text.disabled',
                            '& .MuiChip-icon': { color: 'text.disabled' },
                          }}
                          variant="outlined"
                        />
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        padding: 1,
                        minWidth: '80px',
                      }}
                    >
                      {row.statusName ? (
                        <Chip
                          icon={<TbGraph />}
                          label={t(row.statusName)}
                          sx={{
                            borderColor: row.statusColor,
                            color: row.statusColor,
                            '& .MuiChip-icon': { color: row.statusColor },
                          }}
                          variant="outlined"
                        />
                      ) : !isHistory ? (
                        <Chip
                          icon={<TbGraphOff />}
                          label={t('No available plan')}
                          sx={{
                            borderColor: 'text.disabled',
                            color: 'text.disabled',
                            '& .MuiChip-icon': { color: 'text.disabled' },
                          }}
                          variant="outlined"
                        />
                      ) : (
                        <span>---</span>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        padding: 1,
                        color: 'text.primary',
                        fontWeight: 400,
                        display: { xs: 'none', sm: 'table-cell' },
                      }}
                      onClick={() => handleClick(row.uuid)}
                    >
                      {row.dosage ? (
                        <MuiMarkdown>{formatDosageToMarkdown(row.dosage, t)}</MuiMarkdown>
                      ) : (
                        '---'
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        padding: 1,
                        color: 'text.primary',
                        fontWeight: 400,
                        minWidth: 80,
                        textAlign: 'center',
                        display: { xs: 'none', sm: 'table-cell' },
                      }}
                      onClick={() => handleClick(row.uuid)}
                    >
                      {row.startDate}
                    </TableCell>
                    <TableCell
                      sx={{
                        border: '1px solid',
                        borderColor: 'divider',
                        padding: 1,
                        color: 'text.primary',
                        fontWeight: 400,
                        minWidth: 80,
                        textAlign: 'center',
                        display: { xs: 'none', sm: 'table-cell' },
                      }}
                      onClick={() => handleClick(row.uuid)}
                    >
                      {row.lastActiveDay ? (
                        <Box
                          component="span"
                          sx={{ fontWeight: 500, letterSpacing: '-0.15px' }}
                          data-cy="isLast"
                        >
                          {row.endDate}
                        </Box>
                      ) : (
                        <Box component="span" data-cy="isNotLast">
                          {row.endDate}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={7}
                  sx={{ textAlign: 'center', border: '1px solid', borderColor: 'divider' }}
                >
                  {t('No medications available')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MedicationTable;
