import React from 'react';
import { Box, Typography } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import allLocales from '@fullcalendar/core/locales-all';
import dayGridPlugin from '@fullcalendar/daygrid';
import iCalendarPlugin from '@fullcalendar/icalendar';
import { useTranslation } from 'react-i18next';
import { domainConfigs } from '../../../redux/api';

const CalendarView = ({ calendarRef, date, calendarSelector }) => {
    const { t, i18n } = useTranslation();
    const icsUrl = `https://${domainConfigs[window.location.hostname]}/calendar/${calendarSelector.data[0].secret_key}/takecare.ics`;

    return (
        <Box width="30%" sx={{ fontFamily: (theme) => theme.typography.fontFamily, pr: 2, pb: 2 }}>
            <FullCalendar
                ref={calendarRef}
                initialView="dayGridDay"
                plugins={[dayGridPlugin, iCalendarPlugin]}
                initialDate={date.toDate()}
                events={{
                    url: icsUrl,
                    format: 'ics',
                }}
                height="100%"
                eventContent={renderEventContent}
                eventClick={(info) => {
                    info.jsEvent.preventDefault();
                }}
                locale={i18n.language}
                locales={allLocales}
                headerToolbar={{
                    left: '',
                    center: '',
                    right: '',
                }}
            />
        </Box>
    );
};

const renderEventContent = (eventInfo) => (
    <Box sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'default',
        height: '100%',
    }}
    >
        <Typography
            variant="caption"
            sx={{ mx: 0.5 }}
            noWrap
        >
            {eventInfo.timeText}
            {' '}
            {eventInfo.event.title}
        </Typography>
    </Box>
);

export default CalendarView;
