// Necessary imports
import {
  Cancel as CancelIcon,
  MoreVert as MoreVertIcon,
  PersonAddAlt1,
  Restore as RestoreIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../redux/api';
import { formatPhoneNumber } from '../../utils';
import FilterButton from '../FilterButton';
import ArchiveModal from '../modals/ArchiveModal';
import TableWrapper from '../TableWrapper';
import TableSortLabel from '../tk-ui/table/TableSortLabel';
import AccountCreationDialog from './AccountCreationDialog';

const DIRECTIONS = {
  asc: '',
  desc: '-',
};

const AccountRowButton = React.memo(({ row, idx, viewMode }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { account } = greyboxApiActions;
  const [reactivateAccount] = account.put();
  const [archiveOpen, setArchiveOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const navigate = useNavigate();

  const handleMenuActions = (action, name) => {
    switch (action) {
      case 'edit':
        navigate(`/accounts/${row.uuid}`);
        break;
      case 'delete':
        setFirstName(name);
        setArchiveOpen(true);
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };

  if (viewMode === 'archived') {
    return (
      <Tooltip title={t('Restore account')}>
        <IconButton
          data-cy={`row-${idx}-menu`}
          onClick={() => reactivateAccount({ id: `${row.uuid}/restore_account` })}
          size="small"
        >
          <RestoreIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <>
      <IconButton
        data-cy={`row-${idx}-menu`}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        data-cy={`row-${idx}-menu-main`}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={() => handleMenuActions('edit', '')}>{t('Edit')}</MenuItem>
        <MenuItem
          data-cy="archive-account"
          sx={{ color: (theme) => theme.palette.error.main }}
          onClick={() => handleMenuActions('delete', row.firstName)}
        >
          {t('Archive')}
        </MenuItem>
      </Menu>
      {archiveOpen && (
        <ArchiveModal
          open={archiveOpen}
          firstName={firstName}
          handleClose={() => setArchiveOpen(false)}
          uuid={row.uuid}
        />
      )}
    </>
  );
});

const AccountTable = () => {
  const [page, setPage] = useState(0);
  const { type } = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [ordering, setOrdering] = useState('last_active');
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState([]);
  const [direction, setDirection] = useState('asc');
  const [viewMode, setViewMode] = useState('active'); // 'active' or 'archived'
  const clinic = useSelector((state) => state.clinic.clinic);
  const [open, setOpen] = useState(false); // State for AccountCreationDialog
  const { t } = useTranslation();
  const { account } = greyboxApiActions;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleViewModeChange = (event, newViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
      setPage(0);
    }
  };

  const labels = useMemo(
    () => filters.filter((item) => item.type === 'label').map((item) => item.id),
    [filters]
  );

  const { data, isFetching } = account.list({
    acc_type: type,
    page: page + 1,
    page_size: rowsPerPage,
    invitations: true,
    clinic: clinic.id,
    search: query,
    deleted: viewMode === 'archived',
    ordering: `${DIRECTIONS[direction]}${ordering}`,
    labels,
  });

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
  }, [type]);

  const accountLabels = {
    PT: t('Practitioners'),
    P: t('Patients'),
    CP: t('Caregivers'),
  };

  const columns = useMemo(() => {
    if (isMobile) {
      return [
        {
          id: 'firstName',
          label: t('Name'),
          format: (value, row) => `${value} ${row.lastName}`,
        },
        {
          id: 'email',
          label: t('Email'),
        },
      ];
    } else {
      return [
        {
          id: 'firstName',
          label: t('Name'),
          format: (value, row) => `${value} ${row.lastName}`,
        },
        {
          id: 'email',
          label: t('Email'),
        },
        {
          id: 'phoneNumber',
          label: t('Phone Number'),
          format: (value) => formatPhoneNumber(value),
        },
        {
          id: 'lastActive',
          label: t('Last active'),
          format: (value) => (value ? moment(value).format('lll') : t('Never')),
        },
        {
          id: 'createdDate',
          label: t('Created at'),
          format: (value) => moment(value).format('ll'),
        },
        {
          id: 'invitation_status',
          label: t('Invitation status'),
          format: (value) => (value ? t(value) : t('Not Connected')),
        },
      ];
    }
  }, [isMobile, t]);

  const handleSort = (id) => {
    if (ordering === id) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrdering(id);
      setDirection('desc');
    }
  };

  return (
    <Box width="100%">
      <Box mb={1}>
        <Typography
          gutterBottom
          variant="h4"
          sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}
        >
          {accountLabels[type]}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          sx={{ gap: 1 }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{
              flex: 1,
              minWidth: '200px',
              gap: 1,
              maxWidth: isMobile ? '100%' : '400px', // Adjusted maxWidth on big screens
            }}
          >
            <TextField
              fullWidth
              placeholder={`${data?.count ?? 0} ${t('accounts')}...`}
              value={query}
              variant="outlined"
              sx={{
                bgcolor: 'background.paper',
                borderRadius: 2,
              }}
              onChange={(e) => setQuery(e.target.value)}
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {query !== '' && (
                      <IconButton onClick={() => setQuery('')}>
                        <CancelIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <FilterButton
              filters={filters}
              setFilters={setFilters}
              type="account"
            />
          </Box>
          <ToggleButtonGroup
            value={viewMode}
            size="small"
            sx={{ bgcolor: 'background.paper' }}
            exclusive
            onChange={handleViewModeChange}
            aria-label="View Mode"
          >
            <ToggleButton value="active" aria-label="Active Accounts">
              {t('Actives')}
            </ToggleButton>
            <ToggleButton value="archived" aria-label="Archived Accounts">
              {t('Archived')}
            </ToggleButton>
          </ToggleButtonGroup>
          {type === 'P' && clinic && clinic.permissions.includes('register_account') && (
            <Button
              onClick={() => setOpen(true)}
              startIcon={<PersonAddAlt1 />}
              variant="contained"
              color="primary"
              sx={{ ml: 'auto', width: isMobile ? '100%' : 'auto' }}
            >
              {t('Add patient')}
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ overflowX: 'auto' }}>
        <Paper sx={{ width: '100%', mb: 3 }}>
          <TableWrapper
            maxHeight="calc(100vh - 230px)"
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            total={data?.count ?? 0}
            isFetching={isFetching}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <TableSortLabel
                      active={ordering === column.id}
                      direction={direction}
                      onClick={() => handleSort(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...Array(rowsPerPage)].map((_, idx) => (
                    <TableRow key={idx}>
                      {columns.map((column) => (
                        <TableCell key={column.id}>
                          <Skeleton variant="text" width="100%" />
                        </TableCell>
                      ))}
                      <TableCell>
                        <Skeleton variant="text" width="100%" />
                      </TableCell>
                    </TableRow>
                  ))
                : data && data.count === 0
                ? (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="200px"
                      >
                        <Typography variant="h5">{t('No data')}</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
                : data.results.map((row, idx) => (
                    <Row
                      key={row.uuid}
                      viewMode={viewMode}
                      idx={idx}
                      row={row}
                      columns={columns}
                    />
                  ))}
            </TableBody>
          </TableWrapper>
          {/* Keep AccountCreationDialog for adding patients */}
          {open && <AccountCreationDialog open={open} onClose={() => setOpen(false)} />}
        </Paper>
      </Box>
    </Box>
  );
};

const Row = React.memo(({ row, columns, idx, viewMode }) => {
  const [hover, setHover] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <TableRow
      hover
      sx={{ height: 68 }}
      data-cy={`row-${idx}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {columns.map((column) => (
        <TableCell key={column.id}>
          {column.format ? column.format(row[column.id], row) : row[column.id]}
        </TableCell>
      ))}
      <TableCell sx={{ minWidth: '35px' }}>
        {(hover || isMobile) && (
          <AccountRowButton row={row} idx={idx} viewMode={viewMode} />
        )}
      </TableCell>
    </TableRow>
  );
});

export default AccountTable;
