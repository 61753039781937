import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LinkIcon from '@mui/icons-material/Link';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Skeleton,
  Table, TableBody, TableCell,
  TableHead, TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../redux/api';
import AddDocument from './AddDocument';

const TYPE_ICON = {
  pdf: <PictureAsPdfIcon />,
  image: <InsertPhotoIcon />,
  video: <OndemandVideoIcon />,
  url: <LinkIcon />,
  audio: <VolumeUpIcon />,
};

const EducationSection = ({ sectionId , hasChangePermission}) => {
  const { t } = useTranslation();
  const { educationArticleManagement } = greyboxApiActions;
  const {
    data, isFetching, isError, refetch,
  } = educationArticleManagement.list({ section: sectionId });
  const [deleteArticle] = educationArticleManagement.delete();
  const [updateArticle] = educationArticleManagement.update();
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [editDocumentOpen, setEditDocumentOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);

  const handleMenuClick = (action, article) => {
    switch (action) {
      case 'edit':
        setSelectedArticle(article.uuid);
        setEditDocumentOpen(true);
        break;
      case 'delete':
        setArticleToDelete(article);
        setDeleteConfirmOpen(true);
        break;
      default:
        break;
    }
  };

  const handleDelete = async () => {
    if (articleToDelete) {
      await deleteArticle(articleToDelete.uuid);
      setDeleteConfirmOpen(false);
      setArticleToDelete(null);
      refetch(); // Refetch the data after deletion
    }
  };

  const moveRow = async (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= data.length) return;
    const temp = data[index];
    await updateArticle({ id: temp.uuid, body: { order: newIndex + 1 } });
  };

  return (
    <>
      {isError && (
        <Alert severity="error">
          {t('An error occurred while fetching the documents. Please try again later.')}
        </Alert>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('Title')}</TableCell>
            <TableCell>{t('Type')}</TableCell>
            <TableCell align="right">{t('Actions')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching ? (
            Array.from(new Array(5)).map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton variant="text" width="80%" />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rectangular" width={40} height={40} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="rectangular" width={120} height={40} />
                </TableCell>
              </TableRow>
            ))
          ) : (
            data.length > 0 ? (
              data.map((article, index) => (
                <TableRow key={article.uuid} hover>
                  <TableCell>{article.title}</TableCell>
                  <TableCell>{TYPE_ICON[article.type]}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      size="small"
                      onClick={() => moveRow(index, -1)}
                      disabled={!hasChangePermission || index === 0} // Disable Move Up on first item
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => moveRow(index, 1)}
                      disabled={!hasChangePermission || index === data.length - 1} // Disable Move Down on last item
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleMenuClick('edit', article)}
                      disabled={!hasChangePermission}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleMenuClick('delete', article)}
                      disabled={!hasChangePermission}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} height={100}>
                  <Typography align="center" color="textSecondary">
                    {t('No document')}
                  </Typography>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>

      {editDocumentOpen && (
        <AddDocument
          open={editDocumentOpen}
          handleClose={() => {
            setEditDocumentOpen(false);
            setSelectedArticle(null);
          }}
          section={sectionId}
          articleId={selectedArticle}
        />
      )}

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>{t('Confirm Delete')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Are you sure you want to delete this article? This action cannot be undone.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EducationSection;
