import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MenuIcon from '@mui/icons-material/Menu';
import MessageIcon from '@mui/icons-material/Message';
import { useNavigate, useLocation } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import SchoolIcon from '@mui/icons-material/School';
import {
  Box, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon,
  ListItemText, Toolbar, Tooltip, useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GreyboxLogo from '../../img/Logo_Greybox.png';
import Logo from '../../img/Logo';
import ProfileButton from './ProfileButton';
import ClinicButton from './ClinicButton';
import SearchAccountField from './accounts/SearchAccountField';
import AppBarUnregistered from './AppbarUnregistered';

const ListElement = ({ item, open, handleNavigation }) => {
  const selected = window.location.pathname.includes(item.id);

  return (
    <Tooltip title={!open ? item.name : ''} placement="right" arrow>
      <ListItem
        key={item.id}
        disablePadding
        sx={{
          mb: 1,
          mx: 1,
          width: 'auto',
        }}
      >
        <ListItemButton
          id={item.id}
          onClick={() => handleNavigation(item.path)}
          selected={selected}
          sx={{
            minHeight: 48,
            height: 48,
            width: '100%',
            px: selected ? 0 : 2,
            justifyContent: open ? 'initial' : 'center',
            borderRadius: 1,
            '&.Mui-selected': {
              backgroundColor: (theme) => theme.palette.primary.light,
            },
          }}
        >
          {selected && (
            <Box
              sx={{
                width: 3,
                minWidth: 3,
                height: '80%',
                backgroundColor: (theme) => theme.palette.primary.main,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
              }}

            />
          )}
          <ListItemIcon
            sx={{
              minWidth: 0,
              ml: selected ? 1.2 : 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: selected ? 'primary.main' : 'text.secondary',
            }}
          >
            {item.icon}
          </ListItemIcon>
          {open && (
            <ListItemText
              primary={item.name}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: selected ? 'primary.main' : 'text.secondary',
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
};

const AppBar = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { access, accountId } = useSelector((state) => state.user);
  const { authState } = useSelector((state) => state.authorization);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const isCompleteQuestionnaire = authState === 'questionnaire';
  const isRegistration = location.pathname.includes('complete-registration');
  const { clinic } = useSelector((state) => state.clinic);

  const [searchOpen, setSearchOpen] = useState(false);

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      setOpen(false);
    }
  };

  const STAFF_NAVIGATION = [
    {
      name: t('Dashboard'),
      id: 'dashboard',
      path: '/dashboard',
      icon: <DashboardIcon />,
    },
    {
      name: t('Messages'),
      id: 'messages',
      path: '/messages',
      icon: <MessageIcon />,
    },
    {
      name: t('Education'),
      id: 'education',
      path: '/education',
      icon: <SchoolIcon />,
    },

  ];

  const ADMIN_NAVIGATION = [
    {
      name: t('Invitations'),
      id: 'invitations',
      path: '/pending-invitations',
      icon: <GroupAddIcon sx={{ ml: 0.5 }} />,
    },
    {
      name: t('Administration'),
      id: 'clinic-settings',
      path: '/clinic-settings',
      icon: <SettingsIcon />,
    },
  ];

  const PATIENT_NAVIGATION = [
    {
      name: t('Overview'),
      id: 'patient-profile',
      path: `/patient-profile/${accountId}/overview`,
      icon: <DashboardIcon />,
    },
    {
      name: t('Messages'),
      id: 'messages',
      path: '/messages',
      icon: <MessageIcon />,
    },
    {
      name: t('Education'),
      id: 'education',
      path: '/education',
      icon: <SchoolIcon />,
    },
  ];

  const navigation = [];

  if (!isCompleteQuestionnaire) {
    if (access === 'PT') {
      navigation.push(...STAFF_NAVIGATION);
    } else {
      navigation.push(...PATIENT_NAVIGATION);
    }
  }

  if (clinic && clinic.permissions.includes('change_company')) {
    navigation.push(...ADMIN_NAVIGATION);
  }

  if (isRegistration) {
    return <AppBarUnregistered>{children}</AppBarUnregistered>;
  }

  return (
    <Box
      display="flex"
      sx={{
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.background.paper,
          width: '100%',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'fixed',
        }}
      >
        {isMobile && searchOpen ? (
          <Box sx={{ width: '100%' }}>
            <SearchAccountField setOpen={setSearchOpen} />
          </Box>
        ) : (
          <Toolbar sx={{ height: 60 }}>
            {!isMobile && (
              <>
                <img src={GreyboxLogo} alt="Greybox" height="30px" />
                <Logo />
              </>
            )}
            {!isCompleteQuestionnaire && (
              <IconButton
                data-cy="appBar-menu-button"
                onClick={() => setOpen(!open)}
                sx={{ ml: isMobile ? 0 : 1 }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ flexGrow: 1 }}>
              <SearchAccountField isButton={isMobile} setOpen={setSearchOpen} />
            </Box>
            <Box sx={{ ml: 'auto', display: 'flex' }}>
              <ClinicButton />
              <ProfileButton />
            </Box>
          </Toolbar>
        )}
      </Box>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        hideBackdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer,
          marginTop: '80px',
          '& .MuiDrawer-paper': {
            border: 'none',
            marginTop: '80px',
            width: open ? '245px' : '65px',
            transition: (theme) => theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
          width: open ? '245px' : '65px',
          transition: (theme) => theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),

        }}
        open={open}
        data-cy="app-bar-drawer"
      >
        <List>
          {navigation.map((item) => (
            <ListElement
              key={item.id}
              item={item}
              open={open}
              handleNavigation={handleNavigation}
            />
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: '64px',
          width: open ? 'calc(100% - 245px)' : 'calc(100% - 65px)',
          overflow: 'auto',
          mr: isMobile ? 0 : 3,
          bgcolor: (theme) => theme.palette.background.default,
          px: '1rem',
          pt: 3,
          minHeight: 'calc(100vh - 90px)',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AppBar;
