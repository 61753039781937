import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { emptyObject } from '../../../helper-functions';
import { greyboxApiActions } from '../../../redux/api';
import TargetForm from '../addHealthTargets/addHealthTargetsForm';
import ResultsForm from '../addLabResults/addLabResForm';
import LabResultsTable from './labResultsTable';
import { lab_wrapper_style } from './labResults_style';

const LabResultsWrapper = (props) => {
  const { uuid } = props;
  const [latestTargets, setLatestTargets] = useState(null);
  const { labResultsConfig } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { data, isLoading } = labResultsConfig.list({ clinic: clinic.id });

  if (isLoading) {
    return null;
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div style={lab_wrapper_style.wrapBtn}>
        <ResultsForm
          lab_results_config={data}
          uuid={uuid}
          latest={!emptyObject(latestTargets) && latestTargets}
        />
        <TargetForm
          lab_results_config={data}
          uuid={uuid}
          latest={!emptyObject(latestTargets) && latestTargets}
        />
      </div>
      <div data-cy="LabResultsWrapper">
        <LabResultsTable
          config={data}
          uuid={uuid}
          setLatestTargets={setLatestTargets}
        />
      </div>
    </Paper>
  );
};

export default LabResultsWrapper;
