import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Box, Button, ButtonGroup, LinearProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AnswerSummary from './AnswerSummary';
import { computeJson } from '../../../../helper-functions/jsonCodeUtils';
import { conditionParser } from './utils';
import { Question, Answers } from '../../../../types';
import PanelNavigation from './PanelNavigation';
import PanelSelector from './PanelSelector';

type QuestionnaireFillerProps = {
  patientUuid: string;
  questions: Question[];
  setHasUnsaved: (hasUnsaved: boolean) => void;
  onComplete: (answersId: string) => void;
  prefilledAnswers: Answers;
};

const QuestionnaireFiller = (props: QuestionnaireFillerProps) => {
  const {
    patientUuid, questions, setHasUnsaved, onComplete, prefilledAnswers = {},
  } = props;
  const [current, setCurrent] = useState(0);
  const [answers, setAnswers] = useState<Answers>(prefilledAnswers);
  const [others, setOthers] = useState<Answers>({});
  const [direction, setDirection] = useState<number>(1);
  const [showSummary, setShowSummary] = useState(false);
  const sliderRef = React.useRef(null);
  const answersCount = Object.keys(answers).length;
  const progress = answersCount > 0 ? (answersCount / questions.length) * 100 : 0;
  const isLast = current === questions.length - 1;
  const [hasBeenCompleted, setHasBeenCompleted] = useState(false);
  const hasAnswered = questions[current].id in answers && answers[questions[current].id] !== '';

  const handlePrevious = () => {
    setDirection(-1);
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (questions[current].type_of_q === 'SA'
    && questions[current].sa_question.allow_empty
    && !hasAnswered) {
      setAnswers((prev) => ({
        ...prev,
        [questions[current].id]: '',
      }));
    }
  }, [current]);

  const handleBackFromSummary = (index: number) => {
    setCurrent(index);
    setShowSummary(false);
    setHasBeenCompleted(true);
  };

  useEffect(() => {
    if (progress > 0 && setHasUnsaved) {
      setHasUnsaved(true);
    }
  }, [progress]);

  useEffect(() => {
    if (current >= questions.length) {
      setShowSummary(true);
    }
  }, [current, questions.length]);

  const handleNewAnswer = (questionId: number, answer: string) => {
    // Answer is added for the first time
    if (!(questionId in answers)) {
      if (answer !== '' && answer !== null) {
        setAnswers((prev) => ({
          ...prev,
          [questionId]: answer,
        }));

        // Go directly to the next question if it is a single choice question
        if (!questions[current].mc_question?.multi_select
          && !questions[current].mc_question?.mc_question_choice_other
          && questions[current].type_of_q !== 'SA') {
          handleNext();
        }
      } else {
        // Do nothing
      }
    } else {
      // Answer is updated
      setAnswers((prev) => ({
        ...prev,
        [questionId]: answer,
      }));
    }
  };

  const handleConditionCheck = (): number | null => {
    const { condition } = questions[current];
    const nextPosition = current + (1 * direction);

    if (questions[current].type_of_q === 'QA' && questions[current].id in answers) {
      // Question is a already answered QA, skip it
      if (questions[current].id in answers) { return nextPosition; }
    }

    // If no condition simply return the question
    if (!condition) {
      return current;
    }

    const parsedCondition = conditionParser(condition, answers);

    // If condition is met show the question
    if (computeJson(parsedCondition)) {
      return current;
    }

    // So progress bar is not stuck
    setAnswers((prev) => ({
      ...prev,
      [questions[current].id]: '',
    }));

    // If next question is out of bounds, show summary
    if (nextPosition >= questions.length) {
      setShowSummary(true);
      return current;
    }

    setCurrent(nextPosition);
    return nextPosition;
  };

  const handleNext = () => {
    if (isLast) {
      setShowSummary(true);
    } else {
      setDirection(1);
      setCurrent(current + 1);
    }
  };

  const questionIndices = React.useMemo(() => [
    current - 1 >= 0 ? current - 1 : null,
    handleConditionCheck(),
    current + 1 < questions.length ? current + 1 : null,
  ], [current]);

  const handleNewOther = (questionId: number, answer: string) => {
    setOthers((prev) => ({
      ...prev,
      [questionId]: answer,
    }));
  };

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        flexGrow: 1,
      }}
      ref={sliderRef}
    >
      <LinearProgress color="success" variant="determinate" value={progress} />
      { showSummary ? (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <AnswerSummary
            patientUuid={patientUuid}
            questions={questions}
            handleBackFromSummary={handleBackFromSummary}
            onComplete={onComplete}
            answers={answers}
            others={others}
          />
        </Box>
      ) : (
        <>
          <Box sx={{
            height: '100%',
            overflow: 'hidden',
            position: 'relative',
          }}
          >
            {questionIndices.map(
              (index, i) => index !== null && (
                <Box
                  key={questions[index].id}
                  sx={{
                    transform: `translateY(${(index - current) * 100}%)`,
                    display: 'flex',
                    visibility: `${(i !== 1) ? 'hidden' : 'visible'}`,
                    transition: 'transform 0.5s ease-in-out',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '95%',
                    width: '100%',
                    pt: 2,
                    position: 'absolute',
                    overflow: 'auto',
                  }}
                >
                  <PanelNavigation
                    goNext={handleNext}
                    hasBeenCompleted={hasBeenCompleted}
                    setShowSummary={setShowSummary}
                    hasAnswered={hasAnswered}
                    isCurrent={index === current}
                    allowEmpty={questions[current].sa_question?.allow_empty}
                  >
                    <PanelSelector
                      question={questions[index]}
                      questions={questions}
                      setAnswers={handleNewAnswer}
                      setOthers={handleNewOther}
                      answers={answers}
                      setHasUnsaved={setHasUnsaved}
                      isCurrent={index === current}
                    />
                  </PanelNavigation>
                </Box>
              ),
            )}
          </Box>
          <Box display="flex" alignItems="flex-end" justifyContent="flex-end" height="5%">
            <ButtonGroup variant="contained" sx={{ m: 2 }}>
              <Button disabled={current === 0} onClick={handlePrevious}>
                <ArrowDropUpIcon />
              </Button>
              <Button disabled={isLast || !hasAnswered} onClick={handleNext}>
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </>
      )}
    </Box>
  );
};

export default QuestionnaireFiller;
