import { Box, Button, Paper, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../redux/api';
import GeneralInfo from './GeneralInfo';
import WorkflowDetails from './WorkflowDetails';
import { useTranslation } from 'react-i18next';

const WorkflowForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isEdit = !!id;
    const { workflow } = greyboxApiActions;
    const { data, isLoading, isFetching } = isEdit && workflow.get(id);
    const [workflowInstance, setWorkflowInstance] = useState({ workflow: { input: {} } });
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if (data) {
            setWorkflowInstance(data);
        }
    }, [data]);


    const handleChange = (event) => {
    };

    const handleSave = () => {
        // Save logic here
        navigate(-1);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <Box width="100%">
            <Paper sx={{ p: 2, mb: 2 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>{isEdit ? t('Edit Workflow') : t('Add Workflow')}</Typography>
                <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
                    <Tab label={t('General Information')} />
                    <Tab label={t('Details')} />
                </Tabs>
                <Box hidden={tabIndex !== 0}>
                    <GeneralInfo workflowInstance={workflowInstance} handleChange={handleChange} />
                </Box>
                <Box hidden={tabIndex !== 1}>
                    <WorkflowDetails workflow={workflowInstance.workflow} handleChange={handleChange} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                    <Button onClick={() => navigate(-1)} color="secondary" sx={{ mr: 1 }}>
                        {t('Cancel')}
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        {t('Save')}
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default WorkflowForm;
