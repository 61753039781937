import React from 'react'
import { Box, Paper, Tab, Tabs } from '@mui/material';
import BasicInfoTab from './informations/BasicInfoTab';
import ThresholdTab from './informations/ThresholdTab';
import ContactTab from './informations/ContactTab';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const { access } = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper sx={{ width: '100%', height: 'fit-content' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} >
          <Tab label={t("Informations")} />
          <Tab label={t("Contacts")} />
          {access === 'PT' && <Tab label={t("Thresholds")} />}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <BasicInfoTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ContactTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ThresholdTab />
      </CustomTabPanel>
    </Paper>
  );
}
