import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Tab, Tabs,
  Typography
} from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { greyboxApiActions } from '../../../../redux/api';
import ErrorMessage from '../../../form-inputs/ErrorMessage';
import FormikTextField from '../../../form-inputs/FormikTextField';
import MarkdownPreview from './MarkdownPreview';
import MaxDosePerPeriod from './MaxDosePerPeriod';
import Timing from './Timing';
import TimingCodeAutocomplete from './TimingCodeAutocomplete';
import {
  APIToFormik,
  formatDosageToMarkdown,
  formikToApi,
  generateMedicationInfo,
  getStatus,
  handlePreset,
  parseIngredients,
  parseMedicationItem,
  parseTotalDosage
} from './utils';

import moment from 'moment';
import AiInstructionInputField from './AiInstructionInputField';

const MedicationDetails = ({ searchedMed, handleQuit, editSelection }) => {
  const { t } = useTranslation();
  const medInfo = searchedMed ? searchedMed : editSelection?.medication;
  const { medication } = greyboxApiActions;
  const { uuid } = useParams();
  const ingredients = parseIngredients(medInfo.active_ingredient);
  const initDosage = parseTotalDosage(Object.values(ingredients));
  const medStringInfo = generateMedicationInfo(medInfo, initDosage)
  const [tabIndex, setTabIndex] = useState(0);
  const [preview, setPreview] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [postMedication, result] = medication.add();
  const [updateMedication] = medication.update();

  const initialValues = editSelection
    ? APIToFormik(editSelection)
    : {
      frequency: 1,
      period: 1,
      periodUnit: 'd',
      doseAndRate: [
        {
          doseQuantity: {
            value: initDosage.value,
            unit: initDosage.unit
          }
        }
      ],
      isMaxTolerated: false,
      text: '',
      startDate: new Date().toISOString().split('T')[0],
      endDate: null,
      asNeeded: false,
      site: null,
      route: null,
      method: null,
      timing: {
        code: 'BID',
        frequency: 1,
        frequencyMax: null,
        period: 1,
        periodUnit: 'd',
        when: [],
        offset: null,
        duration: null,
        durationUnit: null,
        dayOfWeek: [],
      },
      maxDosePeriod: {
        maxDose: null,
        period: null,
        periodUnit: '',
      },
      additionalInstruction: [],
    };

  const validationSchema = yup.object({
    doseAndRate: yup.array().of(
      yup.object().shape({
        doseQuantity: yup.object().shape({
          value: yup.number().required(t('Required')).positive(),
          unit: yup.string().required(t('Required'))
        }).required()
      })
    ).required('Required'),
    site: yup.string().nullable(),
    route: yup.string().nullable(),
    method: yup.string().nullable(),
    asNeeded: yup.boolean().nullable(),
    isMaxTolerated: yup.boolean().nullable(),
    maxDosePeriod: yup.object({
      maxDose: yup
        .number()
        .positive()
        .nullable(),
      period: yup
        .number()
        .nullable(),
      periodUnit: yup
        .string()
        .nullable()
        .when('period', (period, schema) => {
          return period ? schema.required(t('Period unit is required when period is present')) : schema;
        }),
    }).nullable(),
    timing: yup.object({
      frequency: yup
        .number()
        .required()
        .positive()
        .when('frequencyMax', (frequencyMax, schema) =>
          frequencyMax
            ? schema.max(frequencyMax, t('Frequency must be less than or equal to frequency max'))
            : schema
        ),
      frequencyMax: yup.number().positive().nullable(),
      period: yup.number().required().positive(),
      periodUnit: yup.string().required(),
      when: yup.array().of(yup.string()).nullable()
        .test(
          'when-and-timeOfDay-exclusive',
          t('Cannot have both time of day and when'),
          function (whenValue) {
            const timeOfDay = this.parent.timeOfDay;
            const hasWhen = Array.isArray(whenValue) && whenValue.length > 0;
            const hasTimeOfDay = Array.isArray(timeOfDay) && timeOfDay.length > 0;
            return !(hasWhen && hasTimeOfDay);
          }
        ),

      timeOfDay: yup.array().of(yup.string()).nullable()
        .test(
          'timeOfDay-and-when-exclusive',
          t('Cannot have both time of day and when'),
          function (timeOfDayValue) {
            const when = this.parent.when;
            const hasTimeOfDay = Array.isArray(timeOfDayValue) && timeOfDayValue.length > 0;
            const hasWhen = Array.isArray(when) && when.length > 0;
            return !(hasTimeOfDay && hasWhen);
          }
        ),
      offset: yup.number().nullable(),
      duration: yup.number().positive().nullable(),
      durationUnit: yup.string().nullable().when('duration', (duration, schema) => {
        return duration ? schema.required(t('Duration unit is required when duration is present')) : schema;
      }),
      dayOfWeek: yup.array().nullable(),
      count: yup.number().positive().nullable(),
      countMax: yup.number().positive().nullable(),
    }).nullable(),
    additionalInstruction: yup.array().of(yup.string().required(t('Required'))).nullable(),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);

    // Prepare the data to be sent to the API
    const dosage = formikToApi(values);
    const body = {
      start_date: values.startDate,
      end_date: values.endDate,
      account: uuid,
      status: getStatus(values.startDate, values.endDate),
      dosage_fhir: [dosage],
      medication: medInfo.uuid,
    };

    try {
      // Add a new medication
      const result = await postMedication({ body });
      // Close the form or perform any cleanup
      handleQuit(parseMedicationItem(result.data));
      setSubmitting(false);

      if (editSelection) {
        // Update the existing medication to 'entered-in-error' status
        updateMedication({
          id: editSelection.uuid,
          body: { status: 'entered-in-error' },
        });
      }
    } catch (error) {
      setSubmitting(false);
      const errors = error.data || {};
      if (errors.error) {
        setErrorMessage(errors.error);
      }
      setErrors(errors);
    }
  };



  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ values,
        handleChange,
        setFieldValue,
        isSubmitting,
        resetForm,
        dirty,
        isValid,
        errors,
        touched, }) => {


        useEffect(() => {
          if (Object.keys(errors).length > 0) {
            console.error('Form Errors:', errors);
          }
        }, [errors]);

        const handleTabChange = (event, newIndex) => {
          setTabIndex(newIndex);
          if (values.timing.code && newIndex === 1) {
            setFieldValue('timing.code', null);
          }
        };

        useEffect(() => {
          const dosage = formikToApi(values);
          setPreview(formatDosageToMarkdown(dosage, t));
        }, [values]);

        useEffect(() => {
          if (values.timing?.code) {
            const preset = handlePreset(values.timing.code);
            if (preset) {
              setFieldValue('timing', preset);
            }
          }
        }, [values.timing?.code, setFieldValue]);

        useEffect(() => {
          if (values.startDate && values.timing?.duration && values.timing?.durationUnit) {
            const unitMap = {
              s: 'seconds',
              min: 'minutes',
              h: 'hours',
              d: 'days',
              wk: 'weeks',
              mo: 'months',
              a: 'years'
            };

            const durationUnit = unitMap[values.timing.durationUnit];

            if (durationUnit) {
              const endDate = moment(values.startDate)
                .add(values.timing.duration, durationUnit)
                .format('YYYY-MM-DD');

              setFieldValue('endDate', endDate);
            }
          }
        }, [values.startDate, values.timing?.duration, values.timing?.durationUnit]);


        return (
          <Form>
            <DialogContent sx={{ overflowY: 'auto', maxHeight: '70vh' }} dividers>
              <Typography variant="h6" fontWeight={600}>
                {medInfo.brand_name}
              </Typography>
              <Box sx={{ mb: 2 }}>
                {Object.entries(ingredients).map(([key, val]) => (
                  <Typography variant="caption" display="block" key={key}>
                    {val.name} - {val.dosageLabel}
                  </Typography>
                ))}
                {medInfo.active_ingredient.length > 1 && (
                  <Typography variant="caption">{`${t('Total')} ${initDosage.value} ${initDosage.unit}`}</Typography>
                )}
              </Box>
              <MarkdownPreview preview={preview} />
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2, mb: 2 }}>
                <FormikTextField
                  required
                  name="doseAndRate[0].doseQuantity.value"
                  label={t('Dose')}
                  type="number"
                  sx={{ mr: 2 }}
                />
                <FormikTextField
                  required
                  name="doseAndRate[0].doseQuantity.unit"
                  label={t('Unit')}
                />
                <TimingCodeAutocomplete name="timing.code" label={t('Preset')} />
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2, mb: 2 }}>
                <FormikTextField required name="startDate" label={t('Start Date')} type="date" />
                <FormikTextField name="endDate" label={t('End Date')} type="date" />
              </Box>

              {/* Display Error Message */}
              <ErrorMessage message={errorMessage} />

              <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
                <Tab label={t('Simple')} />
                <Tab label={t('Advanced')} />
              </Tabs>

              {tabIndex === 0 && (
                <AiInstructionInputField medication_input={medStringInfo} />
              )}

              {tabIndex === 1 && (
                <>
                  <Timing />
                  <MaxDosePerPeriod />
                  <Box sx={{ mt: 1, mb: 2, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 2 }}>
                    <FormikTextField name="site" label={t('Site')} tooltipText={t('Body site to administer to')} />
                    <FormikTextField name="route" label={t('Route')} tooltipText={t('How drug should enter body')} />
                    <FormikTextField name="method" label={t('Method')} tooltipText={t('Technique for administering medication')} />
                  </Box>
                  <Box sx={{ mt: 1, mb: 2, width: '100%' }}>
                    <FormikTextField minRows={2} name="patientInstruction" label={t('Patient Instruction')} fullWidth multiline />
                  </Box>
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <FieldArray name="additionalInstruction">
                      {({ push, remove, form }) => (
                        <>
                          {form.values.additionalInstruction && form.values.additionalInstruction.map((instruction, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <FormikTextField
                                name={`additionalInstruction[${index}]`}
                                label={`${t('Additional Instruction')} ${index + 1}`}
                                minRows={2}
                                multiline
                                fullWidth
                              />
                              <IconButton onClick={() => remove(index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}
                          <Button onClick={() => push('')} fullWidth variant="outlined">
                            {t('Add Additional Instruction')}
                          </Button>
                        </>
                      )}
                    </FieldArray>
                  </Box>
                </>
              )}
            </DialogContent>
            {/* Display validation errors */}
            {/* {errors && (
              <Typography color="error">{errors}</Typography>
            )} */}
            <DialogActions>
              <Button onClick={handleQuit}>{t('Cancel')}</Button>
              {editSelection && (
                <Button onClick={() => resetForm()}>{t('Reset')}</Button>
              )}
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                loading={isSubmitting}
                disabled={!dirty || !isValid || isSubmitting}
              >
                {t('Add')}
              </LoadingButton>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MedicationDetails;
