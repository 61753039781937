import 'moment/locale/fr';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import NavigationMenu from '../../navigation/NavigationMenu';

const PatientProfileNavigation = () => {
  const {
    questionnaires,
    vitalsConfig,
    clinic,
  } = useSelector((state) => state.clinic);
  const { t } = useTranslation();
  const { uuid } = useParams();
  const url = `/patient-profile/${uuid}`;
  const configs = clinic.config.features_enable;
  const { access } = useSelector((state) => state.user);

  const questionnairePaths = useMemo(() => {
    if (questionnaires.length > 0) {
      return questionnaires.map((q) => ({
        id: q.id,
        label: q.name,
        url: `${url}/questionnaire/${q.id}`,
      }));
    }
    return [];
  }, [questionnaires]);

  const navigation = {
    clinical_data: {
      id: 'clinical-data',
      label: t('Clinical Data'),
      url: `${url}/clinical-data`,
      access: ['PT'],
    },
    alert: {
      id: 'alert',
      label: t('Alerts'),
      url: `${url}/alert`,
      access: ['PT'],
    },
    medical_acts: {
      id: 'medical-acts',
      label: t('Follow-ups'),
      url: `${url}/medical-acts`,
      access: ['PT'],
    },
    reminder: {
      id: 'reminder',
      label: t('Reminders'),
      url: `${url}/reminder`,
      access: ['PT'],
    },
    clinical_note:
    {
      id: 'clinical-note',
      label: t('Clinical notes'),
      url: `${url}/clinical-note`,
      access: ['PT'],
    },
    careTeam: {
      id: 'care-team',
      label: t('Care Team'),
      url: `${url}/care-team`,
      access: ['PT'],
    },
    lab_results: {
      id: 'lab_results',
      label: t('Lab Results'),
      url: `${url}/lab-results`,
      access: ['PT'],
    },
    medications: {
      id: 'medications',
      label: t('Treatment Plans'),
      url: `${url}/medications`,
      access: ['PT'],
    },
    reports: {
      id: 'reports',
      label: t('Reports'),
      url: `${url}/reports`,
      access: ['PT'],
    },
    calendar: {
      id: 'calendar',
      label: t('Calendar'),
      url: `${url}/calendar`,
    },
    questionnaires_calendar: {
      id: 'questionnaires_calendar',
      label: t('Questionnaires Calendar'),
      url: `${url}/questionnaires-calendar`,
    },
    documents: {
      id: 'documents',
      label: t('Documents'),
      url: `${url}/documents`,
    },
  };

  if (questionnaires.length > 0) {
    navigation.questionnaires_history = {
      id: 'questionnaires-history',
      label: t('Completed Questionnaires'),
      url: `${url}/questionnaires-history`,
    }
    navigation.questionnaire = {
      id: 'questionnaire',
      label: t('Questionnaires'),
      url: `${url}/questionnaire`,
      subPaths: questionnairePaths,
    };
  }

  const navConfig = useMemo(() => {
    const vitalsPath = Object.values(vitalsConfig).filter((v) => v.enabled).map((v) => (
      {
        id: v.short_code,
        label: v.name,
        url: `${url}/vitals/${v.short_code}`,
      }
    ));

    const nav = [
      {
        id: 'overview',
        label: access === 'P' ? t('Home page') : t('Overview'),
        url: `${url}/overview`,
      },
      {
        id: 'vitals',
        label: t('Vitals'),
        subPaths: vitalsPath,
      },
      {
        id: 'information',
        label: t('Information'),
        url: `${url}/information`,
      },
      {
        id: 'care-team',
        label: t('Care Team'),
        url: `${url}/care-team`,
        access: ['PT'],
      },
    ];

    // remove Vitals nav if no vitals are enabled
    if (vitalsPath.length === 0) {
      nav.splice(1, 1);
    }

    Object.entries(configs).forEach(([key, value]) => {
      if (key in navigation && value) {
        nav.push(navigation[key]);
      }
    });

    return nav;
  }, [vitalsConfig, uuid]);

  return <NavigationMenu navConfig={navConfig} />;
};

export default PatientProfileNavigation;
