import { Alert, Box, Button, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../../redux/api';
import ObservationChart from '../../chart/ObservationChart';
import { DateRangePicker } from '../dateRangePicker';
import LabResultDetailsTable from '../labResults/LabResultDetailsTable';
import AllergyIntoleranceTable from './AllergyIntoleranceTable';

const ClinicalDataDetails = () => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { clinicalDataConfig } = greyboxApiActions;
  const { clinicalDataCode } = useParams();
  const { data = [], isFetching, isError, refetch } = clinicalDataConfig.list({ clinic: clinic.id });

  const config = data.find((item) => item.short_code === clinicalDataCode);

  if (isError) {
    return (
      <Box width="100%" mt={2}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {t('An error occurred while fetching the clinical data.')}
        </Alert>
        <Button variant="contained" color="primary" onClick={refetch}>
          {t('Retry')}
        </Button>
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {isFetching ? (
          <Skeleton variant="text" width="30%" height={40} />
        ) : (
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
            {config.name}
          </Typography>
        )}
        <DateRangePicker />
      </Box>
      {isFetching ? (
        <Skeleton variant="rectangular" width="100%" height={400} sx={{ mt: 2 }} />
      ) : (
        <>
          {config?.referenceModel === 'Observation' && (
            <ObservationChart
              measures={[{
                ...config,
                id: config.uuid,
                is_specific: false,
              }]}
              config={{
                label: config.name,
                unit: config.unit,
                chartType: 'line',
                values: ['observation'],
                colors: ['spo2'],
              }}
              detailed
            />
          )}
          <Paper sx={{ width: '100%', mt: 1 }}>
            {config.referenceModel === 'AllergyIntolerance' ? (
              <AllergyIntoleranceTable config={config} />
            ) : (
              <LabResultDetailsTable config={config} />
            )}
          </Paper>
        </>
      )}
    </Box>
  );
};

export default ClinicalDataDetails;
