import { format } from 'date-fns';
import { enUS, fr , es, pt, de, it} from 'date-fns/locale';
import i18n from '../i18n';

// Utility function to get date-fns locale based on i18n language
const getLocale = (language) => {
  const localeMap = {
    en: enUS,
    fr: fr,
    es:es,
    pt:pt,
    de:de,
    it,it
  };

  return localeMap[language] || enUS; // Fallback to enUS if locale not found
};

// Utility function to format date using date-fns with i18next locale
export const formatDateWithLocale = (date, formatType = 'PP') => {

  // Get the locale from the current i18n language
  const locale = getLocale(i18n.language);

  // Format the date using date-fns, with locale support
  return format(new Date(date), formatType, { locale });
};
