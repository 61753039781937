import {
  Box, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../../redux/api';
import QuestionnaireResultChart from '../../chart/QuestionnaireResultChart';
import DateRangePicker from '../dateRangePicker/DateRangePicker';
import QuestionnaireDetailTable from './QuestionnaireDetailTable';
import DialogQuestionnaire from './filler/DialogQuestionnaire';

const QuestionnaireDetails = ({ patientUuid }) => {
  const [open, setOpen] = React.useState(false);
  const { questionnaireId } = useParams();
  const { questionnaire } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { data, isFetching } = questionnaire.list({ id: questionnaireId, clinic: clinic.id });
  const [previousAnswers, setPreviousAnswers] = React.useState(null);

  useEffect(() => {
    if (previousAnswers) {
      setOpen(true);
    }
  }, [previousAnswers]);

  const handleClose = () => {
    setOpen(false);
    setPreviousAnswers(null);
  };

  if (isFetching || !data) {
    return null;
  }

  return (
    <Box sx={{ width: '100%', p: 2, height: 'fit-content' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {data.name}
        </Typography>
        <DateRangePicker />
      </Box>
      {data.has_score && (
        <QuestionnaireResultChart detailed patientUuid={patientUuid} questionnaireId={questionnaireId} />
      )}
      <QuestionnaireDetailTable
        questionnaireId={questionnaireId}
        patientUuid={patientUuid}
        setOpen={setOpen}
        setPreviousAnswers={setPreviousAnswers}
        type={data.type}
      />
      <DialogQuestionnaire
        patientUuid={patientUuid}
        open={open}
        onComplete={() => setOpen(false)}
        handleClose={handleClose}
        questionnaireId={questionnaireId}
        previousAnswers={previousAnswers}
      />
    </Box>
  );
};

export default QuestionnaireDetails;
