import React from 'react'
import { Dialog, MenuItem, DialogActions, Button, DialogContent } from "@mui/material"
import { useFormik } from "formik"
import Row from "../Row"
import { useTranslation } from "react-i18next"
import { FormSelect, FormTextField, PhoneNumber } from "../form-inputs"

const isPhone = (system) => system === 'phone' || system === 'fax' || system === 'sms'

const Telecom = ({telecom, onChange, open , onClose}) => {
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      system: telecom.system || 'phone',
      value: telecom.value,
      use: telecom.use || 'home',
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      onChange(values)
      onClose()
    },
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }
  
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Row>
            <FormSelect formik={formik} name="system" label={t("Type")}>
              <MenuItem value="phone">{t("Phone")}</MenuItem>
              <MenuItem value="email">{t("Email")}</MenuItem>
              <MenuItem value="fax">{t("Fax")}</MenuItem>
              <MenuItem value="sms">SMS</MenuItem>
              <MenuItem value="other">{t("Other")}</MenuItem>
            </FormSelect>
            {
              isPhone(formik.values.system) ? (
                <PhoneNumber required formik={formik} name="value" label={t("Contact")} />
              ) : (
                <FormTextField required formik={formik} name="value" label={t("Contact")} />
              )
            }
          </Row>
          <Row>
            <FormSelect formik={formik} name="use" label={t("Use")}>
              <MenuItem value="home">{t("Home")}</MenuItem>
              <MenuItem value="mobile">{t("Mobile")}</MenuItem>
              <MenuItem value="work">{t("Work")}</MenuItem>
            </FormSelect>
          </Row>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("Cancel")}</Button>
          <Button type="submit">{t("Save")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default Telecom