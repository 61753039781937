import {
  Autocomplete, CircularProgress, Stack, Button, Menu,
  Dialog, DialogActions, DialogContent, IconButton, MenuItem, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, Skeleton,
} from '@mui/material';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';
import { parseParticipants } from './utils';
import TableSkeleton from '../skeletons/TableSkeleton';

const SubjectTable = () => {
  const { t } = useTranslation();
  const { careTeam } = greyboxApiActions;
  const { id } = useParams();
  const { data = [], isFetching } = careTeam.list(
    { 'participant:CareTeam.identifier': id },
  );
  const [open, setOpen] = React.useState(false);
  const [hovering, setHovering] = React.useState(null);
  const [updateCareTeam] = careTeam.update();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const handleDelete = (event, resourceId) => {
    event.stopPropagation();
    const subjectCareTeam = data.entry.find((item) => item.resource.id === resourceId);
    updateCareTeam({
      id: subjectCareTeam.resource.id,
      body: {
        participant: parseParticipants(
          subjectCareTeam.resource.participant.filter((item) => item.member.reference !== `CareTeam/${id}`),
        ),
      },
    });
  };

  const handleNavigation = (resource) => {
    navigate(`/patient-profile/${resource.subject.reference.split('/')[1]}/care-team/${resource.id}`);
  };

  if (isFetching) {
    return (
      <TableContainer>
        <Skeleton variant="rectangular" width="90px" />
        <TableSkeleton columns={[{ id: 'name' }]} rowCount={4} />
      </TableContainer>
    );
  }

  return (
    <>
      <TableContainer sx={{ mb: 2 }} component={Paper}>
        <Stack
          direction="row"
          spacing={1}
          sx={{ p: 1, borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
        >
          <Button startIcon={<AddIcon />} variant="outlined" onClick={() => setOpen(true)}>
            {t('Add')}
          </Button>
        </Stack>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={120}>{t('Name')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.entry?.length > 0 ? (
              data.entry.map((item) => (
                <TableRow
                  key={item.resource.id}
                  sx={{ height: 68, '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}
                  onMouseEnter={() => setHovering(item.resource.id)}
                  onMouseLeave={() => setHovering(null)}
                >
                  <TableCell>{item.resource.subject.display}</TableCell>
                  <TableCell align="right" sx={{ width: 50 }}>
                    {hovering === item.resource.id && (
                      <div>
                        <IconButton
                          size="small"
                          onClick={(event) => setAnchorEl(event.currentTarget)}
                          sx={{ transition: 'all 0.7s ease-in-out' }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={() => setAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                        >
                          <MenuItem onClick={() => handleNavigation(item.resource)}>
                            {t('Navigate to care team')}
                          </MenuItem>
                          <MenuItem
                            sx={{ color: (theme) => theme.palette.error.main }}
                            onClick={(event) => handleDelete(event, item.resource.id)}
                          >
                            {t('Delete')}
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2} height={100}>
                  <Typography align="center" color="textSecondary">
                    {t('No patient')}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <SubjectDialog
        open={open}
        handleClose={() => setOpen(false)}
        subjects={data.entry?.map((item) => item.resource.subject.reference.split('/')[1])}
      />
    </>
  );
};

const SubjectDialog = ({ open, handleClose, subjects }) => {
  const { t, i18n } = useTranslation();
  const { careTeam, account } = greyboxApiActions;
  const [patientUuid, setPatientUuid] = React.useState(null);
  const [createCareTeam] = careTeam.add();
  const { clinic } = useSelector((state) => state.clinic);
  const [searchInput, setSearchInput] = React.useState('');
  const [updateCareTeam] = careTeam.update();
  const { id } = useParams();
  const { data = [], isFetching } = account.list(
    { search: searchInput, clinic: clinic.id, acc_type__in: 'P' },
    { skip: searchInput.length < 3 },
  );
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const subjectCareTeams = careTeam.list({
    clinic: clinic.id,
    'status:in': 'active,proposed',
    'managing-organization:Organization.identifier': clinic.id,
    subject: `Patient/${patientUuid}`,
  }, { skip: patientUuid === null });

  const formik = useFormik({
    initialValues: {
      patient: null,
    },
    validationSchema: Yup.object({
      patient: Yup.object().required(t('Required')),
    }),
    onSubmit: (values) => {
      const existingCareTeam = subjectCareTeams.data?.entry[0] || null;
      if (existingCareTeam) {
        const subjectParticipants = existingCareTeam.resource.participant || [];
        updateCareTeam({
          id: existingCareTeam.resource.id,
          body: {
            participant: [
              ...parseParticipants(subjectParticipants),
              {
                member: {
                  reference: `CareTeam/${id}`,
                },
              },
            ],
          },
        }).then(() => { handleClose(); });
      } else {
        createCareTeam({
          body: {
            clinic: clinic.id,
            name: values.patient.label,
            managing_organization: [{
              reference: `Organization/${clinic.id}`,
            }],
            status: 'active',
            participant: [{
              member: {
                reference: `CareTeam/${id}`,
              },
            }],
            subject: {
              reference: `Patient/${values.patient.id}`,
            },
          },
        }).then(() => { handleClose(); });
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Add patient')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers>
          <Autocomplete
            fullWidth
            noOptionsText={(
              <Typography color="textSecondary">
                {t('No results')}
              </Typography>
            )}
            open={autocompleteOpen}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(e, value) => {
              formik.setFieldValue('patient', { id: value?.id, label: value?.label });
              setPatientUuid(value?.id);
            }}
            onOpen={() => {
              setAutocompleteOpen(true);
            }}
            onClose={() => {
              setAutocompleteOpen(false);
            }}
            options={[
              ...data.map((item) => ({ id: item.uuid, label: `${item.firstName} ${item.lastName}` }))
                .filter((item) => !subjects.includes(item.id)),
            ]}
            renderOption={(p, option) => (
              <MenuItem {...p} key={option.id}>
                {option.label}
              </MenuItem>
            )}
            loading={isFetching || subjectCareTeams.isFetching}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Search')}
                onChange={(e) => setSearchInput(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isFetching ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SubjectTable;
