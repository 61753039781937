import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Error as ErrorIcon,
  MoreVert as MoreVertIcon,
  PictureAsPdf as PictureAsPdfIcon
} from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdOutlinePendingActions } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateWithLocale } from '../../../../helper-functions/datetime';
import { greyboxApiActions } from '../../../../redux/api';
import { RiDraftLine } from 'react-icons/ri';
import { usePatientWebSocket } from '../../../../hooks';

const PrescriptionTable = ({ patientId }) => {
  const { requestOrchestration } = greyboxApiActions;
  const [deleteRequestOrchestration] = requestOrchestration.delete();

  const {
    data,
    isFetching,
    isError,
    error,
    refetch,
  } = requestOrchestration.list({ subject: `Patient/${patientId}` });

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { uuid } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { lastJsonMessage, readyState } = usePatientWebSocket();

  useEffect(() => {
    if (lastJsonMessage?.entity === 'PatientReport' || lastJsonMessage?.entity === 'RequestOrchestration') {
      refetch();
    }
  }, [lastJsonMessage]);
  

  const prescriptions = data?.entry?.map((it) => it.resource) || [];

  const selectedPrescriptions = useMemo(() => {
    return prescriptions.filter((prescription) =>
      selected.includes(prescription.id)
    );
  }, [prescriptions, selected]);

  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAllClick = (event) => {
    if (selected.length === 0 && event.target.checked) {
      const newSelecteds = prescriptions.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleAddPrescription = () => {
    navigate(`/patient-profile/${uuid}/prescriptions/new`);
  };

  const handleEditPrescription = () => {
    const prescriptionId = selectedPrescriptions[0].id;
    navigate(`/patient-profile/${uuid}/prescriptions/${prescriptionId}`);
  };

  const handleDeletePrescription = async () => {
    setConfirmOpen(false); // Close the confirmation dialog
    try {
      await Promise.all(selected.map((id) => deleteRequestOrchestration(id)));
      setSelected([]);
    } catch (error) {
      console.error("Error deleting prescriptions:", error);
    }
  };

  const statusConfig = {
    pending: {
      label: t('Pending'),
      icon: <MdOutlinePendingActions />,
      color: 'secondary'
    },
    'in-progress': {
      label: t('In Progress'),
      icon: <CircularProgress size={20} />,
      color: 'info'
    },
    completed: {
      label: t('Completed'),
      icon: <IoMdCheckmarkCircleOutline />,
      color: 'success'
    },
    draft: {
      label: t('Draft'),
      icon: <RiDraftLine />,
      color: 'primary'
    },
    error: {
      label: t('Error'),
      icon: <ErrorIcon color="error" />,
      color: 'error'
    },
  };

  const actions = [
    {
      label: t('Add'),
      onClick: handleAddPrescription,
      icon: <AddIcon />,
      disabled: false,
      show: true,
      variant: 'contained',
    },
    {
      label: t('Edit'),
      onClick: handleEditPrescription,
      icon: <EditIcon />,
      disabled:
        selectedPrescriptions.length !== 1 ||
        selectedPrescriptions[0].status !== 'draft',
      show: true,
      variant: 'outlined',
    },
    {
      label: t('Delete'),
      onClick: () => setConfirmOpen(true), // Open confirmation dialog
      icon: <DeleteIcon />,
      disabled:
        selectedPrescriptions.length === 0 ||
        selectedPrescriptions.some(
          (prescription) => prescription.status !== 'draft'
        ),
      show: true,
      variant: 'outlined',
      color: 'error',
      sx: { borderColor: 'error.main', color: 'error.main' },
    },
  ];

  return (
    <Paper>
      <Toolbar disableGutters variant="dense" data-cy="toolbar">
        {isMobile ? (
          <>
            <IconButton
              aria-label="more"
              aria-controls="action-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="action-menu"
              anchorEl={anchorEl}
              keepMounted
              open={openMenu}
              onClose={handleMenuClose}
            >
              {actions
                .filter((action) => action.show)
                .map((action, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      action.onClick();
                      handleMenuClose();
                    }}
                    disabled={action.disabled}
                  >
                    <ListItemIcon>{action.icon}</ListItemIcon>
                    <ListItemText primary={action.label} />
                  </MenuItem>
                ))}
            </Menu>
          </>
        ) : (
          <Stack spacing={1} direction="row" sx={{ ml: 1 }}>
            {actions
              .filter((action) => action.show)
              .map((action, index) => (
                <Button
                  key={index}
                  variant={action.variant}
                  color={action.color || 'primary'}
                  disableElevation
                  size="small"
                  disabled={action.disabled}
                  onClick={action.onClick}
                  startIcon={action.icon}
                  sx={action.sx}
                >
                  {action.label}
                </Button>
              ))}
          </Stack>
        )}
      </Toolbar>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{t('Delete Confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {t('Are you sure you want to delete the selected prescriptions? This action cannot be undone.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleDeletePrescription} color="error" autoFocus>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>


      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{ borderTop: '1px solid', borderColor: 'divider' }}
              >
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < prescriptions.length
                  }
                  checked={
                    prescriptions.length > 0 &&
                    selected.length === prescriptions.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{ 'aria-label': 'select all prescriptions' }}
                />
              </TableCell>
              <TableCell
                sx={{ border: '1px solid', borderColor: 'divider' }}
              >
                {t('Date')}
              </TableCell>
              <TableCell
                sx={{ border: '1px solid', borderColor: 'divider' }}
              >
                {t('Status')}
              </TableCell>
              <TableCell
                sx={{ border: '1px solid', borderColor: 'divider' }}
              >
                {t('Prescribed By')}
              </TableCell>
              <TableCell
                sx={{ border: '1px solid', borderColor: 'divider' }}
              >
                {t('PDF Status')}
              </TableCell>
              <TableCell
                sx={{ border: '1px solid', borderColor: 'divider' }}
              >
                {t('Actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <>
                {[...Array(5)].map((_, index) => (
                  <TableRow key={index}>
                    <TableCell padding="checkbox">
                      <Skeleton variant="rectangular" width={24} height={24} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      <Skeleton variant="rectangular" width={80} height={30} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      <Skeleton variant="rectangular" width={80} height={30} />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      <Skeleton variant="rectangular" width={40} height={40} />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : prescriptions.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={6}
                  sx={{
                    textAlign: 'center',
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  {t('No prescriptions available')}
                </TableCell>
              </TableRow>
            ) : (
              prescriptions.map((prescription) => {
                const isItemSelected = selected.indexOf(prescription.id) !== -1;

                const reportStatus = statusConfig[prescription.prescription_report?.status];
                const prescriptionStatus = statusConfig[prescription.status] || {};

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, prescription.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={prescription.id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': `checkbox-${prescription.id}`,
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      {prescription._created ? formatDateWithLocale(new Date(prescription._created), 'Pp') : 'N/A'}
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      <Chip
                        icon={prescriptionStatus.icon}
                        label={prescriptionStatus.label}
                        color={prescriptionStatus.color}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      {prescription.author?.display}
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      {reportStatus && (
                        <Chip
                          icon={reportStatus.icon}
                          label={reportStatus.label}
                          color={reportStatus.color}
                          variant="outlined"
                        />
                      )}
                    </TableCell>
                    <TableCell sx={{ border: '1px solid', borderColor: 'divider' }}>
                      {prescription.prescription_report?.status === 'completed' &&
                        prescription.prescription_report?.file && (
                          <Tooltip title={t('View PDF')}>
                            <IconButton
                              aria-label="view pdf"
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent row selection
                                window.open(prescription.prescription_report.file);
                              }}
                            >
                              <PictureAsPdfIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PrescriptionTable;
