import React from 'react';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormTextField, DatePicker, Duration, QuestionnaireSelect, Recurrence } from '../../form-inputs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import Row from '../../Row';

const EventForm = ({ formik, showRecurrence, setShowRecurrence }) => {
    const { t } = useTranslation();

    const handleChangeDate = (date: Date | null) => {
        if (date) {
            const newDate = moment(date)
                .hour(formik.values.start_datetime.hour()).minute(formik.values.start_datetime.minute());
            formik.setFieldValue('start_datetime', newDate);

            if (!formik.values.all_day) {
                formik.setFieldValue('end_datetime', moment(newDate).add(30, 'minutes'));
            } else {
                formik.setFieldValue('end_datetime', moment(newDate).endOf('day'));
            }
        }
    };

    const handleChangeTime = (time: Date | null) => {
        if (time) {
            const newDate = moment(formik.values.start_datetime)
                .hour(moment(time).hour()).minute(moment(time).minute());
            formik.setFieldValue('start_datetime', newDate);

            if (!formik.values.all_day) {
                formik.setFieldValue('end_datetime', moment(newDate).add(30, 'minutes'));
            } else {
                formik.setFieldValue('end_datetime', moment(newDate).endOf('day'));
            }
        }
    };

    return (
        <Box flexGrow={1} p={2}>
            <Row sx={{ mb: 2 }}>
                <FormTextField
                    name="name"
                    formik={formik}
                    label={t('Name')}
                    required
                    sx={{ flex: 1, mr: 2 }}
                />
                <FormTextField
                    name="description"
                    formik={formik}
                    label={t('Description')}
                    sx={{ flex: 1, ml: 2 }}
                />
            </Row>
            <Duration formik={formik} name="reminder" label={t('Reminder')} sx={{ mb: 2 }} />
            <Box sx={{ mb: 2 }}>
                {formik.values.resourcetype === 'OnlineMeetingEvent' ? (
                    <FormTextField
                        name="join_url"
                        required
                        type="url"
                        formik={formik}
                        label={t('Online meeting link')}
                        sx={{ width: '100%' }}
                    />
                ) : (
                    <FormTextField
                        name="location"
                        formik={formik}
                        label={t('Location')}
                        sx={{ width: '100%' }}
                    />
                )}
            </Box>
            {formik.values.resourcetype === 'QuestionnaireEvent' && (
                <>
                        <QuestionnaireSelect
                            name="questionnaire"
                            formik={formik}
                            required
                            sx={{mb: 2, width: '100%' }}  // This ensures it takes full width of its container
                            questionnaireId={formik.values.questionnaire}
                        />
                    <Duration formik={formik} name="response_reminder" label={t('Response reminder')} sx={{ mb: 2 }} />
                </>
            )}
            {formik.values.all_day ? (
                <Row sx={{ mb: 2 }}>
                    <DatePicker
                        formik={formik}
                        label={t('Start')}
                        name="start_datetime"
                        sx={{ flex: 1, mr: 2 }}
                    />
                    <DatePicker
                        formik={formik}
                        name="end_datetime"
                        label={t('End')}
                        sx={{ flex: 1, ml: 2 }}
                    />
                </Row>
            ) : (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Row sx={{ mb: 2 }}>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <MuiDatePicker
                                label={t('Date')}
                                sx={{ width: '100%' }}
                                value={formik.values.start_datetime}
                                onChange={(d) => handleChangeDate(d)}
                            />
                        </Box>
                        <Box sx={{ flex: 1, mr: 2 }}>
                            <TimePicker
                                label={t('Start Time')}
                                sx={{ width: '100%' }}
                                value={formik.values.start_datetime}
                                onChange={(time) => handleChangeTime(time)}
                                ampm={false}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <TimePicker
                                label={t('End Time')}
                                sx={{ width: '100%' }}
                                value={formik.values.end_datetime}
                                minTime={formik.values.start_datetime}
                                onChange={(time) => formik.setFieldValue('end_datetime', time)}
                                ampm={false}
                            />
                        </Box>
                    </Row>
                </LocalizationProvider>
            )}
            <Row sx={{ mb: 2 }}>
                <FormControlLabel
                    sx={{ ml: 1 }}
                    control={(
                        <Checkbox
                            checked={formik.values.all_day}
                            onChange={() => formik.setFieldValue('all_day', !formik.values.all_day)}
                        />
                    )}
                    label={t('All Day')}
                />
            </Row>
            <Button
                sx={{ mb: 2 }}
                onClick={() => {
                    if (showRecurrence) {
                        formik.setFieldValue('recurrences', '');
                    }
                    setShowRecurrence(!showRecurrence);
                }}
                variant="outlined"
            >
                {showRecurrence ? t('Remove recurrence') : t('Add recurrence')}
            </Button>

            {(showRecurrence || formik.values.recurrences !== '') && (
                <Box border={(theme) => `1px solid ${theme.palette.grey[300]}`} borderRadius={2} p={1} sx={{ mb: 2 }}>
                    <Recurrence
                        onChange={(recurrence) => formik.setFieldValue('recurrences', recurrence)}
                        rule={formik.values.recurrences}
                    />
                </Box>
            )}
        </Box>
    );
};

export default EventForm;
