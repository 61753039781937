import React from 'react';
import { Paper, Box, Typography, Skeleton, Alert, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ObservationChart from '../../chart/ObservationChart';
import { greyboxApiActions } from '../../../redux/api';
import DateRangePicker from '../dateRangePicker/DateRangePicker';
import LabResultDetailsTable from './LabResultDetailsTable';

const LabResultDetails = () => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { labResultsConfig } = greyboxApiActions;
  const { labResultCode } = useParams();
  const { data = [], isFetching, isError, refetch } = labResultsConfig.list({ clinic: clinic.id });

  const config = data.find((item) => item.short_code === labResultCode);

  if (isError) {
    return (
      <Box width="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Alert severity="error" sx={{ mb: 2 }}>
          {t('An error occurred while fetching lab result details.')}
        </Alert>
        <Button variant="contained" onClick={refetch}>
          {t('Retry')}
        </Button>
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        {isFetching ? (
          <Skeleton variant="text" width={300} height={50} />
        ) : (
          <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
            {config?.name || t('No data available')}
          </Typography>
        )}
        <DateRangePicker />
      </Box>
      {isFetching ? (
        <Skeleton variant="rectangular" height={400} sx={{ mb: 2 }} />
      ) : (
        <ObservationChart
          measures={[{
            ...config,
            id: config.uuid,
            is_specific: false,
          }]}
          config={{
            label: config?.name,
            unit: config?.unit,
            chartType: 'line',
            values: ['observation'],
            colors: ['spo2'],
          }}
          detailed
        />
      )}
      <Paper sx={{ width: '100%', mt: 1 }}>
        {isFetching ? (
          <Skeleton variant="rectangular" height={200} />
        ) : (
          <LabResultDetailsTable config={config} />
        )}
      </Paper>
    </Box>
  );
};

export default LabResultDetails;
