import { Box, Typography, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { greyboxApiActions } from '../../../../redux/api';
import MedicationTable from './MedicationTable';
import PrescriptionTable from '../prescription/PrescriptionTable';
import { enrichMedicationData } from './utils';
import { usePatientWebSocket } from '../../../../hooks';

const CurrentMedicationsWrapper = (props) => {
  const { titration_status_choices } = props;
  const { uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { clinic } = useSelector((state) => state.clinic);
  const [selected, setSelected] = useState([]);
  const { medication, treatmentPlan } = greyboxApiActions;
  const { t } = useTranslation();
  const theme = useTheme();

  // Define valid tab values and extract the current tab from the URL hash
  const tabValues = ['current', 'history', 'prescriptions'];
  const hash = location.hash.replace('#', '');
  const currentTab = tabValues.includes(hash) ? hash : 'current';

  // Handle tab change by updating the URL hash
  const handleTabChange = (event, newValue) => {
    setSelected([]);
    navigate(
      {
        pathname: location.pathname,
        search: location.search,
        hash: `#${newValue}`,
      },
      { replace: true }
    );
  };

  // Fetch treatment plans and medications based on the current tab
  const treatmentPlanSelector = treatmentPlan.list({
    patient: uuid,
    latest: true,
    clinic: clinic.id,
  });

  const status = currentTab === 'history' ? 'completed' : 'active,intended';

  const medicationsData = medication.list({
    account: uuid,
    status,
  });


  const { lastJsonMessage, readyState } = usePatientWebSocket();

  useEffect(() => {
    if (lastJsonMessage?.entity === 'Medication_History') {
      medicationsData.refetch();
    }
    else if (lastJsonMessage?.entity === 'Decision_Tree_History') {
      treatmentPlanSelector.refetch();
    }
  }, [lastJsonMessage]);

  // Process data using useMemo for performance optimization
  const suggestedMedications = useMemo(() => {
    if (!treatmentPlanSelector.isFetching) {
      const decisions = treatmentPlanSelector.data
        .map((item) => {
          const output = item.decision_tree_output;
          return output.output_code === 'suggest-medications' ? output.data : null;
        })
        .flat();
      const parsedMedications = [];
      decisions.forEach((item) => {
        if (Array.isArray(item)) {
          item.forEach((el) => {
            parsedMedications.push({
              class: el.medication_class.en,
              contraindications: el.contraindication,
              list: el.medications.map((med) => med.active_ingredient_codes).flat(),
            });
          });
        } else if (item) {
          parsedMedications.push({
            class: item.medication_class.en,
            contraindications: item.contraindication,
            list: item.medications.map((med) => med.active_ingredient_codes).flat(),
          });
        }
      });

      return parsedMedications;
    }
    return [];
  }, [treatmentPlanSelector.data]);

  const medications = useMemo(() => {
    if (!medicationsData.isFetching && medicationsData.data) {
      return medicationsData.data.map((item) =>
        enrichMedicationData(item, titration_status_choices, suggestedMedications)
      );
    }
    return [];
  }, [medicationsData.data, titration_status_choices, suggestedMedications]);

  return (
    <Box
      sx={{
        mb: 2,
        filter: currentTab === 'history' ? 'saturate(0.7)' : 'saturate(1)',
        transition: 'all 400ms ease',
      }}
      data-cy="medWrapper"
    >
      <Typography variant="h5">{t('Medication Table')}</Typography>
      <Typography variant="subtitle2">
        {t(
          "Only drugs added with Takecare are displayed. The patient's pharmacological profile may be incomplete."
        )}
      </Typography>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="medication tabs"
        textColor="inherit"
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <Tab
          label={t('Current')}
          value="current"
        />
        <Tab
          label={t('History')}
          value="history"
        />
        <Tab
          label={t('Prescriptions')}
          value="prescriptions"
        />
      </Tabs>
      {currentTab === 'prescriptions' ? (
        <PrescriptionTable patientId={uuid} />
      ) : (
        <MedicationTable
          meds={medications}
          isHistory={currentTab === 'history'}
          selected={selected}
          setSelected={setSelected}
          titration_status_choices={titration_status_choices}
          isFetching={medicationsData.isFetching}
        />
      )}
    </Box>
  );
};

export default CurrentMedicationsWrapper;
