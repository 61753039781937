import {
  Box, Button, Paper, Skeleton, Typography,
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import RegisterMedicalActDialog from './RegisterMedicalActDialog';

const MedicalActSummary = ({patientUuid}) => {
  const { t } = useTranslation();
  const [hcp, setHcp] = React.useState(null);
  const { medicalAct, account } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { data = {}, isFetching } = medicalAct.list({
    patient: patientUuid, page_size: 1, clinic: clinic.id,
  });
  const accountSelector = account.get(hcp, { skip: !hcp });
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (data.count > 0) {
      if (data.results[0].hcp) {
        setHcp(data.results[0].hcp);
      }
    }
  }, [data]);

  if (isFetching || accountSelector.isFetching) {
    <Paper>
      <Box display="flex" flexDirection="column" alignItems="center" padding={1}>
        <Skeleton variant="text" />
      </Box>
    </Paper>;
  }

  return (
    <>
      <Paper>
        <Box display="flex" flexDirection="column" alignItems="center" padding={1}>
          <Typography>
            {t('Latest follow-up')}
          </Typography>
          {data.count > 0 ? (
            <>
              <Typography>
                <strong>
                  {data.results[0].medical_act_type_name}
                </strong>
              </Typography>
              <Typography variant="caption" align="center">
                {t('By')}
                {' '}
                {accountSelector.data?.firstName}
                {' '}
                {accountSelector.data?.lastName}
              </Typography>
              <Typography variant="caption" align="center">
                {moment(data.results[0].createdDate).format('lll')}
              </Typography>
            </>
          ) : (
            <Typography>
              {t('No medical act recorded')}
            </Typography>
          )}
          <Button variant="outlined" fullWidth onClick={() => setOpen(true)}>
            {t('Record a follow-up')}
          </Button>
        </Box>
      </Paper>
      {open && (
        <RegisterMedicalActDialog patientUuid={patientUuid} open={open} handleClose={() => setOpen(false)} />
      )}
    </>
  );
};

export default MedicalActSummary;
