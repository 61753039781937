import React from 'react';
import { useSelector } from 'react-redux';
import {Navigate } from 'react-router-dom';
import { greyboxApiActions } from '../redux/api';

/**
 * Only call when user just logged in. Based on access of user we redirect to the right page.
 */
const Home = () => {
  const user = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const { patientDashboard } = greyboxApiActions;
  const { data, isLoading } = patientDashboard.list({
    clinic: clinic?.id, page_size: 0,
  });

  if (user.isLoading || isLoading) {
    return null;
  }

  if (user.access === 'CP' && data.results.length > 0) {
    return <Navigate to={`/patient-profile/${data.results[0].id}/overview`} />;
  }

  if (user.access === 'P') {
    return <Navigate to={`/patient-profile/${user.accountId}/overview`} />;
  }

  if (user.access === 'S') {
    return <Navigate to="/clinic-settings" />;
  }

  return (
    <Navigate to="/dashboard" />
  );
};

export default Home;
