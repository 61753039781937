import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import CommentIcon from '@mui/icons-material/Comment';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SendIcon from '@mui/icons-material/Send';
import {
  Box, Button, CircularProgress, IconButton,
  Popover,
  ToggleButton, Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useThreadWebSocket } from '../../hooks';
import { greyboxApiActions } from '../../redux/api';
import { getMediaTypeFromFileType } from '../../utils';
import MessageBox from './MessageBox';
import MessageTextField from './MessageTextField';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

const PAGE_SIZE = 20;

/**
 * Used to display messages from clinic to patient. It can toggle if the patient can
 * answer or not. The previous message (if any) will load when the user scrolls to the top.
 */
const ChatBox = ({
  threadId,
}) => {
  const { messageThread, user, media } = greyboxApiActions;
  const { access } = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [sendMessage] = messageThread.add();
  const [sendMedia] = media.add();
  const [currentMessage, setCurrentMessage] = useState('');
  const { clinic } = useSelector((state) => state.clinic);
  const [updateThread] = messageThread.update();
  const threadRequest = messageThread.get(threadId);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [attachedFile, setAttachedFile] = useState(null);
  const [anchorEmote, setAnchorEmote] = useState(null);
  const [sendLaterAnchorEl, setSendLaterAnchorEl] = useState(null);
  const [sendLaterDate, setSendLaterDate] = useState(moment().add(1, 'hours'));
  const [loading, setLoading] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { lastJsonMessage, readyState } = useThreadWebSocket(threadId);

  const thread = threadRequest.data;

  const scrollableDivRef = useRef(null);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'Message') {
        if (page === 1) {
          refetch();
        } else {
          setPage(1);
        }
      }
    }
  }, [lastJsonMessage]);

  const { data, isFetching, refetch } = messageThread.list(
    {
      id: `${threadId}/message`,
      page_size: PAGE_SIZE,
      page: page,
    },
  );

  useEffect(() => {
    if (threadId) {
      setMessages([]);
      setPage(1);
    }
  }, [threadId]);

  const handleSendMessage = async () => {
    const body = { message: currentMessage };

    if (attachedFile) {
      const newMedia = new FormData();
      const mediaType = getMediaTypeFromFileType(attachedFile.type);
      newMedia.append('file', attachedFile);
      newMedia.append('clinic', clinic.id);
      newMedia.append('type', mediaType);
      newMedia.append('patient', thread.patient);
      const mediaData = await sendMedia({ body: newMedia });
      body.media = mediaData.data.id;
    }

    if (sendLaterAnchorEl) {
      body.scheduled_time = sendLaterDate.toISOString();
      setSendLaterAnchorEl(null);
    }

    sendMessage({ body, id: `${threadId}/message` });
    setCurrentMessage('');
    setAttachedFile(null);
  };

  const handleSwitch = async () => {
    setLoading(true); // Start loading state
    const accept = !thread.accept_patient_message;

    try {
      await updateThread({
        id: threadId,
        body: { accept_patient_message: accept },
      });
    } catch (error) {
      console.error(error); // Handle any errors
    } finally {
      setLoading(false); // End loading state
    }
  };

  useEffect(() => {
    if (data && data.results) {
      setMessages(prevMessages => {
        const allMessages = page === 1 ? data.results : [...prevMessages, ...data.results];

        // Create a Map to ensure uniqueness by message.id and preserve order
        const uniqueMessages = Array.from(new Map(allMessages.map(msg => [msg.id, msg])).values());

        return uniqueMessages;
      });

    }
  }, [data]);


  const handleScroll = () => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      const { scrollTop } = scrollableDiv;
      const threshold = 100; // Adjust as needed
      if (scrollTop <= threshold) {
        if (data?.next && !isFetching) {
          setPage(prevPage => prevPage + 1);
        } 
      } 
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {isMobile && (
        <Box>
          <IconButton
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
        </Box>
      )}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        <AutoSizer>
          {({ height, width }) => (
            <Box
              id="scrollableDiv"
              ref={scrollableDivRef}
              onScroll={handleScroll}
              sx={{
                height: height,
                width: width,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column-reverse',
                position: 'relative',
                maskImage: `linear-gradient(
                  to bottom,
                  rgba(0,0,0,0) 0px, 
                  rgba(0,0,0,1) 20px, 
                  rgba(0,0,0,1) 100%
                )`,
                WebkitMaskImage: `linear-gradient(
                  to bottom,
                  rgba(0,0,0,0) 0px, 
                  rgba(0,0,0,1) 20px, 
                  rgba(0,0,0,1) 100%
                )`,
              }}
            >
              {/* Placeholder for no messages */}
              {!isFetching && messages.length === 0 && (
                <Box sx={{ textAlign: 'center', my: 2 }}>
                  <Typography variant="body1" color="textSecondary">
                    {t('No messages yet. Start the conversation!')}
                  </Typography>
                </Box>
              )}


              {messages.map((msg, index) => (
                <MessageBox
                  key={msg.id}
                  message={msg}
                  previousMessage={messages[index + 1]}
                  threadId={threadId}
                />
              ))}

              {isFetching && (
                <div style={{ alignSelf: 'center' }}><CircularProgress size={30} /></div>
              )}


              {/* Message indicating the top of the conversation */}
              {messages.length > 0 && !data.next && (
                <Box sx={{ textAlign: 'center', my: 3 }}>
                  <Typography variant="body2" color="textSecondary">
                    {t('You have reached the beginning of the conversation')}
                  </Typography>
                </Box>
              )}

            </Box>
          )}
        </AutoSizer>
      </Box>

      {!(access === 'P' && !thread?.accept_patient_message) && (
        <Box sx={{ p: 1 }}>
          {isMobile && (
            <Box display="flex">
              <MessageTextField
                isFetching={isFetching}
                attachedFile={attachedFile}
                setAttachedFile={setAttachedFile}
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
              />
              <IconButton
                size="small"
                color="primary"
                sx={{ ml: 1 }}
                disabled={!currentMessage && !attachedFile}
                onClick={handleSendMessage}
              >
                {isFetching ? <CircularProgress size={24} /> : <SendIcon />}
              </IconButton>
            </Box>
          )}
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 1,
          }}
          >
            {access === 'PT' && (
              <Tooltip title={!thread?.accept_patient_message ? t('Allow patient to answer') : t('Do not allow patient to answer')}>
                <ToggleButton
                  selected={thread?.accept_patient_message}
                  value={thread?.accept_patient_message}
                  color="success"
                  onChange={handleSwitch}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    thread?.accept_patient_message ? <CommentIcon /> : <CommentsDisabledIcon />
                  )}
                </ToggleButton>
              </Tooltip>
            )}
            <input
              style={{ display: 'none' }}
              onChange={(e) => setAttachedFile(e.target.files[0])}
              type="file"
              id="raised-button-file"
            />
            <label htmlFor="raised-button-file">
              <IconButton
                size="small"
                component="span"
                color="primary"
                sx={{ ml: 1 }}
              >
                <AttachFileIcon />
              </IconButton>
            </label>
            {!isMobile && (
              <MessageTextField
                isFetching={isFetching}
                attachedFile={attachedFile}
                setAttachedFile={setAttachedFile}
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
              />
            )}
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              onClick={(e) => setAnchorEmote(e.currentTarget)}
            >
              <InsertEmoticonIcon />
            </IconButton>
            <Popover
              open={Boolean(anchorEmote)}
              onClose={() => setAnchorEmote(null)}
              anchorEl={anchorEmote}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <EmojiPicker
                onEmojiClick={(emojiObject) => {
                  setCurrentMessage(currentMessage + emojiObject.emoji);
                }}
                lazyLoadEmojis
                searchDisabled
                autoFocusSearch={false}
                suggestedEmojisMode="recent"
                skinTonesDisabled
              />
            </Popover>
            {!isMobile && (
              <IconButton
                size="small"
                color="primary"
                disabled={!currentMessage && !attachedFile}
                onClick={handleSendMessage}
                onContextMenu={(e) => {
                  setSendLaterAnchorEl(e.currentTarget); e.preventDefault();
                }}
              >
                {isFetching ? <CircularProgress size={24} /> : <SendIcon />}
              </IconButton>
            )}
            <Popover
              open={Boolean(sendLaterAnchorEl)}
              onClose={() => setSendLaterAnchorEl(null)}
              anchorEl={sendLaterAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Box sx={{ p: 2 }}>
                <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.resolvedLanguage}>
                  <DateTimePicker
                    value={moment(sendLaterDate)}
                    label={t('Send message at')}
                    onChange={(newValue) => setSendLaterDate(newValue)}
                    disablePast
                  />
                </LocalizationProvider>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                  <Button color="secondary" size="small" onClick={() => setSendLaterAnchorEl(null)}>
                    {t('Close')}
                  </Button>
                  <Button size="small" onClick={handleSendMessage}>
                    {t('Send')}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatBox;
