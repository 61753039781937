import {
  Box,
  Button,
  Card, CardContent,
  Container,
  Divider,
  Grid,
  Skeleton, TextField, Typography,
  useMediaQuery, useTheme
} from '@mui/material';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { getThresholdCodes, parseThresholdForPost, thresholdInitValue } from './Threshold';

export const thresholdValidations = (enabledVitals) => {
  const validationSchema = yup.object();

  enabledVitals.forEach((vital) => {
    if (vital === 'bloodPressure') {
      validationSchema.concat(yup.object().shape({
        min_tensionLow: yup.number().min(0),
        min_tensionHigh: yup.number().min(0),
        max_tensionLow: yup.number().min(0),
        max_tensionHigh: yup.number().min(0),
      }));
    } else {
      validationSchema.concat(yup.object().shape({
        [`min_${vital}`]: yup.number().min(0),
        [`max_${vital}`]: yup.number().min(0),
        [`min_mod_${vital}`]: yup.number().min(0),
        [`max_mod_${vital}`]: yup.number().min(0),
        [`min_low_${vital}`]: yup.number().min(0),
        [`max_low_${vital}`]: yup.number().min(0),
      }));
    }
  });

  return validationSchema;
};

export const parseThresholdsForPost = (values, vitalsConfig) => {
  const thresholds = [];

  Object.keys(vitalsConfig).forEach((key) => {
    const thresholdCodes = getThresholdCodes(key, vitalsConfig);
    const parsed = parseThresholdForPost(values, key, thresholdCodes);
    thresholds.push(...parsed);
  });

  return thresholds;
};


export const thresholdsInitValue = (vitalsConfig, values) => {
  const thresholds = {};
  Object.keys(vitalsConfig).forEach((key) => {
    const thresholdCodes = getThresholdCodes(key, vitalsConfig);
    thresholdCodes.forEach((t) => {
      let value = {};
      if (values) {
        if (values[t.code]) {
          value = { [t.shortCode]: values[t.code].defaultValue };
        } else {
          value = values;
        }
      } else {
        return;
      }
      const newThreshold = thresholdInitValue(key, value, [t.shortCode]);
      Object.assign(thresholds, newThreshold);
    });
  });

  return thresholds;
};

export const Thresholds = memo(({ formik, isLoading }) => {
  const { t } = useTranslation();
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleAdvancedMode = useCallback((vital) => {
    formik.setFieldValue(`advancedMode_${vital}`, !formik.values[`advancedMode_${vital}`]);
  }, [formik]);

  return (
    <Container>
      <Grid container spacing={2}>
        {Object.entries(vitalsConfig).map(([vital, config]) => (
          <Grid item md={12} lg={6} xl={4} key={vital}>
            <Card sx={{ minWidth: isSmallScreen ? 250 : 300 }}>
              <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                  <Typography variant="h6">{config.name}</Typography>
                  <Button onClick={() => toggleAdvancedMode(vital)} size="small" variant="text">
                    {formik.values[`advancedMode_${vital}`] ? t('Basic') : t('Advanced')}
                  </Button>
                </Box>
                <Divider />
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={6}>
                    <ThresholdTextField name={`min_${vital}`} label={formik.values[`advancedMode_${vital}`] ? t('High Min') : t('Min')} formik={formik} isLoading={isLoading} />
                  </Grid>
                  <Grid item xs={6}>
                    <ThresholdTextField name={`max_${vital}`} label={formik.values[`advancedMode_${vital}`] ? t('High Max') : t('Max')} formik={formik} isLoading={isLoading} />
                  </Grid>
                  {formik.values[`advancedMode_${vital}`] && (
                    <>
                      <Grid item xs={6}>
                        <ThresholdTextField name={`min_mod_${vital}`} label={t('Moderate Min')} formik={formik} isLoading={isLoading} />
                      </Grid>
                      <Grid item xs={6}>
                        <ThresholdTextField name={`max_mod_${vital}`} label={t('Moderate Max')} formik={formik} isLoading={isLoading} />
                      </Grid>
                      <Grid item xs={6}>
                        <ThresholdTextField name={`min_low_${vital}`} label={t('Low Min')} formik={formik} isLoading={isLoading} />
                      </Grid>
                      <Grid item xs={6}>
                        <ThresholdTextField name={`max_low_${vital}`} label={t('Low Max')} formik={formik} isLoading={isLoading} />
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
});

export const ThresholdTextField = memo(({ formik, name, label, isLoading }) => {
  if (isLoading) {
    return (
      <Skeleton variant="rectangular" sx={{ width: '100%', height: 56, margin: '8px 0' }} />
    );
  }
  return (
    <TextField
      id={name}
      name={name}
      label={label}
      value={formik.values[name] || ''}
      onBlur={formik.handleBlur}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      variant="outlined"
      type="number"
      fullWidth
      onChange={formik.handleChange}
      disabled={formik.isSubmitting} 
      sx={{ margin: '8px 0' }}
    />
  );
});

export default Thresholds;
