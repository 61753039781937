import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import UnsavedWarning from '../../../modals/UnsavedWarning';
import { Dialog } from '@mui/material';
import DialogTitle from '../../../DialogTitle';
import QuestionnaireContainer from './QuestionnaireContainer';
import ErrorFallbackCard from '../../../utils/ErrorFallbackCard';
import { ErrorBoundary } from 'react-error-boundary';

type DialogQuestionnaireProps = {
  patientUuid: string;
  open: boolean;
  handleClose: () => void;
  questionnaireId: number;
  onComplete: (answersId: string) => void;
  previousAnswers?: any;
};

const DialogQuestionnaire = (props: DialogQuestionnaireProps) => {
  const {
    patientUuid, open, handleClose, questionnaireId, onComplete, previousAnswers,
  } = props;
  const { clinic } = useSelector((state: any) => state.clinic);

  const [unsavedWarningOpen, setUnsavedWarningOpen] = useState(false);
  const [hasUnsaved, setHasUnsaved] = useState(false);

  const handleDialogClose = () => {
    if (hasUnsaved) {
      setUnsavedWarningOpen(true);
    } else {
      setHasUnsaved(false);
      handleClose();
    }
  };

  const handleWarningClose = (closeFiller: boolean) => {
    if (closeFiller) {
      setHasUnsaved(false);
      handleClose();
    }
    setUnsavedWarningOpen(false);
  };

  return (
    <>
      <Dialog open={open} fullScreen>
        <DialogTitle onClose={() => handleDialogClose()} />
        <ErrorBoundary
          FallbackComponent={ErrorFallbackCard}
          onReset={handleDialogClose}
        >
          <QuestionnaireContainer
            patientUuid={patientUuid}
            questionnaireId={questionnaireId}
            onComplete={onComplete}
            clinic={clinic.id}
            previousAnswers={previousAnswers}
            setHasUnsaved={setHasUnsaved}
          />
        </ErrorBoundary>
      </Dialog>
      <UnsavedWarning
        open={unsavedWarningOpen}
        handleClose={handleWarningClose}
      />
    </>
  );
};

export default DialogQuestionnaire;
