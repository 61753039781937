import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const FormikSelect = ({
  name,
  label,
  required = false,
  sx = {},
  children,
  disabled = false,
  multiple = false,
  fullWidth = false,
  ...other
}) => {
  const [field, meta] = useField(name);
  const error = Boolean(meta.touched && meta.error);
  const helperText = meta.touched && meta.error;
  const ariaDescribedBy = error ? `${name}-helper-text` : undefined;

  // Ensure the value is not undefined
  const value = field.value ?? '';

  return (
    <FormControl
      error={error}
      disabled={disabled}
      fullWidth={fullWidth}
      sx={{
        ...sx,
      }}
    >
      <InputLabel id={`${name}-label`}>{required ? `${label} *` : label}</InputLabel>
      <Select
        id={name}
        labelId={`${name}-label`}
        name={name}
        label={required ? `${label} *` : label}
        multiple={multiple}
        data-cy={name}
        aria-describedby={ariaDescribedBy}
        fullWidth={fullWidth}
        value={value} // Use the safe value here
        onChange={field.onChange}
        onBlur={field.onBlur}
        {...other}
      >
        {children}
      </Select>
      {error && (
        <FormHelperText id={ariaDescribedBy}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikSelect;
