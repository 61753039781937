import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box, FormControl, FormLabel, IconButton, Typography, Menu, MenuItem,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../redux/api';
import { formatPhoneNumber } from '../../utils';
import PharmacyDialog from '../PharmacyDialog';

const Pharmacy = ({ formik }) => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const { pharmacy, account } = greyboxApiActions;
  const accountSelector = account.get(uuid);
  const [updateAccount] = account.update();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const hasPharmacy = Boolean(formik.values.pharmacy);
  const { data } = pharmacy.get(formik.values.pharmacy,
    { skip: !hasPharmacy });

  const handlePharmacyRemove = () => {
    updateAccount({ id: uuid, body: { extension: [] } }).then(() => {
      accountSelector.refetch();
    }).then(() => {
      formik.setFieldValue('pharmacy', '');
    });
  };

  return (
    <>
      <FormControl sx={{ mx: 1}}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <FormLabel>
            {t('Pharmacy')}
          </FormLabel>
          {!hasPharmacy && (
            <IconButton
              size="small"
              sx={{ ml: 1 }}
              data-cy="pharmacy-add"
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
          ) }
        </Box>
        {data && hasPharmacy && (
          <Box
            sx={{
              border: (theme) => `1px solid ${theme.palette.divider}`,
              display: 'flex',
              flexDirection: 'row',
              mt: 1,
              borderRadius: 3,
              p: 1,
            }}
            data-cy="pharmacy-info"
          >
            <Box sx={{ pl: 1, width: '80%' }} display="flex" flexDirection="column">
              <Typography>
                <strong>
                  {data.name || ''}
                </strong>
              </Typography>
              <Typography>
                {`${t('Address')}: ${data.address ? data.address : '---'}`}
              </Typography>
              <Typography>
                {`${t('Phone')}: ${formatPhoneNumber(data.phone) || '---'}`}
              </Typography>
              <Typography>
                {`${t('Fax')}: ${formatPhoneNumber(data.fax) || '---'}`}
              </Typography>
            </Box>
            <Box sx={{ ml: 'auto', alignSelf: 'flex-start' }}>
              <IconButton
                size="small"
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVertIcon sx={{ transform: 'scale(0.8)' }} />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => setDialogOpen(true)}>
                  {t('Edit')}
                </MenuItem>
                <MenuItem
                  sx={{ color: (theme) => theme.palette.error.main }}
                  onClick={() => handlePharmacyRemove()}
                >
                  {t('Remove')}
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        )}
      </FormControl>
      {dialogOpen && (
        <PharmacyDialog
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          accountFormik={formik}
          id={hasPharmacy && data?.id}
        />
      )}
    </>
  );
};

export default Pharmacy;
