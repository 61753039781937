import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MenuItem } from '@mui/material';
import { greyboxApiActions } from '../../redux/api';
import FormSelect from './FormSelect';

type QuestionnaireSelectProps = {
  formik: any;
  name: string;
  required?: boolean;
  sx?: any;
  questionnaireId?: number;
};

const QuestionnaireSelect = (props: QuestionnaireSelectProps) => {
  const {
    formik, name, sx = {}, required = false, questionnaireId,
  } = props;
  const { t } = useTranslation();
  const { questionnaire } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { data, isLoading } = questionnaire.list({ clinic: clinic.id });

  React.useEffect(() => {
    if (questionnaireId) {
      const selected = data.find((q) => q.id === questionnaireId);
      if (selected) {
        formik.setFieldValue(name, selected.questionnaire_clinic_id);
      }
    }
  }, [data]);

  return (
    <FormSelect
      formik={formik}
      name={name}
      required={required}
      sx={{ 
        width: '100%',
        mx: 0,  
        ...sx, // Allow passing custom styles
      }}
      label={t('Questionnaire')}
      disabled={isLoading}
    >
      {data?.map((q) => (
        <MenuItem key={q.questionnaire_clinic_id} value={q.questionnaire_clinic_id}>
          {q.name}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

export default QuestionnaireSelect;
