import { LoadingButton } from '@mui/lab';
import {
  Box, Button, Dialog, DialogActions, DialogContent, MenuItem, TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import convertToYup from 'json-schema-yup-transformer';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import DialogTitle from '../../DialogTitle';

const createValidationSchema = (clinicalDataConfig = []) => {
  const schema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    $id: 'http://example.com/clinicalData.schema.json',
    title: 'ClinicalData',
    description: 'Clinical data',
    type: 'object',
    properties: {},
    required: [],
  };

  clinicalDataConfig.forEach((config) => {
    schema.properties[config.short_code] = config.dataTypeJSON;
  });

  return convertToYup(schema);
};

const initialValues = (clinicalDataConfig = []) => {
  const initVals = {};

  clinicalDataConfig.forEach((config) => {
    initVals[config.short_code] = '';
  });

  return initVals;
};

/**
 * Set of input fields for adding a new clinical data entry. Will adjust based on the clinical data
 * config. It also permits the user to select a date from a calendar for past entry.
 */
const AddClinicalDataModal = (props) => {
  const {
    open,
    handleClose,
    patientUuid
  } = props;
  const { t, i18n } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { clinicalDataConfig, clinicalData } = greyboxApiActions;
  const { data } = clinicalDataConfig.list({ clinic: clinic.id });
  const [postClinicalData, { isLoading }] = clinicalData.add();
  const [date, setDate] = useState(moment());
  const validationSchema = createValidationSchema(data);
  const formik = useFormik({
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    initialValues: initialValues(data),
  });

  const handleSubmit = (values) => {
    const body = [];

    Object.entries(values).forEach(([key, value]) => {
      if (value) {
        body.push({
          short_code: key,
          value: value,
          time: date,
          patient: patientUuid,
        });
      }
    });

    postClinicalData({ body: body }).then(() => {
      handleClose();
    });
  };

  const fieldMap = (config) => {
    const jsonSchema = config.dataTypeJSON;
    if (jsonSchema.enum) {
      return (
        <TextField
          select
          id={config.short_code}
          name={config.short_code}
          fullWidth
          label={config.name}
          value={formik.values[config.short_code] || ''}
          onBlur={formik.handleBlur}
          error={formik.touched[config.short_code] && Boolean(formik.errors[config.short_code])}
          helperText={formik.touched[config.short_code] && formik.errors[config.short_code]}
          variant="outlined"
          onChange={formik.handleChange}
        >
          {jsonSchema.enum && jsonSchema.enum.map((value, index) => (
            <MenuItem key={value} value={value}>
              {jsonSchema.enum_titles[index]}
            </MenuItem>
          ))}
        </TextField>

      );
    }

    return (
      <TextField
        id={config.short_code}
        name={config.short_code}
        type={jsonSchema.type}
        label={`${config.name} ${config.unit ? `(${config.unit})` : ''}`}
        value={formik.values[config.short_code] || ''}
        onBlur={formik.handleBlur}
        error={formik.touched[config.short_code] && formik.errors[config.short_code]}
        helperText={formik.touched[config.short_code] && formik.errors[config.short_code]}
        variant="outlined"
        onChange={formik.handleChange}
      />
    );
  };

  return (
    open && (
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle onClose={handleClose}>
          {t('Add clinical data')}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Box sx={{
              '& .MuiTextField-root': { m: 1, width: '32ch' },
              display: 'flex',
              justifyContent: 'left',
              flexWrap: 'wrap',
            }}
            >
              {data.map((config) => (
                <div key={config.short_code}>
                  {fieldMap(config)}
                </div>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Date"
                value={moment(date)}
                onChange={(d) => setDate(d)}
                disableFuture
              />
            </LocalizationProvider>
            <Button onClick={handleClose} sx={{ ml: 1 }}>
              {t('Cancel')}
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isLoading}>
              {t('Add')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    )
  );
};

AddClinicalDataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddClinicalDataModal;
