import ClearIcon from '@mui/icons-material/Clear';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Link,
  MenuItem,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import FormSelect from '../../form-inputs/FormSelect';
import FormTextField from '../../form-inputs/FormTextField';
import RichTextEditor from '../../RichTextEditor';

const LANGUAGES = [{
  code: 'en',
  name: 'English',
}, {
  code: 'fr',
  name: 'French',
}, {
  code: 'es',
  name: 'Spanish',
}, {
  code: 'de',
  name: 'German',
}, {
  code: 'it',
  name: 'Italian',
}, {
  code: 'pt',
  name: 'Portuguese',
}];

const AddDocument = (props) => {
  const { open, handleClose, section, articleId } = props;
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const { educationArticleManagement } = greyboxApiActions;
  const [addArticle] = educationArticleManagement.add();
  const [updateArticle] = educationArticleManagement.update();
  const {
    data, isLoading, isError, refetch,
  } = educationArticleManagement.get(articleId, { skip: !articleId });

  const formik = useFormik({
    initialValues: LANGUAGES.reduce((acc, lang) => ({
      ...acc,
      [`title_${lang.code}`]: data ? data[`title_${lang.code}`] || '' : '',
      [`description_${lang.code}`]: data ? data[`description_${lang.code}`] || '' : '',
      [`url_${lang.code}`]: data ? data[`url_${lang.code}`] || '' : '',
      [`source_${lang.code}`]: data ? data[`source_${lang.code}`] || '' : '',
    }), {
      type: data ? data.type : 'pdf',
    }),
    enableReinitialize: true,
    validationSchema: Yup.object(
      LANGUAGES.reduce((schema, lang) => ({
        ...schema,
        [`title_${lang.code}`]: Yup.string(),
        [`description_${lang.code}`]: Yup.string(),
        [`url_${lang.code}`]: Yup.string(),
        [`source_${lang.code}`]: Yup.mixed(),
      }), {
        type: Yup.string().required(t('Required')),
      })
    ),
    onSubmit: async (values) => {
      const formData = new FormData();

      if (articleId) {

        Object.entries(values).forEach(([key, value]) => {
          if (value !== formik.initialValues[key]) {
            formData.append(key, value);
          }
        });
        await updateArticle({ id: articleId, body: formData, feedback: { success: t('Document updated') } });
      } else {
        formData.append('section', section);
        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });
        await addArticle({ body: formData, feedback: { success: t('Education document added') } });
      }
      handleClose();
    },
  });

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Skeleton width="60%" />
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: 400, overflowY: 'auto', padding: '24px' }}>
          <Skeleton variant="rectangular" width="100%" height={30} sx={{ marginBottom: '16px' }} />
          <Skeleton variant="rectangular" width="100%" height={150} />
        </DialogContent>
        <DialogActions>
          <Skeleton width="30%" />
        </DialogActions>
      </Dialog>
    );
  }

  if (isError) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography variant="h6">{t('Error')}</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: 400, overflowY: 'auto', padding: '24px' }}>
          <Typography variant="body1" >{t('Failed to load document data')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ marginRight: '8px' }}>{t('Close')}</Button>
          <Button onClick={refetch} variant="contained">{t('Retry')}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{articleId ? t('Edit document') : t('Add document')}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ padding: '24px' }}>
          <FormSelect name="type" formik={formik} label={t('Type')} required sx={{ marginBottom: '24px' }}>
            <MenuItem value="image">{t('Image')}</MenuItem>
            <MenuItem value="video">{t('Video')}</MenuItem>
            <MenuItem value="audio">{t('Audio')}</MenuItem>
            <MenuItem value="pdf">{t('PDF')}</MenuItem>
            <MenuItem value="url">{t('URL')}</MenuItem>
          </FormSelect>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ marginBottom: '16px', borderBottom: 1, borderColor: 'divider' }}
          >
            {LANGUAGES.map((lang) => (
              <Tab key={lang.code} label={t(lang.name)} />
            ))}
          </Tabs>
          {LANGUAGES.map((lang, index) => (
            <Box
              key={lang.code}
              hidden={tabIndex !== index}
              sx={{
                display: tabIndex === index ? 'block' : 'none',
                mt: 2,
                paddingBottom: '16px',
              }}
            >
              <FormTextField
                name={`title_${lang.code}`}
                formik={formik}
                label={t('Title')}
                fullWidth
                sx={{ marginBottom: '16px' }}
              />
              <RichTextEditor
                field={formik.getFieldProps(`description_${lang.code}`)}
                form={formik}
                placeholder={t('Enter description')}
                sx={{ marginBottom: '16px' }}
              />
              {formik.values.type === 'url' && (
                <FormTextField
                  name={`url_${lang.code}`}
                  formik={formik}
                  label={t('URL')}
                  fullWidth
                  sx={{ marginBottom: '16px' }}
                />
              )}
              {formik.values.type !== 'url' && (
                <Dropzone formik={formik} name={`source_${lang.code}`} />
              )}
            </Box>
          ))}
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button onClick={handleClose} sx={{ marginRight: '8px' }}>
            {t('Cancel')}
          </Button>
          <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>

  );
};

const Dropzone = ({ formik, name }) => {
  const [isDragging, setIsDragging] = React.useState(false);
  const { t } = useTranslation();

  const handleDrop = (event) => {
    event.preventDefault();

    if (event.dataTransfer.files.length > 1) {
      return;
    }

    formik.setFieldValue(name, event.dataTransfer.files[0]);
  };

  return formik.values[name] ? (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        m: 1,
        p: 1,
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        backgroundColor: (theme) => theme.palette.primary.light,
      }}
    >
      <Typography variant="body1" sx={{ mr: 1 }}>
        {formik.values[name] instanceof File ? (
          formik.values[name].name
        ) : (
          <Link
            href={formik.values[name]}
            target="_blank"
            rel="noopener noreferrer"
            underline="hover"
          >
            {formik.values[name]}
          </Link>
        )}
      </Typography>
      <Tooltip title={t('Remove file')}>
        <Button onClick={() => formik.setFieldValue(name, null)}>
          <ClearIcon />
        </Button>
      </Tooltip>
    </Box>
  ) : (
    <Box
      onDrop={(e) => handleDrop(e)}
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      sx={{
        border: (theme) => `2px ${isDragging ? 'solid' : 'dashed'} ${theme.palette.divider}`,
        borderRadius: 2,
        display: 'flex',
        cursor: isDragging ? 'copy' : 'pointer',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        m: 1,
        p: 4,
      }}
    >
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t('Drag and drop your file here')}
      </Typography>
      <input
        style={{ display: 'none' }}
        onChange={(e) => formik.setFieldValue(name, e.target.files[0])}
        id="raised-button-file"
        type="file"
      />
      <label htmlFor="raised-button-file">
        <Button variant="raised" component="span">
          {t('Browse files')}
        </Button>
      </label>
    </Box>
  );
};

export default AddDocument;
