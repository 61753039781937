import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const AddIdentifierDialog = ({ open, handleClose, handleSave, initialValues }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: initialValues || { value: '', system: '' },
    enableReinitialize: true,
    validationSchema: Yup.object({
      value: Yup.string().required(t('Required')),
      system: Yup.string().required(t('Required')),
    }),
    onSubmit: (values) => {
      handleSave(values);
      formik.resetForm();
      handleClose();
    },
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{initialValues ? t('Edit Identifier') : t('Add Identifier')}</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <TextField
            fullWidth
            id="value"
            name="value"
            label={t('Value')}
            value={formik.values.value}
            onChange={formik.handleChange}
            error={formik.touched.value && Boolean(formik.errors.value)}
            helperText={formik.touched.value && formik.errors.value}
            margin="normal"
          />
          <TextField
            fullWidth
            id="system"
            name="system"
            label={t('System')}
            value={formik.values.system}
            onChange={formik.handleChange}
            error={formik.touched.system && Boolean(formik.errors.system)}
            helperText={formik.touched.system && formik.errors.system}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button color="primary" variant="contained" type="submit">
            {t('Save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddIdentifierDialog;
