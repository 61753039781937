import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box, Button, IconButton, Menu, MenuItem,
  Paper,
  Stack, Table,
  TableBody, TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { greyboxApiActions } from '../../../redux/api';
import { QuestionnaireAnswers } from '../../../types';
import TableWrapper from '../../TableWrapper';
import { NoData } from '../../tk-ui';
import PrintableAnswers from './PrintableAnswers';
import QuestionnaireSummaryButton from './QuestionnaireSummaryButton';
import ScoreButton from './ScoreButton';

type Data = {
  count: number;
  results: QuestionnaireAnswers[];
};

type AnswerTableProps = {
  questionnaireId: number;
  patientUuid: string;
  setOpen: (open: boolean) => void;
  setPreviousAnswers: (previousAnswers: any) => void;
};

type Column = {
  id: string;
  label: string;
  minWidth: number;
  align?: 'right' | 'left' | 'center';
  format?: (value: any, row?: any, recordIndex?: number) => any;
};

type QuestionnaireAnswer = {
  data: Data;
  isFetching: boolean;
};

const ScoreCell = ({ answer }) => {
  const { clinic } = useSelector((state) => state.clinic);
  const { description, color } = answer.score_range || {};

  // APP4WE clinic has a different way of displaying scores
  if (clinic.name === 'APP4WE') {
    return (
      <TableCell>
        <ScoreButton score={answer.score} />
      </TableCell>
    );
  }

  // If there is a description, display it
  if (description) {
    return (
      <TableCell>
        <Stack direction="row" spacing={1} alignItems="center">
          <Box
            sx={{
              width: '20px',
              mr: 1,
              height: '20px',
              borderRadius: '50%',
              backgroundColor: color || 'white',
            }}
          />
          {`${answer.score.value} - ${description}`}
        </Stack>
      </TableCell>
    );
  }

  // Legacy way of displaying scores
  return (
    <TableCell>
      {answer.score?.value !== null && answer.score?.value !== undefined ? answer.score.value : '---'}
    </TableCell>
  );
};

const AnswersTable = ({ questionnaireId, patientUuid, setOpen, setPreviousAnswers }: AnswerTableProps) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [hover, setHover] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedToPrint, setSelectedToPrint] = useState(null);
  const { questionnaireAnswers, questionnaire, account } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { edit } = useSelector((state) => state.permissions);
  const questionnaireSelector = questionnaire.list({ id: questionnaireId, clinic: clinic.id });
  const [deleteAnswers] = questionnaireAnswers.delete();
  const [anchorEl, setAnchorEl] = useState(null);
  const printRef = useRef();
  const menuOpen = Boolean(anchorEl);
  const accountSelector = account.get(patientUuid);
  const { data = [], isFetching }: QuestionnaireAnswer = questionnaireAnswers.list(
    {
      patient: patientUuid,
      questionnaire: questionnaireId,
      page_size: rowsPerPage,
      page: page + 1,
      clinic: clinic.id,
    },
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: questionnaireSelector.data?.name,
    onBeforePrint: () => {
      setAnchorEl(null);
    },
  });

  useEffect(() => {
    if (selectedToPrint) {
      handlePrint();
    }
  }, [selectedToPrint]);

  const columns = [
    {
      id: 'answer_timestamp',
      label: t('Date'),
      minWidth: 100,
      align: 'left',
      format: (value) => moment(value).format('YYYY-MM-DD HH:mm'),
    },
    {
      id: 'status',
      label: t('Status'),
      format: (value) => t(value),
      minWidth: 100,
    },
    {
      id: 'id',
      label: t('Answers'),
      minWidth: 200,
      format: (value, row, recordIndex) => (
        <QuestionnaireSummaryButton
          recordId={value}
          date={row.answer_timestamp}
          score={row.score}
          recordIndex={recordIndex}
          hasScore={questionnaireSelector.data?.has_score}
        />
      ),
    },
    {
      id: 'created_by',
      label: t('Answered by'),
      minWidth: 100,
      format: (value) => (value ? `${value.first_name} ${value.last_name}` : '---'),
    },

  ] as Column[];

  return (
    <>
      <Paper sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        p: 1,
      }}
      >
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          disabled={!edit}
          data-cy="add-answer"
          onClick={() => setOpen(true)}
          size="small"
        >
          {t('Add')}
        </Button>
      </Paper>
      <TableWrapper
        total={data?.count}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        isFetching={isFetching}
        maxHeight="50vh"
        colCount={columns.length + 1}
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell key={idx} align={column.align || 'center'} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
              {questionnaireSelector.data?.has_score && (
                <TableCell sx={{ minWidth: 100 }}>{t('Score')}</TableCell>
              )}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results?.length > 0 ? (
              data.results.map((record, recordIndex) => (
                <TableRow
                  key={record.id}
                  hover
                  sx={{ height: 68 }}
                  onMouseEnter={() => setHover(record.id)}
                  onMouseLeave={() => setHover(null)}
                >

                  {columns.map((column, idx) => (
                    <TableCell key={idx} align={column.align || 'center'}>
                      {column.format ? (
                        column.format(record[column.id], record, recordIndex)
                      ) : (
                        record[column.id]
                      )}
                    </TableCell>
                  ))}
                  {questionnaireSelector.data.has_score && (
                    <ScoreCell answer={record} />
                  )}
                  <TableCell sx={{ minWidth: '35px' }}>
                    {(hover === record.id) && (
                      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                        <MoreVertIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpen && hover === record.id}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={() => setSelectedToPrint(record)}>
                      {t('Print')}
                    </MenuItem>
                    <MenuItem onClick={() => setPreviousAnswers(record.answers)}>
                      {t('Pre-fill a questionnaire')}
                    </MenuItem>
                    <MenuItem onClick={() => deleteAnswers(record.id)}>
                      {t('Delete')}
                    </MenuItem>
                  </Menu>
                </TableRow>

              ))
            ) : (
              !isFetching && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} sx={{ borderBottom: 'none' }}>
                    <Box height="200px" display="flex" justifyContent="center" alignItems="center">
                      <NoData style={{ transform: 'scale(4)' }} />
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableWrapper>
      <Box sx={{ display: 'none' }}>
        <PrintableAnswers
          ref={printRef}
          answers={selectedToPrint}
          patient={accountSelector.data}
          questionnaire={questionnaireSelector.data}
        />
      </Box>
    </>
  );
};

export default AnswersTable;
