import { combineReducers, compose } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import api from './api';
import authorization from './authorization';
import clinic from './clinic';
import dockedChatBox from './dockedChatBox';
import snackbarMiddleware from './middlewares/snackbarMiddleware';
import patient from './patient';
import patientProfileDateRange from './patientProfileDateRange';
import user from './user';
import snackbarStatus from './snackbarStatus';
import permissions from './permissions';
import invitation from './invitation';
import theme from './theme';
import pageLoader from './pageLoader';

const appReducer = combineReducers({
  patient,
  user,
  authorization,
  clinic,
  permissions,
  dockedChatBox,
  patientProfileDateRange,
  [api.reducerPath]: api.reducer,
  snackbarStatus,
  invitation,
  pageLoader,
  theme
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_REDUX_STORE') {
    state = {};
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    api.middleware,
    snackbarMiddleware,
  ),
  devTools: process.env.NODE_ENV !== 'production',
});

// Function to create a test store with an optional initial state
export const createTestStore = (initialState) => configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    api.middleware,
    snackbarMiddleware,
  ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store;
