import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { XIcon } from '../../../tk-ui';
import { Popovers } from '../../../tk-ui/popover';
import { NoMedications } from '../../img';

function SuggestedNone() {
  const { t } = useTranslation();

  return (
    <Paper
      variant='outlined'
      sx={(theme) => ({
        p: 2,
        backgroundColor: theme.palette.mode === 'dark' ? '#0a2540' : '#e0f5f5',
      })}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <NoMedications data-cy="WarningInfoIcon" sx={{ mr: 2, color: 'text.primary' }} />
        <Typography variant="h6">
          {`${t('No suggested treatment plan for this patient')}.`}
        </Typography>
      </Box>

      <Typography variant="body2">
        {t('Based on the available data, TAKECARE does not suggest any medication in the treatment plan')}.
      </Typography>
    </Paper>

  );
}

function DisplayInformation() {
  const { t } = useTranslation();
  return (
    <Paper
      variant='outlined'
      sx={{ p: 2, backgroundColor: '#e0f5f5' }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <NoMedications data-cy="WarningInfoIcon" sx={{ mr: 2 }} />
        <Typography variant="h6">
          {t('Guideline information')}
        </Typography>
      </Box>

      <Typography variant="body2">
        <ul>
          <Trans i18nKey="HF_GUIDELINE" t={t}>
            <li>Identification and treatment of underlying factors and comorbidities.</li>
            <li>Minimum effective diuretic dose to maintain euvolemia.</li>
            <li>An MRA like spironolactone should be considered.</li>
          </Trans>
        </ul>
      </Typography>
    </Paper>
  );
}

function Consultation() {
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '232px', borderRadius: '5px', position: 'relative', margin: '0px 20px 0 0px', backgroundColor: '#EDEDED' }} data-cy="Consultation">
      <Box sx={{ width: '232px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', height: '76px', background: 'rgba(255,255,255,0.65)' }}>
        <Typography variant="h6" sx={{ padding: '7px 10px 2px 12px', color: '#454545' }} data-cy="ConsultationTitle">
          {t('Consultation')}
        </Typography>
        <Box sx={{ marginLeft: '5.7px' }}>
          <Popovers
            pointer={false}
            borderRadius="3px"
            popTopPos="-10px"
            anchorVertical="top"
            text={t('CAUTION')}
            anchorHorizontal="left"
            transformVertical="bottom"
            transformHorizontal="left"
            ownStyle={{
              marginTop: '2px',
              marginLeft: '6.5px',
              backgroundColor: 'rgba(0,0,0,0)',
              padding: '10px 8px',
              borderRadius: '3px',
              border: 'solid 1px #6c6c6c',
              fontSize: '14px',
              textTransform: 'capitalize',
              fontWeight: 500,
              lineHeight: 0.7,
              color: '#454545',
            }}
          >
            {(closePop) => (
              <Box sx={{ width: '360px', padding: '12px', backgroundColor: '#454545', position: 'relative' }} data-cy="ConsultationBox">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', color: 'white' }}>
                  <Typography variant="h6" sx={{ marginBottom: '12px' }}>
                    {t('Consultation - Endocrinologist')}
                  </Typography>
                  <Box
                    onClick={closePop}
                    sx={{ position: 'absolute', top: '8px', right: '10px', cursor: 'pointer' }}
                    role="button"
                    tabIndex={0}
                  >
                    <XIcon fills="#babdbf" />
                  </Box>
                </Box>
                <Box sx={{ backgroundColor: '#363636' }}>
                  <Typography variant="body2" sx={{ color: 'white', padding: '12px', fontWeight: 400, lineHeight: 1.2 }} data-cy="ConsultationText">
                    {t(
                      'Based on available data, Takecare suggests consulting an endocrinologist to define the patient\'s treatment plan',
                    )}
                    .
                  </Typography>
                </Box>
              </Box>
            )}
          </Popovers>
        </Box>
      </Box>
      <Box sx={{ fontWeight: 400, fontSize: '14px', color: '#454545', display: 'flex', position: 'relative', margin: '10px 7px', padding: '4px 5px' }} data-cy="ConsultationName">
        {t('Endocrinologist')}
      </Box>
    </Box>
  );
}

export { Consultation, DisplayInformation, SuggestedNone };

