import {
  Add as AddIcon,
} from '@mui/icons-material';
import { MdPlaylistRemove } from "react-icons/md";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import MuiMarkdown from 'mui-markdown';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MedicationModal from '../medicationTable/MedicationModal';
import { formatDosageToMarkdown } from '../medicationTable/utils';
import { FieldArray } from 'formik';

const MedicationFields = ({ name }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = (newMedication, push) => {
    setOpen(false);
    if (newMedication) {
      push(newMedication); // Push new medication to FieldArray
    }
  };

  return (
    <FieldArray
      name={name} // Ties the array to Formik
      render={({ push, remove, form }) => {
        const medications = form.values[name]; // Access medications from FieldArray's form.values

        return (
          <Box>
            <Typography variant="h6" gutterBottom>
              {t('Medications')}
            </Typography>
            <TableContainer component={Paper}>
              <Table aria-label="medications table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Medication Name')}</TableCell>
                    <TableCell>{t('Posology')}</TableCell>
                    <TableCell align="center">{t('Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medications && medications.length > 0 ? (
                    medications.map((medication, index) => (
                      <TableRow key={index} hover>
                        <TableCell>
                          {medication.name || '---'}
                        </TableCell>
                        <TableCell>
                          {medication.dosage
                            ? <MuiMarkdown>
                                {formatDosageToMarkdown(medication.dosage, t)}
                              </MuiMarkdown>
                            : '---'}
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title={t('Remove Medication')}>
                            <IconButton
                              aria-label="remove medication"
                              onClick={() => remove(index)} // Remove the medication from the FieldArray
                              color="primary"
                            >
                              <MdPlaylistRemove />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3} align="center">
                        {t('No medications available')}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box mt={2}>
              <Button
                startIcon={<AddIcon />}
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
                data-cy="addMedicationButton"
              >
                {t('Add Medication')}
              </Button>
            </Box>

            {/* MedicationModal for adding medications */}
            <MedicationModal
              open={open}
              handleClose={(newMedication) => handleCloseDialog(newMedication, push)} // Push new medication to FieldArray
            />
          </Box>
        );
      }}
    />
  );
};

export default MedicationFields;
