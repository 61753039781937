import {
  Box
} from '@mui/material';
import React from 'react';
import CompletedQuestionnaires from './CompletedQuestionnaires';

const QuestionnairesHistory = ({patientUuid}) => {

  return (
    <Box sx={{ width: '100%', p: 2, height: 'fit-content' }}>
      <CompletedQuestionnaires patientUuid={patientUuid}/>
    </Box>
  );
};

export default QuestionnairesHistory;
