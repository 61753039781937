import { Add, MoreVert } from '@mui/icons-material';
import {
  Box,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TelecomModal from './TelecomModal';
import Row from '../Row'
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import FaxIcon from '@mui/icons-material/Fax';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const getIcon = (system) => {
  switch (system) {
    case 'phone':
      return <PhoneIcon />;
    case 'fax':
      return <FaxIcon />;
    case 'email':
      return <EmailIcon />;
    default:
      return <SmartphoneIcon />;
  }
}

const Telecom = ({ informations }) => {
  const [editIndex, setEditIndex] = React.useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuIndex, setMenuIndex] = React.useState(null);
  const { t } = useTranslation();

  const handleEdit = (index) => {
    setEditIndex(index);
    setModalOpen(true);
    handleMenuClose();
  };

  const handleRankChange = (index, rank) => {
    // Only allow one telecom to be preferred
    const updatedTelecoms = informations.values.telecom.map((telecom, i) => {
      if (i === index) {
        return { ...telecom, rank: rank ? 1 : 0 };
      } else if (rank) {
        return { ...telecom, rank: 0 };
      } else {
        return telecom;
      }
    });
  
    informations.setFieldValue('telecom', updatedTelecoms);
  };

  const handleAdd = (telecom) => {
    if (editIndex !== null) {
      const updatedTelecoms = informations.values.telecom.slice();
      updatedTelecoms[editIndex] = telecom;
      informations.setFieldValue('telecom', updatedTelecoms);
      setEditIndex(null);
    } else {
      informations.setFieldValue('telecom', [...informations.values.telecom, telecom]);
    }
  };

  const handleRemove = (index) => {
    const updatedTelecoms = informations.values.telecom.slice();
    updatedTelecoms.splice(index, 1);
    informations.setFieldValue('telecom', updatedTelecoms);
    handleMenuClose();
  };

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuIndex(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
        <FormLabel>{t('Contact Informations')}</FormLabel>
        <IconButton size="small" sx={{ ml: 1 }} onClick={() => setModalOpen(true)}>
          <Add />
        </IconButton>
      </Box>
      <Row>
      {informations.values.telecom.map((id, index) => (
        <Box
          key={index}
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'row',
            mx: 1,
            borderRadius: 3,
            p: 1,
          }}
        >
          <Box sx={{ pl: 1 }} display="flex" flexDirection="column">
            <FormControlLabel
              control={
                <Switch checked={id.rank === 1} onChange={(event) => handleRankChange(index, event.target.checked)} />
              }
              label={t('Prefered')}
            />
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {getIcon(id.system)}
              <Typography sx={{ml: 1}}>
                {`${id.value} (${id.use && t(id.use)})`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ ml: 'auto', alignSelf: 'flex-start' }}>
            <IconButton size="small" onClick={(event) => handleMenuOpen(event, index)}>
              <MoreVert sx={{ transform: 'scale(0.8)' }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && menuIndex === index}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={() => handleEdit(index)}>{t('Edit')}</MenuItem>
              <MenuItem sx={{ color: (theme) => theme.palette.error.main }} onClick={() => handleRemove(index)}>
                {t('Remove')}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      ))}
      </Row>
      {modalOpen && (
        <TelecomModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onChange={handleAdd}
          telecom={editIndex !== null ? informations.values.telecom[editIndex] : {}}
        />
      )}
    </>
  );
};

export default Telecom;
