import React from 'react';
import {
  BrowserRouter,
  Navigate, Route, Routes,
} from 'react-router-dom';
import Invitation from './Invitation';
import Login from './Login';
import SelfRegistration from './SelfRegistration';
import AuthenticateForQuestionnaire from './AuthenticateForQuestionnaire';

/**
 * Pages only accessible when unauthenticated. Everything that wraps this component
 * will be shown when the user is not authenticated wherever in the app.
 */
const UnauthenticatedPages = () => (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/invitation" element={<Invitation />} />
      <Route path="/self-registration" element={<SelfRegistration />} />
      <Route path="/questionnaires-token/" element={<AuthenticateForQuestionnaire />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
);

export default UnauthenticatedPages;
