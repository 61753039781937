import React from 'react';
import BackArrowIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type BackButtonProps = {
  children?: React.ReactNode;
  to?: string;
  sx?: any;
};

const BackButton = ({ children, to, sx }: BackButtonProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleNavigation = () => {
    if (to) {
      navigate(to);
    } else {
      navigate(-1);
    }
  };

  return (
    <Button
      startIcon={<BackArrowIcon />}
      onClick={handleNavigation}
      variant="outlined"
      sx={sx}
    >
      {children || t('Go back')}
    </Button>
  );
};

export default BackButton;
