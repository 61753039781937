import { Box } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { usePatientWebSocket } from '../../hooks';
import { greyboxApiActions } from '../../redux/api';
import AddVitalsDialog from '../vitals/AddVitalsDialog';
import { VITAL_CONFIG } from '../vitals/utils';
import ChartContainer from './ChartContainer';
import CountChart from './CountChart';
import { getDurations } from './DurationSelect';
import LineChart from './LineChart';
import SummaryChart from './SummaryChart';
import {
  parseObservationData, parseThresholds, parseVitalsData,
} from './utils';

const ObservationChart = (props) => {
  const {
    measures,
    config,
    patientUuid,
    autoHeight = false,
    detailed = false,
  } = props;
  const { t } = useTranslation();
  const { is_specific, name, short_code } = config;
  const { range, startDate, endDate } = useSelector((state) => state.patientProfileDateRange);
  const { observationSummary } = greyboxApiActions;
  const [isSummary, setIsSummary] = useState(false);
  const [open, setOpen] = useState(false);
  const [series, setSeries] = useState([]);
  const { lastJsonMessage } = usePatientWebSocket();
  const { threshold, observation } = greyboxApiActions;
  const [duration, setDuration] = useState(null);
  const chartConfig = VITAL_CONFIG[short_code]?.chartConfig || null;
  const durations = getDurations(startDate, endDate);

  const getSelector = () => {
    if (!is_specific) {
      const params = {
        subject: patientUuid,
        status: 'final,amended',
        page_size: 50,
        GB_medicalMeasurementType__in: measures.map((m) => m?.id).toString(),
      };

      if (endDate === null) {
        params.effectiveDateTime__gte = moment(startDate).toISOString();
      } else {
        params.effectiveDateTime__range = range;
      }
      return observation.list(params);
    }

    const params = {
      account: patientUuid,
      ordering: 'time',
      endTime__range: range,
      resolution: 'D',
    };

    if (endDate === null) {
      params.time__gte = moment(startDate).toISOString();
    } else {
      params.time__range = range;
    }

    return greyboxApiActions[VITAL_CONFIG[short_code].apiCall].list(params);
  };

  const summarySelector = observationSummary.list({
    subject: patientUuid,
    effectiveDateTime__range: range,
    status: 'final,amended',
    duration: `${duration} minutes`,
    GB_medicalMeasurementType__in: measures.map((m) => m?.id).toString(),
  }, { skip: !isSummary });

  const thresholdParams = {
    localAccount: patientUuid,
    end_date_isnull_or_gte: moment(startDate).format('YYYY-MM-DD'),
    function__in: measures.map((m) => m?.threshold_code).toString(),
  };

  if (endDate !== null) {
    thresholdParams.end_date_isnull_or_range_before = moment(endDate).toISOString();
  }

  const thresholdSelector = threshold.list(thresholdParams, {
    skip: !measures[0]?.threshold_code,
  });

  const selector = getSelector();

  const thresholds = useMemo(() => {
    return parseThresholds(thresholdSelector.data, startDate, short_code, measures);
  }, [thresholdSelector.data]);

  useEffect(() => {
    if (selector.data) {
      if (!is_specific && selector.data?.count > 200) {
        setIsSummary(true);
      } else {
        setIsSummary(false);
        const parsedData = [];

        // Observations
        if (!is_specific) {
          const newSeries = parseObservationData(selector.data.results);

          Object.entries(newSeries).forEach(([key, value]) => {
            parsedData.push({
              name: measures.find((m) => m.short_code === key)?.name || key,
              type: chartConfig?.chartType || 'line',
              data: value,
            });
          });
        } else {
          // Legacy vitals
          chartConfig?.values.forEach((value) => {
            parsedData.push({
              name: t(value),
              type: chartConfig?.chartType || 'line',
              data: parseVitalsData(selector.data, value),

            });
          });
        }
        setSeries(parsedData);
      }
    }
  }, [selector.data]);

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity.toLowerCase() === short_code) {
        selector.refetch();
      }

      if (!is_specific
        && lastJsonMessage.entity === 'Observation'
        && lastJsonMessage.data.medicalMeasurementType === short_code) {
        selector.refetch();
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    // Find best duration to display 100 points
    const groupedDuration = (moment(endDate || moment()).diff(moment(startDate), 'minutes')) / 100;
    const bestDuration = durations.find((d) => d.value.asMinutes() > groupedDuration);
    if (bestDuration) {
      setDuration(bestDuration.value.asMinutes());
    }
  }, [startDate, endDate]);

  const disabled = is_specific || selector.data?.count > 200;
  if (isSummary) {
    return (
      <>
        <ChartContainer
          patientUuid={patientUuid}
          detailed={detailed}
          name={name}
          isSummary
          setIsSummary={setIsSummary}
          disabled={disabled}
          vital={short_code}
          url={`/patient-profile/${patientUuid}/vitals/${short_code}`}
          setOpen={setOpen}
          duration={duration}
          setDuration={setDuration}
        >
          <SummaryChart
            vitalSelector={summarySelector}
            shortCode={short_code}
            autoHeight={autoHeight}
            thresholds={thresholds}
            unit={durations.find((d) => d.value.asMinutes() === duration)?.unit || null}
          />
        </ChartContainer>
        {open && (
          <AddVitalsDialog open={open} handleClose={() => setOpen(false)} vital={short_code} />
        )}
        {detailed && (
          <Box sx={{ mt: 1 }}>
            <ChartContainer
              patientUuid={patientUuid}
              vital={short_code}
              detailed={detailed}
              disabled={disabled}
              name={name}
              url={`/patient-profile/${patientUuid}/vitals/${short_code}`}
              setOpen={setOpen}
            >
              <CountChart
                vitalSelector={summarySelector}
                shortCode={short_code}
                unit={durations.find((d) => d.value.asMinutes() === duration)?.unit || null}
              />
            </ChartContainer>
          </Box>
        )}
      </>
    );
  }

  return (
    <ChartContainer
      patientUuid={patientUuid}
      detailed={detailed}
      vital={short_code}
      hasThresholds={measures[0]?.threshold_code}
      disabled={disabled}
      setIsSummary={setIsSummary}
      isSummary={false}
      name={name}
      url={`/patient-profile/${patientUuid}/vitals/${short_code}`}
      setOpen={setOpen}
    >
      <LineChart
        series={[...Object.values(series), ...thresholds]}
        linesCount={series.length}
        thresholdsCount={thresholds.length}
        isLoading={selector.isFetching}
        autoHeight={autoHeight}
        chartConfig={chartConfig}
      />
      {open && (
        <AddVitalsDialog open={open} handleClose={() => setOpen(false)} vital={short_code} patientUuid={patientUuid} />
      )}
    </ChartContainer>
  );
};

export default ObservationChart;
