import LoadingButton from '@mui/lab/LoadingButton';
import {
  Card, CardActions, CardContent, CardHeader, Skeleton, Alert, Autocomplete, TextField,
  Box, MenuItem
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Layout from '../components/Layout';
import Row from '../components/Row';
import FormTextField from '../components/form-inputs/FormTextField';
import Language from '../components/form-inputs/Language';
import PhoneNumber from '../components/form-inputs/PhoneNumber';
import FormSelect from '../components/form-inputs/FormSelect';
import { greyboxApiActions } from '../redux/api';

const UserInformation = () => {
  const { t, i18n } = useTranslation();
  const { user } = greyboxApiActions;

  const { data = {}, isLoading, isError, refetch } = user.get();
  const [updateUser] = user.update();

  const allTimezones = moment.tz.names();

  const weightUnitChoice = [
    { value: 'kg', label: t('Kilogram') },
    { value: 'lb', label: t('Pound') },
  ];

  const temperatureUnitChoice = [
    { value: '°C', label: t('Celsius') },
    { value: '°F', label: t('Fahrenheit') },
  ];

  const formik = useFormik({
    initialValues: {
      first_name: data?.first_name || '',
      last_name: data?.last_name || '',
      email: data?.email || '',
      mobile: data?.phone || '',
      language: data?.language || 'en',
      timezone: data?.timezone || moment.tz.guess(), // Default to user's current timezone
      temperature_unit: data?.temperature_unit || '°C',
      weight_unit: data.weight_unit || 'kg',
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      first_name: yup.string().required(t('required')),
      last_name: yup.string().required(t('required')),
      email: yup.string().email(),
      mobile: yup.string(),
      language: yup.string().required(t('required')),
      timezone: yup.string().required(t('required')),
      temperature_unit: yup.string().required(t('required')),
      weight_unit: yup.string().required(t('required')),
    }),
    onSubmit: (values) => {
      updateUser({ body: values, feedback: { success: t('User updated') } }).unwrap().then(() => {
        formik.setSubmitting(false);
      }).catch((error) => {
        formik.setSubmitting(false);
        if (error.status === 400 && error.data) {
          const errors = error.data;
          formik.setErrors(errors);
        }
      });
    },
  });

  useEffect(() => {
    if (formik.values.language) {
      if (formik.values.language !== i18n.resolvedLanguage) {
        i18n.changeLanguage(formik.values.language);
        moment.locale(formik.values.language);
      }
    }
  }, [formik.values.language]);

  if (isLoading) {
    return (
      <Layout width="md">
        <Card sx={{ m: 1 }}>
          <CardHeader title={<Skeleton width="40%" role="progressbar" />} />
          <CardContent>
            <Row>
              <Skeleton variant="rectangular" width="100%" height={56} role="progressbar" />
              <Skeleton variant="rectangular" width="100%" height={56} role="progressbar" />
            </Row>
            <Row>
              <Skeleton variant="rectangular" width="100%" height={56} role="progressbar" />
              <Skeleton variant="rectangular" width="100%" height={56} role="progressbar" />
            </Row>
            <Row>
              <Skeleton variant="rectangular" width="100%" height={56} role="progressbar" />
              <Skeleton variant="rectangular" width="100%" height={56} role="progressbar" />
            </Row>
            <Row>
              <Skeleton variant="rectangular" width="100%" height={56} role="progressbar" />
              <Skeleton variant="rectangular" width="100%" height={56} role="progressbar" />
            </Row>
          </CardContent>
          <CardActions>
            <Skeleton variant="rectangular" width={100} height={36} sx={{ ml: 'auto' }} role="progressbar" />
          </CardActions>
        </Card>
      </Layout>
    );
  }

  if (isError) {
    return (
      <Layout width="md">
        <Alert severity="error" onClose={() => refetch()}>
          {t('Failed to load user information. Please try again.')}
        </Alert>
      </Layout>
    );
  }

  return (
    <Layout width="md">
      <Card sx={{ m: 1 }}>
        <CardHeader title={t('Your Information')} />
        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            <Row>
              <FormTextField
                formik={formik}
                name="first_name"
                label={t('First name')}
                required
                disabled={formik.isSubmitting}
              />
              <FormTextField
                formik={formik}
                name="last_name"
                label={t('Last name')}
                required
                disabled={formik.isSubmitting}
              />
            </Row>
            <Row>
              <FormTextField
                formik={formik}
                name="email"
                label={t('Email')}
                disabled={formik.isSubmitting}
              />
              <PhoneNumber
                formik={formik}
                name="mobile"
                label={t('Mobile')}
                disabled={formik.isSubmitting}
              />
            </Row>
            <Row>
              <Language
                formik={formik}
                name="language"
                disabled={formik.isSubmitting}
              />
              <Autocomplete
                options={allTimezones}
                value={formik.values.timezone}
                onChange={(event, newValue) => formik.setFieldValue('timezone', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Timezone')}
                    required
                    variant="outlined"
                    sx={{ mx: 1 }}
                    disabled={formik.isSubmitting}
                  />
                )}
                sx={{ pr: 2 }}
              />
            </Row>
            <Row>
              <FormSelect
                formik={formik}
                name="temperature_unit"
                label={t('Temperature Unit')}
                sx={{ mx: 1 }}
                disabled={formik.isSubmitting}
              >
                {temperatureUnitChoice.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </FormSelect>
              <FormSelect
                formik={formik}
                name="weight_unit"
                label={t('Weight Unit')}
                sx={{ mx: 1 }}
                disabled={formik.isSubmitting}
              >
                {weightUnitChoice.map((unit) => (
                  <MenuItem key={unit.value} value={unit.value}>
                    {unit.label}
                  </MenuItem>
                ))}
              </FormSelect>
            </Row>
          </CardContent>
          <CardActions>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{ ml: 'auto' }}
              disabled={formik.isSubmitting || !formik.dirty}
              loading={formik.isSubmitting}
            >
              {t('Save')}
            </LoadingButton>
          </CardActions>
        </form>
      </Card>
    </Layout>
  );
};

export default UserInformation;
