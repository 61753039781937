import React, { useState, useEffect } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const SearchField = ({ setSearch }) => {
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue] = useDebounce(inputValue, 300);
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    setSearch(debouncedValue);
  }, [debouncedValue, setSearch]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      value={inputValue}
      size="small"
      onChange={(e) => setInputValue(e.target.value)}
      placeholder={t('Search')}
      sx={{
        bgcolor: theme.palette.background.paper,
        borderRadius: '25px', // Round the search field
        '& .MuiOutlinedInput-root': {
          borderRadius: '25px',
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: inputValue && (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={() => setInputValue('')}
              edge="end"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchField;
