import { 
  Dialog, 
  DialogActions, 
  DialogContent,
  IconButton,
  Tooltip, 
  DialogTitle, 
  Button, 
  TextField, 
  Box
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaApple } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import { SiMicrosoftoutlook } from "react-icons/si";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FaMicrosoft } from "react-icons/fa6";



const IcsLinkDialog = ({ open, onClose, calendarUrl }) => {
  const { t } = useTranslation();

  const calendarLinks = [
    { label: 'Apple / Outlook', url: `webcal://${calendarUrl}`, icon: <SiMicrosoftoutlook size={40} /> },
    { label: 'Google Calendar', url: `https://calendar.google.com/calendar/r?cid=webcal://${calendarUrl}`, icon: <FaGoogle size={40} /> },
    { label: 'Microsoft 365', url: `https://outlook.office.com/calendar/0/addfromweb?url=webcal://${calendarUrl}`, icon: <FaMicrosoft size={40} /> },
    { label: 'iCal', url: `https://${calendarUrl}`, icon: <FaApple size={40} /> }
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('Add to your personal calendar')}</DialogTitle>
      <DialogContent>
        <Box sx={{
          display: 'flex',
          flex: 'flexWrap',
          gap: 2,
          justifyContent: "center"
        }}>
        {calendarLinks.map((link, index) => (
          <Tooltip key={index} title={link.label}>
            <Button
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100px',
                height: '100px',
                borderRadius: '20%',
                backgroundColor: '#f0f0f0',
              }}
              >
                {link.icon}
              </Button>
            </Tooltip>
          ))}
        </Box>
        <TextField
          value={`https://${calendarUrl}`}
          sx={{ mt: 2}}
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(`https://${calendarUrl}`);
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">{t('Close')}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default IcsLinkDialog;