import AddIcon from '@mui/icons-material/Add';
import NoteIcon from '@mui/icons-material/Note';
import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent, DialogContentText,
  IconButton,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import TableWrapper from '../TableWrapper';
import { NoData } from '../tk-ui';
import RegisterMedicalActDialog from './layout/RegisterMedicalActDialog';

const MedicalActs = ({ patientUuid = null, isAdmin = false }) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [note, setNote] = useState(null);
  const [open, setOpen] = useState(false);
  const { medicalAct } = greyboxApiActions;
  const params = {
    clinic: clinic.id,
    page: page + 1,
    page_size: rowsPerPage,
    expand: patientUuid ? 'hcp' : 'hcp,patient'
  };
  
  if (patientUuid) {
    params.patient = patientUuid;
  }
  
  const { data, isFetching, isError, refetch } = medicalAct.list(params);

  if (isError) {
    return (
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <Typography color="error">
          {t('An error occurred while fetching data.')}
        </Typography>
        <Button variant="contained" onClick={refetch}>
          {t('Retry')}
        </Button>
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
        {t('Follow-up')}
      </Typography>
      {!isAdmin && (
        <Button variant="contained" sx={{ mb: 1 }} startIcon={<AddIcon />} onClick={() => setOpen(true)}>
          {t('Add')}
        </Button>
      )}
      <TableWrapper
        total={data ? data.count : 0}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        maxHeight="80vh"
        isFetching={isFetching}
        colCount={patientUuid ? 6 : 7}
      >
        <TableHead>
          <TableRow>
            <TableCell>{t('Type')}</TableCell>
            {!patientUuid && <TableCell>{t('Patient')}</TableCell>}
            <TableCell>{t('Note')}</TableCell>
            <TableCell>{t('Registered by')}</TableCell>
            <TableCell>{t('Start Date')}</TableCell>
            <TableCell>{t('End Date')}</TableCell>
            <TableCell>{t('Elapsed Time')}</TableCell>
            <TableCell>{t('Date')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isFetching ? (
            [...Array(rowsPerPage)].map((_, index) => (
              <TableRow key={index}>
                <TableCell><Skeleton /></TableCell>
                {!patientUuid && <TableCell><Skeleton /></TableCell>}
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
              </TableRow>
            ))
          ) : data && data.count > 0 ? (
            data.results.map((item) => {
              const start = item.start_datetime ? moment(item.start_datetime) : null;
              const end = item.end_datetime ? moment(item.end_datetime) : null;
              const elapsedTime = start && end ? moment.duration(end.diff(start)).humanize() : '';

              return (
                <TableRow key={item.uuid}>
                  <TableCell>{item.medical_act_type_name}</TableCell>
                  {!patientUuid && (
                    <TableCell>{`${item.patient.first_name} ${item.patient.last_name}`}</TableCell>
                  )}
                  <TableCell>
                    {item.note ? (
                      <Tooltip title={t('View note')}>
                        <IconButton size="small" onClick={() => setNote(item.note)}>
                          <NoteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : '------'}
                  </TableCell>
                  <TableCell>{`${item.hcp.first_name} ${item.hcp.last_name}`}</TableCell>
                  <TableCell>{start ? start.format('lll') : ''}</TableCell>
                  <TableCell>{end ? end.format('lll') : ''}</TableCell>
                  <TableCell>{elapsedTime}</TableCell>
                  <TableCell>{moment(item.createdDate).format('lll')}</TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <Box height="30vh" display="flex" justifyContent="center" alignItems="center">
                  <NoData style={{ transform: 'scale(4)' }} />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableWrapper>
      {open && (
        <RegisterMedicalActDialog
          patientUuid={patientUuid}
          open={open}
          handleClose={() => setOpen(false)}
        />
      )}
      <Dialog
        open={Boolean(note)}
        maxWidth="sm"
        fullWidth
        onClose={() => setNote(null)}
      >
        <DialogContent>
          <DialogContentText>{note}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNote(null)}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MedicalActs;
