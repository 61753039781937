import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button, Dialog,
  DialogActions, DialogContent,
  FormControl, InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import { DatePicker } from '../../form-inputs';
import Row from '../../Row';

const GenerateReportDialog = (props) => {
  const {
    open,
    handleClose,
    patientUuid
  } = props;
  const { i18n, t } = useTranslation();
  const [reportType, setReportType] = useState('');
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeOpen, setIframeOpen] = useState(false);
  const { patientReport, patientReportClinic } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const [postReport, result] = patientReport.add();
  const { data, isFetching } = patientReportClinic.list({
    clinic: clinic.id,
    role: 'patient'
  });

  const handleSubmit = async (values) => {
    const body = {
      type: 'pdf',
      report_clinic: reportType,
      patient: patientUuid,
      language: i18n.resolvedLanguage,
      status: 'pending',
      data: {
        range: {
          start_date: moment(values.startDate).toISOString(),
          end_date: moment(values.endDate).toISOString(),
        },
      },
    };
    const response = await postReport({ body: body });

    if (response.data && response.data.file) {
      setIframeUrl(response.data.file);
      setIframeOpen(true);
    }

    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      startDate: moment().subtract(1, 'months'),
      endDate: moment(),
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const closeIframe = () => {
    setIframeOpen(false);
    setIframeUrl('');
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Row>
            <DatePicker
              label={t('Start Date')}
              formik={formik}
              disableFuture
              name="startDate"
            />
            <DatePicker
              label={t('End Date')}
              disableFuture
              formik={formik}
              name="endDate"
            />
          </Row>
          {isFetching ? (
            <Skeleton variant="rectangular" height={56} sx={{ mx: 1, mt: 1 }} />
          ) : data && data.length > 0 ? (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="report-type-label">{t('Report Type')}</InputLabel>
              <Select
                labelId="report-type-label"
                sx={{ mx: 1, mt: 1 }}
                id="report-type-select"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
                label={t('Report Type')}
              >
                {data.map((report) => (
                  <MenuItem key={report.id} value={report.id}>{report.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="body2" sx={{ mx: 1, mt: 1 }}>
              {t('No reports are configured for this patient.')}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={result.isLoading}>
            {t('Cancel')}
          </Button>
          <LoadingButton
            onClick={formik.handleSubmit}
            loading={result.isLoading}
            disabled={!reportType || result.isLoading || (data && data.length === 0)}
          >
            {t('Generate')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={iframeOpen}
        onClose={closeIframe}
        fullWidth
        maxWidth="md"
        PaperProps={{ style: { height: '100%' } }}
      >
        <DialogContent style={{ height: '100%' }}>
          <iframe src={iframeUrl} width="100%" height="100%" title="Report Preview" />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeIframe}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GenerateReportDialog;
