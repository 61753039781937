import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';

// Autocomplete component with Formik and localized description
const TimingCodeAutocomplete = ({ name, label }) => {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const { setFieldValue } = useFormikContext();

    // Initialize the timing code list with localized descriptions
    const timingCodeDescriptions = [
        { code: 'QD', description: t('Every day') },
        { code: 'BID', description: t('Twice a day') },
        { code: 'TID', description: t('Three times a day') },
        { code: 'QID', description: t('Four times a day') },
        { code: 'AM', description: t('Morning') },
        { code: 'PM', description: t('Afternoon or evening') },
        { code: 'QOD', description: t('Every other day') },
        { code: 'Q1H', description: t('Every 1 hour') },
        { code: 'Q2H', description: t('Every 2 hours') },
        { code: 'Q3H', description: t('Every 3 hours') },
        { code: 'Q4H', description: t('Every 4 hours') },
        { code: 'Q6H', description: t('Every 6 hours') },
        { code: 'Q8H', description: t('Every 8 hours') },
        { code: 'BED', description: t('At bedtime') },
        { code: 'WK', description: t('Weekly') },
        { code: 'MO', description: t('Monthly') },
    ];

    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.code} ${option.description}`
    });

    return (
        <Autocomplete
            value={timingCodeDescriptions.find(option => option.code === field.value) || null}
            options={timingCodeDescriptions}
            getOptionLabel={(option) => option.code}
            filterOptions={filterOptions}
            onChange={(event, newValue) => {
                setFieldValue(name, newValue ? newValue.code : '');
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error ? meta.error : ''}
                />
            )}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderOption={(props, option) => (
                <li {...props}>
                    {`${option.code} - ${option.description}`}
                </li>
            )}
        />
    );
};

export default TimingCodeAutocomplete;
