import React, { useEffect } from 'react';
import { MenuItem, TextField, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type DurationProps = {
  formik: any;
  name: string;
  label: string;
  shortOptions?: boolean;
};

const Duration = (props: DurationProps) => {
  const { formik, name, label, shortOptions = false } = props;
  const { t } = useTranslation();
  const [index, setIndex] = React.useState(1);

  const DURATION_OPTIONS_SHORT = [
    { label: t('None'), value: null },
    { label: t('15 minutes'), value: moment.duration(15, 'minutes') },
    { label: t('30 minutes'), value: moment.duration(30, 'minutes') },
    { label: t('45 minutes'), value: moment.duration(45, 'minutes') },
    { label: t('1 hour'), value: moment.duration(1, 'hours') },
    { label: t('1 hour 15 minutes'), value: moment.duration(1, 'hours').add(15, 'minutes') },
    { label: t('1 hour 30 minutes'), value: moment.duration(1, 'hours').add(30, 'minutes') },
    { label: t('1 hour 45 minutes'), value: moment.duration(1, 'hours').add(45, 'minutes') },
    { label: t('2 hours'), value: moment.duration(2, 'hours') },
  ];
  
  const DURATION_OPTIONS = [
    { label: t('None'), value: null },
    { label: t('15 minutes'), value: moment.duration(15, 'minutes') },
    { label: t('30 minutes'), value: moment.duration(30, 'minutes') },
    { label: t('45 minutes'), value: moment.duration(45, 'minutes') },
    { label: t('1 hour'), value: moment.duration(1, 'hours') },
    { label: t('4 hours'), value: moment.duration(4, 'hours') },
    { label: t('1 day'), value: moment.duration(1, 'days') },
    { label: t('1 week'), value: moment.duration(1, 'weeks') },
  ];
  
  const options = shortOptions ? DURATION_OPTIONS_SHORT : DURATION_OPTIONS;

  useEffect(() => {
    if (index === 0) {
      formik.setFieldValue(name, null);
    } else {
      formik.setFieldValue(name, options[index].value);
    }
  }, [index]);

  useEffect(() => {
    if (formik.values[name] === null || formik.values[name] === '') {
      setIndex(0);
    } else {
      const i = options.findIndex((option) => (
        moment.duration(option.value).asMilliseconds()
        === moment.duration(formik.values[name]).asMilliseconds()));
      setIndex(i);
    }
  }, [formik.initialValues[name]]);

  return (
    <Box sx={{ width: '100%', mx: 0, my: 1 }}>
      <TextField
        select
        fullWidth
        sx={{ mb: 2 }}
        label={label}
        value={index}
        onChange={(e) => setIndex(parseInt(e.target.value, 10))}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={i}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default Duration;
