import AddIcon from '@mui/icons-material/Add';
import TrashIcon from '@mui/icons-material/Delete';
import {
  Button,
  Paper,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import DataGrid from '../DataGrid';
import AddVitalsDialog from './AddVitalsDialog';
import { VITAL_CONFIG } from './utils';

const VitalTable = ({ patientUuid, vital, sx }) => {
  const { t } = useTranslation();
  const { observation } = greyboxApiActions;

  const { edit } = useSelector((state) => state.permissions);
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const { is_specific } = vitalsConfig[vital];

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [addVitalsOpen, setAddVitalsOpen] = React.useState(false);

  const vitalAction = is_specific ? greyboxApiActions[VITAL_CONFIG[vital].apiCall] : null;

  const deleteAction = !is_specific ? (
    observation.update()[0]
  ) : (
    vitalAction.delete()[0]
  );

  const vitalSelector = !is_specific ? (
    observation.list({
      subject: patientUuid,
      page: page + 1,
      page_size: rowsPerPage,
      status: 'final,amended',
      GB_medicalMeasurementType__short_code: vital,
    })
  ) : (
    vitalAction.list({
      account: patientUuid,
      page: page + 1,
      page_size: rowsPerPage,
    })
  );

  const handleDelete = async () => {
    const deletePromises = selected.map((item) => {
      if (!is_specific) {
        return deleteAction({ id: item, body: { status: 'entered-in-error' } });
      }
      return deleteAction(item);
    });

    try {
      await Promise.all(deletePromises);
      setSelected([]);
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  };

  const columns = React.useMemo(() => {
    if (!is_specific) {
      return [
        {
          field: 'effectiveDateTime',
          headerName: t('Date'),
          format: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm') : '---'),
          flex: 1,
        },
        {
          field: 'valueQuantity',
          headerName: t('Value'),
          format: (value, row) => {
            if (value) {
              return `${row.valueQuantity.value} ${row.valueQuantity.unit || ''}`;
            }
            return '---';
          },
          flex: 1,
        },
        {
          field: 'GB_source',
          headerName: t('Source'),
          format: (value) => (value ? t(value) : '---'),
          flex: 1,
        },
        {
          field: 'status',
          headerName: t('Status'),
          format: (value) => t(value),
          flex: 1,
        },
        ...(VITAL_CONFIG[vital]?.tableConfig.map(config => ({
          ...config,
          headerName: t(config.headerName), 
        })) || []),
      ];
    }
    return [
      {
        field: vital === 'CD' ? 'startTime' : 'time',
        headerName: t('Date'), 
        format: (value) => (value ? moment(value).format('YYYY-MM-DD HH:mm') : '---'),
        flex: 1,
      },
      ...(VITAL_CONFIG[vital]?.tableConfig.map(config => ({
        ...config,
        headerName: t(config.headerName), 
      })) || []),
      {
        field: 'device',
        headerName: t('Source'), 
        format: (value) => (value ? t(value) : '---'),
        flex: 1,
      },
    ];
  }, [is_specific, vital]);

  const rows = React.useMemo(() => (
    vitalSelector.data?.results?.map((row) => {
      const val = {};
      columns.forEach((column) => {
        val.id = row.id || row.uuid;
        val[column.field] = column.format ? (
          column.format(row[column.field], row)) : row[column.field];
      });
      return val;
    }) || []), [vitalSelector.data, columns]);

  return (
    <>
      <Paper sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        p: 1,
      }}
      >
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => setAddVitalsOpen(true)}
          disabled={!edit}
          color="primary"
          sx={{ mr: 1 }}
        >
          {t('Add')}
        </Button>
        <Button
          startIcon={<TrashIcon />}
          onClick={handleDelete}
          variant="outlined"
          color="error"
          disabled={selected.length === 0 || !edit}
        >
          {t('Delete')}
        </Button>
      </Paper>
      <DataGrid
        columns={columns}
        rows={rows}
        loading={vitalSelector.isFetching}
        selected={selected}
        onPageChange={setPage}
        onPageSizeChange={setRowsPerPage}
        pageSize={rowsPerPage}
        page={page}
        totalCount={vitalSelector.data?.count || 0}
        setSelectedRows={setSelected}
      />
      {addVitalsOpen && (
        <AddVitalsDialog
          open={addVitalsOpen}
          handleClose={() => setAddVitalsOpen(false)}
          vital={vital}
          patientUuid={patientUuid}
        />
      )}
    </>
  );
};

export default VitalTable;
