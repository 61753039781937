import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { medication_status } from '../../../../helper-functions';
import { greyboxApiActions } from '../../../../redux/api';

const StopMedicationModal = ({ selectedMeds = [], open, handleClose }) => {
  const { t } = useTranslation();
  const { medication } = greyboxApiActions;
  const [updateMedication, result] = medication.update();
  const minDate = moment.max(selectedMeds.map((med) => moment(med.startDate)));
  const [date, setDate] = useState(
    moment.max(moment(minDate).add(1, 'days'), moment()).format('YYYY-MM-DD')
  );

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleStopMedication = async () => {
    await Promise.all(
      selectedMeds.map((med) =>
        updateMedication({
          id: med.uuid,
          body: {
            status: medication_status(med.start_date, date),
            end_date: date,
          },
        })
      )
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('Stop Medication')}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{ borderBottom: '1px solid rgb(241, 242, 243)', pb: 1 }}
        >
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('Medication')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary" align="right">
              {t('Start')}
            </Typography>
          </Grid>
        </Grid>
        {selectedMeds.map((med) => (
          <Grid
            container
            spacing={2}
            key={med.uuid}
            sx={{ borderBottom: '1px solid rgb(241, 242, 243)', py: 1 }}
          >
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {med.name.toLowerCase()}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" align="right">
                {med.startDate}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t('End Date')}
              type="date"
              value={date}
              onChange={handleDateChange}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={result.isLoading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={handleStopMedication}
          variant="contained"
          loading={result.isLoading}
          color="primary"
        >
          {t('Stop')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StopMedicationModal;
