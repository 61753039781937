import {
  Paper, Box, Typography,
  Stack,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DateRangePicker from '../patient-profile/dateRangePicker/DateRangePicker';
import AlertAreaChart from './AlertAreaChart';
import AlertTable from './AlertTable';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const AlertDetails = ({ type , patientUuid}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
          {type === 'alert' ? t('Alerts') : t('Reminders')}
        </Typography>
        <DateRangePicker />
      </Box>
      <Paper
        sx={{
          width: '100%',
          height: '35vh',
        }}
      >
        <AlertAreaChart type={type} patientUuid={patientUuid}/>
      </Paper>
      <Paper className={classes.table}>
        <AlertTable type={type} patientUuid={patientUuid} />
      </Paper>
    </Stack>
  );
};

export default AlertDetails;
