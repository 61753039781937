import {
  Box, Table, TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import TablePaginationSkeleton from '../skeletons/TablePaginationSkeleton';
import TableSkeleton from '../skeletons/TableSkeleton';
import {
  AboveThreshold, BelowThreshold,
  NoData,
} from '../tk-ui';
import QuestionnaireLabel from './QuestionnaireLabel';
import { ALERT_CONFIGS } from './utils';

const useStyles = makeStyles((theme) => ({
  noData: {
    transform: 'scale(4)',
  },
  noDataCell: {
    borderBottom: 'none',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const getLevelColor = (level) => {
  switch (level) {
    case 'High':
      return 'red';
    case 'Moderate':
      return 'orange';
    case 'Low':
      return '#FFD700';
    default:
      return 'grey';
  }
};

const AlertTable = ({ type, patientUuid }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const selector = greyboxApiActions[type];
  const { clinic } = useSelector((state) => state.clinic);
  const { data, isFetching } = selector.list(
    {
      patient: patientUuid,
      page_size: rowsPerPage,
      page: page + 1,
      clinic: clinic.id,
    },
  );

  const columns = [
    {
      id: 'create_date',
      label: t('Date'),
      minWidth: 130,
      align: 'left',
      format: (value) => moment(value).format('lll'),
    },
    {
      id: 'status',
      label: t('Status'),
      format: (value) => t(value),
      minWidth: 100,
    },
    {
      id: 'alert_type',
      label: t('Type'),
      minWidth: 100,
      format: (value, row) => {
        if (value === 'HR') {
          return <QuestionnaireLabel answerId={row.alert_detail.value_reference} />;
        }
        return ALERT_CONFIGS[type].types[value].label;
      },
    },
  ];

  if (type === 'alert') {
    columns.push({
      id: 'alert_detail',
      label: t('Value'),
      align: 'left',
      minWidth: 150,
      format: (value, row) => (
        <Box display="flex" alignItems="center">
          {`${value.value} ${value.unit}`}
          {value.threshold_status && (
            <Box marginLeft="auto" display="flex" alignItems="center">
              {Object.keys(value.threshold_status).map((key) => {
                if (key.startsWith('Range_')) {
                  const status = value.threshold_status[key];
                  const level = key === 'Range_1' ? 'High' : key === 'Range_2' ? 'Moderate' : 'Low';
                  const color = getLevelColor(level);
                  return (
                    <Box key={key} display="flex" alignItems="center" sx={{ color, marginLeft: 1 }}>
                      {status === 'above' ? <AboveThreshold /> : <BelowThreshold />}
                      <Box component="span" sx={{ marginLeft: 0.5 }}>
                        {t(level)}
                      </Box>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          )}
        </Box>
      ),
    });
  }

  if (ALERT_CONFIGS[type].columns) {
    columns.push(...ALERT_CONFIGS[type].columns);
  }

  if (isFetching) {
    return (
      <>
        <div className={classes.header}>
          <TablePaginationSkeleton />
        </div>
        <TableContainer className={classes.table}>
          <TableSkeleton colCount={columns.length} rowCount={rowsPerPage} />
        </TableContainer>
      </>
    );
  }

  return (
    <TableContainer className={classes.table}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        pl: 1,
      }}
      >
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          sx={{ ml: 'auto' }}
          count={data.count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
        />
      </Box>
      <Table sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.id} align={column.align || 'center'} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.results?.length > 0 ? (
            data.results.map((row) => (
              <Row key={row.uuid} columns={columns} row={row} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length + 1} className={classes.noDataCell}>
                <Box height="50vh" display="flex" justifyContent="center" alignItems="center">
                  <NoData className={classes.noData} />
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const Row = (props) => {
  const { columns, row } = props;
  const { t } = useTranslation();
  const { medicalAct, account } = greyboxApiActions;
  const actSelector = medicalAct.get(row.linked_medical_act, { skip: !row.linked_medical_act });
  const hcp = account.get(row.hcp, { skip: !row.hcp });

  if (actSelector.isFetching || hcp.isFetching) return null;

  return (
    <TableRow>
      {columns.map((column, idx) => {
        if (column.id === 'medicalAct') {
          return (
            <TableCell key={idx} align={column.align || 'center'}>
              {t(actSelector.data?.medical_act_type_name)}
            </TableCell>
          );
        } if (column.id === 'hcp') {
          return (
            <TableCell key={idx} align={column.align || 'center'}>
              {hcp.data?.firstName}
              {' '}
              {hcp.data?.lastName}
            </TableCell>
          );
        }
        return (
          <TableCell key={idx} align={column.align || 'center'}>
            {column.format ? (
              column.format(row[column.id], row)
            ) : (
              row[column.id]
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default AlertTable;
