import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { greyboxApiActions } from '../../../../redux/api';
import { Medication, MedicationHistoryType } from '../../../../types';
import DialogTitle from '../../../DialogTitle';
import SearchMedication from '../SearchMedication';
import MedicationDetails from './MedicationDetails';

type Props = {
  open: boolean;
  selectedId?: string;
  search?: string;
  handleClose: (newMedication?: MedicationHistoryType | null) => void; // Adjusted the type to include newMedication
};

const MedicationModal = ({ open, handleClose, selectedId, search = '' }: Props) => {
  const [selected, setSelected] = useState<Medication | null>(null);
  const { t } = useTranslation();

  const handleQuit = (newMedication: MedicationHistoryType | null) => { // Updated the type for newMedication
    setSelected(null);
    handleClose(newMedication);
  };

  const { medication } = greyboxApiActions;
  const { data, isFetching, isError, refetch } = medication.get(selectedId, { skip: !selectedId });

  // Determine the title based on whether we're adding or editing
  const dialogTitle = selectedId ? t('Edit Medication') : t('Add Medication');

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        {!selected && !selectedId ? (
          <>
            <SearchMedication setSelected={setSelected} initialSearch={search} />
            <DialogActions>
              <Button onClick={it => handleQuit(null)}>{t('Cancel')}</Button>
            </DialogActions>
          </>
        ) : isFetching ? (
          <Skeleton variant="rectangular" height={200} />
        ) : isError ? (
          <>
            <Typography color="error">{t('Failed to load medication details.')}</Typography>
            <Button onClick={refetch} variant="contained" color="primary">
              {t('Retry')}
            </Button>
          </>
        ) : (
          <MedicationDetails
            searchedMed={selected}
            editSelection={selectedId ? data : null}
            handleQuit={handleQuit}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default MedicationModal;
