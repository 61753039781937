import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ManageRolesDialog = ({ open, onClose, roles, selectedRoles, onSave }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(selectedRoles);

  const handleToggle = (role) => {
    const currentIndex = selected.indexOf(role);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(role);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  const handleSave = () => {
    onSave(selected);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="manage-roles-dialog">
      <DialogTitle id="manage-roles-dialog">{t('Manage Role Access')}</DialogTitle>
      <DialogContent>
        <FormGroup>
          {roles.map((role) => (
            <FormControlLabel
              key={role.value}
              control={
                <Checkbox
                  checked={selected.indexOf(role.value) !== -1}
                  onChange={() => handleToggle(role.value)}
                />
              }
              label={t(role.label)} // Localize the role label
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('Cancel')}
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManageRolesDialog;
