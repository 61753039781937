import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Fab, MenuItem, TextField, IconButton } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlow, {
  addEdge,
  Background,
  Controls,
  ReactFlowProvider,
  useEdgesState,
  useNodesState
} from 'reactflow';
import 'reactflow/dist/style.css';
import { parseJsonToReactFlow, getLayoutedElements } from './utils';

const nodeTypes = [
  { value: 'input', label: 'Input' },
  { value: 'action', label: 'Action' },
  { value: 'compute', label: 'Compute' },
  { value: 'suspend', label: 'Suspend' },
  { value: 'endpoint', label: 'Endpoint' },
  { value: 'process', label: 'Process' },
];

const WorkflowEditor = ({ existingWorkflow }) => {
  const { t } = useTranslation();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [open, setOpen] = useState(false);
  const [editingNode, setEditingNode] = useState(null);
  const [nodeData, setNodeData] = useState({
    type: 'default',
    label: '',
    params: {},
    action: '',
    op: '',
    var1: '',
    var2: '',
    output: '',
    next: '',
    success: ''
  });

  useEffect(() => {
    if (existingWorkflow?.process) {
      const { nodes, edges } = parseJsonToReactFlow(existingWorkflow);
      setNodes(nodes);
      setEdges(edges);
    }
  }, [existingWorkflow]);

  const onLoad = useCallback((instance) => {
    setReactFlowInstance(instance);
    instance.fitView();
  }, []);

  const onConnect = useCallback(
    (connection) => {
      const sourceNode = nodes.find((node) => node.id === connection.source);
      const targetNode = nodes.find((node) => node.id === connection.target);

      if (
        ((sourceNode.data.type !== 'process' || connection.sourceHandle === undefined) && edges.some(edge => edge.source === connection.source && edge.sourceHandle === connection.sourceHandle))
      ) {
        return;
      }

      setEdges((eds) => addEdge({ ...connection, type: 'smoothstep', animated: true }, eds));
    },
    [nodes, edges]
  );

  const handleClickOpen = (node = null) => {
    if (node) {
      setNodeData({
        ...node.data,
        params: node.data.params || {},
        action: node.data.action || '',
        op: node.data.op || '',
        var1: node.data.var1 || '',
        var2: node.data.var2 || '',
      });
      setEditingNode(node.id);
    } else {
      setNodeData({
        type: 'action',
        label: '',
        params: {},
        action: '',
        op: '',
        var1: '',
        var2: '',
      });
      setEditingNode(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    if (editingNode) {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === editingNode ? { ...node, data: { ...nodeData } } : node
        )
      );
    } else {
      const newNode = {
        id: (nodes.length + 1).toString(),
        type: 'default',
        data: { ...nodeData },
        position: { x: Math.random() * 250, y: Math.random() * 250 },
      };
      setNodes((nds) => [...nds, newNode]);
    }
    handleClose();
  };

  const handleChange = (e) => {
    setNodeData({ ...nodeData, [e.target.name]: e.target.value });
  };

  const handleParamsChange = (key, value) => {
    setNodeData({
      ...nodeData,
      params: { ...nodeData.params, [key]: value },
    });
  };

  const addParam = () => {
    setNodeData({
      ...nodeData,
      params: { ...nodeData.params, [`param${Object.keys(nodeData.params).length + 1}`]: '' }
    });
  };

  const removeParam = (key) => {
    const newParams = { ...nodeData.params };
    delete newParams[key];
    setNodeData({
      ...nodeData,
      params: newParams
    });
  };

  const onNodeClick = (event, node) => {
    handleClickOpen(node);
  };

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        nodes,
        edges,
        direction
      );

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  return (
    <Container>
      <Box sx={{ height: '75vh', mt: 4, position: 'relative' }}>
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={onLoad}
            onNodeClick={onNodeClick}
            fitView
          >
            <Controls />
            <Background color="#aaa" gap={16} />
          </ReactFlow>
          <Fab
            color="primary"
            sx={{ position: 'absolute', top: 16, right: 16 }}
            onClick={() => handleClickOpen()}
          >
            <AddIcon />
          </Fab>
        </ReactFlowProvider>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingNode ? t('Edit Node') : t('Add Node')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="label"
            label={t('Label')}
            fullWidth
            variant="outlined"
            value={nodeData.label}
            onChange={handleChange}
          />
          <TextField
            select
            margin="dense"
            name="type"
            label={t('Type')}
            fullWidth
            variant="outlined"
            value={nodeData.type}
            onChange={handleChange}
          >
            {nodeTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
          {nodeData.type === 'action' && (
            <TextField
              margin="dense"
              name="action"
              label={t('Action')}
              fullWidth
              variant="outlined"
              value={nodeData.action}
              onChange={handleChange}
            />
          )}
          {nodeData.type === 'compute' && (
            <>
              <TextField
                margin="dense"
                name="op"
                label={t('Operation')}
                fullWidth
                variant="outlined"
                value={nodeData.op}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="var1"
                label={t('Variable 1')}
                fullWidth
                variant="outlined"
                value={nodeData.var1}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="var2"
                label={t('Variable 2')}
                fullWidth
                variant="outlined"
                value={nodeData.var2}
                onChange={handleChange}
              />
              <TextField
                margin="dense"
                name="output"
                label={t('Output Variable')}
                fullWidth
                variant="outlined"
                value={nodeData.output}
                onChange={handleChange}
              />
            </>
          )}
          {nodeData.type === 'suspend' && (
            <TextField
              margin="dense"
              name="scheduled"
              label={t('Scheduled')}
              fullWidth
              variant="outlined"
              value={nodeData.scheduled}
              onChange={handleChange}
            />
          )}
          {Object.keys(nodeData.params).map((param) => (
            <Box key={param} display="flex" alignItems="center" mb={1}>
              <TextField
                margin="dense"
                name="paramName"
                label={t('Param Name')}
                variant="outlined"
                // value={param}
                // onChange={(e) => {
                //   const newParams = { ...nodeData.params };
                //   const value = newParams[param];
                //   delete newParams[param];
                //   newParams[e.target.value] = value;
                //   setNodeData({ ...nodeData, params: newParams });
                // }}
                sx={{ mr: 1 }}
              />
              <TextField
                margin="dense"
                name="paramValue"
                label={t('Param Value')}
                variant="outlined"
                value={nodeData.params[param]}
                onChange={(e) => handleParamsChange(param, e.target.value)}
                sx={{ mr: 1 }}
              />
              <IconButton onClick={() => removeParam(param)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button onClick={addParam} color="primary" sx={{ mt: 2 }}>
            {t('Add Parameter')}
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleSave} color="primary">
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
      <Box sx={{ mt: 2 }}>
        <Button onClick={() => onLayout('TB')}>{t('Vertical Layout')}</Button>
        <Button onClick={() => onLayout('LR')}>{t('Horizontal Layout')}</Button>
      </Box>
    </Container>
  );
};

export default WorkflowEditor;
