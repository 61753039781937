import moment from 'moment';
import * as Yup from 'yup';

export const getInitialValues = (data, date, selectedType, isAllDay) => {
  let start_datetime = moment(date).minute(Math.round(moment().minute() / 30) * 30);
  let end_datetime = moment(start_datetime).add(30, 'minutes');

  if (data && data.start_datetime) {
    start_datetime = moment(data.start_datetime);
    end_datetime = moment(data.end_datetime);
  }

  return {
    name: data?.name || '',
    description: data?.description || '',
    all_day: data?.all_day === undefined ? isAllDay : data?.all_day,
    calendar: data?.calendar || '',
    start_datetime: start_datetime,
    end_datetime: end_datetime,
    join_url: data?.join_url || '',
    resourcetype: data?.resourcetype || selectedType.id,
    questionnaire: data?.questionnaire?.id || '',
    recurrences: data?.recurrences || '',
    reminder: data?.reminder ? moment.duration(data.reminder) : moment.duration('PT15M'),
    location: data?.location || '',
    response_reminder: data?.response_reminder ? moment.duration(data.response_reminder) : null
  };
};

export const getValidationSchema = (t) => (
  Yup.object({
    name: Yup.string().required(t('Required')),
    start_datetime: Yup.date().required(t('Required')),
    end_datetime: Yup.date().required(t('Required')).when('start_datetime', (start, schema) => (
      start && schema.min(start, t('End date must be after start date'))
    )),
    questionnaire: Yup.string().when('resourcetype', {
      is: 'QuestionnaireEvent',
      then: Yup.string().required(t('Required')),
      otherwise: Yup.string(),
    }),
    join_url: Yup.string().when('resourcetype', {
      is: 'OnlineMeetingEvent',
      then: Yup.string().required(t('Required')),
      otherwise: Yup.string(),
    }),
  })
);

export const handleSubmit = async (values, calendarSelector, clinic, eventId, refetchEvents, addEvent, updateEvent) => {
  const calendarKey = calendarSelector.data[0].id;
  const body = { ...values };

  body.calendar = calendarKey;
  if (body.questionnaire) {
    body.questionnaire = {
      id: values.questionnaire,
      clinic: clinic.id,
    };
  }

  if (body.resourcetype === 'Event') {
    delete body.calendar;
    delete body.resourcetype;
  }

  if (body.all_day) {
    body.start_datetime = moment(body.start_datetime).startOf('day');
    body.end_datetime = moment(body.end_datetime).endOf('day');
  }

  if (eventId) {
    await updateEvent({ id: eventId, body });
  } else {
    await addEvent({ id: `${calendarKey}/event`, body });
  }
  refetchEvents();
};
