import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Graph_Wrapper from './graphWrapper';
import { greyboxApiActions } from '../../../../redux/api';
import { usePatientWebSocket } from '../../../../hooks';
import { Box, Typography, Skeleton, Alert } from '@mui/material';

const TitrationSection = (props) => {
  const { uuid, titration_status_choices } = props;
  const { titrationStatus } = greyboxApiActions;
  const { data, isFetching, isError, refetch } = titrationStatus.list({ patient: uuid });
  const { t } = useTranslation();
  const { lastJsonMessage } = usePatientWebSocket();

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.entity === 'Titration_Status') {
        refetch();
      }
    }
  }, [lastJsonMessage]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5">
      {t('Titration progress overview')}
      </Typography>
      <Typography variant="subtitle2">
        {t('Here you can track the status of your medications that are currently under titration. This includes progress updates and target information.')}
      </Typography>

      {isFetching && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }}>
          {[...Array(2)].map((_, idx) => (
            <Skeleton key={idx} variant="rectangular" width={546} height={520} sx={{ m: 1 }} />
          ))}
        </Box>
      )}

      {isError && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {t('Failed to load titration status. Please try again.')}
        </Alert>
      )}

      {!isFetching && !isError && data.length === 0 && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          {t('No titration data available at the moment. Once a titration plan is added, it will appear here.')}
        </Typography>
      )}

      {!isFetching && !isError && data.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 2 }} data-cy="titration_wrapper">
          {data.map((titrationStatusInfo) => (
            <Graph_Wrapper
              key={titrationStatusInfo.uuid}
              titration_status_choices={titration_status_choices}
              titrationStatusInfo={titrationStatusInfo}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TitrationSection;
