import {
  Box, MenuItem
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikSelect from '../../../form-inputs/FormikSelect';
import FormikTextField from '../../../form-inputs/FormikTextField';
import { unitsOfTime } from './utils';


const MaxDosePerPeriod = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2, mb: 2 }}>
      <FormikTextField name="maxDosePeriod.maxDose" label={t('Max dose')} type="number" />
      <FormikTextField name="maxDosePeriod.maxDoseUnit" label={t('Max dose unit')} />
      <FormikTextField name="maxDosePeriod.period" label={t('Period value')} type="number" />
      <FormikSelect name="maxDosePeriod.periodUnit" label={t('Period unit')}>
        {Object.entries(unitsOfTime).map(([key, val]) => (
          <MenuItem key={key} value={key}>
            {val}
          </MenuItem>
        ))}
      </FormikSelect>
    </Box>
  );
};

export default MaxDosePerPeriod;
