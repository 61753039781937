import {
  Paper
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import moment from 'moment';
import { useTranslation } from 'react-i18next';



const CompletedQuestionnairesChart = ({ patientUuid }) => {
  const { startDate } = useSelector((state) => state.patientProfileDateRange);
  const { calendarEvent } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { t } = useTranslation();

  const [parsedData, setParsedData] = useState([]);
  const [xaxisCategories, setXaxisCategories] = useState([]);

  const { data = [] } = calendarEvent.list(
    {
      owner: patientUuid,
      start_datetime__gte: moment(startDate).toISOString(),
      clinic: clinic.id,
    },
  );


  const parseDataPerQuestionnaire = (data) => {
    const questionnaires = [{ name: t('Scheduled'), data: [] }, { name: t('Completed'), data: [] }];
    data.forEach((item) => {

      if (!item.questionnaire) {
        return;
      }
      
      const total = item.records?.records.length;
      const completed = (item.records?.percentage * total) / 100;
      const remaining = total - completed;

      setXaxisCategories((prev) => xaxisCategories.includes(item.questionnaire.name) ? prev : [...prev, item.questionnaire.name]);
  
      questionnaires[1].data.push(completed);
      questionnaires[0].data.push(remaining);
    });
  
    return questionnaires;
  };

  useEffect(() => {
    if (data?.length) {
      setParsedData(parseDataPerQuestionnaire(data));
    }
  }, [data]);

  const chartOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      title: {
        text: 'Number of Questionnaires',
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        }
      }
    },
    xaxis: {
      categories: xaxisCategories,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toFixed(0) + (val > 1 ? ' questionnaires' : ' questionnaire');
        }
      }
    },
  };

  return (
    <Paper sx={{ p: 2 }}>
      <ReactApexChart
        options={chartOptions}
        series={parsedData}
        type="bar"
        height={350}
      />
    </Paper>
  );
};

export default CompletedQuestionnairesChart;
