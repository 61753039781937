import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { MuiFileInput } from 'mui-file-input';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoImage } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import ErrorMessage from '../form-inputs/ErrorMessage';
import FormikTextField from '../form-inputs/FormikTextField';

const MAX_FILE_SIZE = 1024 * 1024; // 1MB
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

const ClinicInformation = () => {
  const { t } = useTranslation();
  const { company } = greyboxApiActions;
  const { clinic } = useSelector((state) => state.clinic);
  const { data, isFetching, isError, refetch } = company.get(clinic.id);
  const [updateClinic] = company.update();
  const [errorMessage, setErrorMessage] = useState('');
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    setLogoUrl(data?.logo);
  }, [data?.logo]);


  return (
    <Paper sx={{ p: 1, mb: 1 }}>
      {isFetching ? (
        <>
          <Typography variant="h5" sx={{ m: 1 }}>
            <Skeleton width="50%" />
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: 2,
              p: 2,
            }}
          >
            {[1, 2, 3, 4].map((index) => (
              <Skeleton key={index} variant="rectangular" height={56} />
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
            <Skeleton variant="rectangular" width={100} height={36} sx={{ mr: 1 }} />
            <Skeleton variant="rectangular" width={100} height={36} />
          </Box>
        </>
      ) : isError ? (
        <Alert severity="error">
          <AlertTitle>{t('Error')}</AlertTitle>
          {t('Failed to load clinic information.')}
          <Button onClick={refetch}>{t('Retry')}</Button>
        </Alert>
      ) : (
        <>
          <Typography variant="h5" sx={{ m: 1 }}>
            {t('Edit Clinic Information')}
          </Typography>
          <Formik
            initialValues={{
              name: data?.name || '',
              address: data?.address || '',
              phone_num: data?.phone_num || '',
              email: data?.email || '',
              logo: data?.logo,
            }}
            enableReinitialize
            validationSchema={Yup.object({
              name: Yup.string()
                .required(t('required'))
                .min(2, t('Name must be at least 2 characters')),
              address: Yup.string(),
              phone_num: Yup.string()
                .required(t('required'))
                .matches(
                  /^[+]?[(]?[0-9]{1,4}[)]?[-\s.]?[0-9]{1,4}[-\s.]?[0-9]{1,9}(?: *(?:x|ext)\.? *[0-9]+)?$/im,
                  t('Invalid phone number')
                ),
              email: Yup.string()
                .email(t('Invalid email'))
                .required(t('required')),
              logo: Yup.mixed()
                .test('fileSize', t('File too large'), (value) =>
                  value ? value.size <= MAX_FILE_SIZE : true
                )
                .test('fileFormat', t('Unsupported Format'), (value) =>
                  value ? SUPPORTED_FORMATS.includes(value.type) : true
                ),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              setErrorMessage('');
              let formData = values;
              if (values.logo) {
                formData = new FormData();
                formData.append('name', values.name);
                formData.append('address', values.address);
                formData.append('phone_num', values.phone_num);
                formData.append('email', values.email);
                formData.append('logo', values.logo);
              }

              updateClinic({ id: clinic.id, body: formData })
                .unwrap()
                .then(() => {
                  setSubmitting(false);
                  // Optionally refetch data or update state
                })
                .catch((error) => {
                  setSubmitting(false);
                  const errors = error.data || {};
                  if (errors.error) {
                    setErrorMessage(errors.error);
                  }
                  setErrors(errors);
                });
            }}
          >
            {({ resetForm, dirty, isSubmitting, setFieldValue }) => (
              <Form>
                <Box
                  sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    gap: 2,
                    p: 2,
                  }}
                >
                  {/* Display Existing Logo */}
                  {logoUrl && (
                    <Box
                      sx={{ gridColumn: 'span 2' }}
                    >
                      <img
                        src={logoUrl}
                        alt="Clinic Logo"
                        style={{ maxHeight: 100 }}
                      />
                    </Box>
                  )}
                  <FormikTextField name="name" label={t('Name')} required />
                  <FormikTextField name="address" label={t('Address')} />
                  <FormikTextField
                    name="phone_num"
                    label={t('Phone')}
                    type="tel"
                    required
                  />
                  <FormikTextField
                    name="email"
                    label={t('Email')}
                    type="email"
                    required
                  />

                  {/* Logo Upload Field */}
                  <Field name="logo">
                    {({ field, meta }) => (
                      <MuiFileInput
                        {...field}
                        label={t('Clinic logo')}
                        getInputText={(value) => {
                          if (value instanceof File) {
                            return value.name;
                          } else if (typeof value === 'string' && value.startsWith('http')) {
                            try {
                              const url = new URL(value); // Create a URL object
                              const fileName = url.pathname.split('/').pop(); // Get the last part of the path without query params
                              return fileName.split('?')[0]; // Remove any query params, just in case
                            } catch (error) {
                              // Handle invalid URL or fallback in case of error
                              const fallbackUrlSegments = value.split('/');
                              return fallbackUrlSegments[fallbackUrlSegments.length - 1].split('?')[0]; // Remove query params in fallback
                            }
                          }
                          return '';
                        }}
                        placeholder={t('Choose a file to upload')}
                        onChange={(file) => {
                          setFieldValue('logo', file);
                          if (file) {
                            setLogoUrl(URL.createObjectURL(file));
                          }
                          else {
                            setLogoUrl(null);
                          }
                        }}
                        InputProps={{
                          inputProps: {
                            accept: 'image/*'
                          },
                          startAdornment: <IoImage />
                        }}
                        clearIconButtonProps={{
                          title: t("Remove"),
                          children: <CloseIcon fontSize="small" />
                        }}
                        helperText={meta.touched && meta.error}
                        error={meta.touched && Boolean(meta.error)}
                      />
                    )}
                  </Field>
                </Box>

                {/* Display Error Message */}
                {errorMessage && <ErrorMessage message={errorMessage} />}

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="secondary"
                    sx={{ mr: 1 }}
                    onClick={resetForm}
                    disabled={!dirty || isSubmitting}
                  >
                    {t('Reset')}
                  </Button>
                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t('Save')}
                  </LoadingButton>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Paper>
  );
};

export default ClinicInformation;
