import { Navigate, Route, Routes } from "react-router-dom";
import WorkflowManager from "./WorkflowManager";
import WorkflowForm from "./WorkflowForm";

const WorkflowRoutes = () => {

    return (
        <Routes>
            <Route index element={<WorkflowManager />} />
            <Route path="add" element={<WorkflowForm />} />
            <Route path="edit/:id" element={<WorkflowForm />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default WorkflowRoutes;