import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import LinkIcon from '@mui/icons-material/Link';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Divider
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { styled } from '@mui/system';

const TYPE_ICON = {
  pdf: <PictureAsPdfIcon />,
  image: <InsertPhotoIcon />,
  video: <OndemandVideoIcon />,
  url: <LinkIcon />,
  audio: <VolumeUpIcon />,
};

// Styled component to handle markdown content with MUI
const MarkdownTypography = styled(Typography)(({ theme }) => ({
  '& h1': {
    ...theme.typography.h5,
    marginBottom: theme.spacing(2),
  },
  '& h2': {
    ...theme.typography.h6,
    marginBottom: theme.spacing(2),
  },
  '& p': {
    ...theme.typography.body1,
    marginBottom: theme.spacing(1),
  },
  '& a': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const EducationSection = ({ section }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 4, px: { xs: 2, md: 4 } }}>
      {/* Section Title */}
      <Typography variant="h4" component="h2" sx={{ mb: 2, fontSize: { xs: '1.5rem', md: '2rem' } }}>
        {section.title}
      </Typography>
      
      {/* Section Description (Markdown) */}
      <MarkdownTypography variant="body1" sx={{ mb: 3, color: 'text.secondary' }}>
        <ReactMarkdown>
          {section.description}
        </ReactMarkdown>
      </MarkdownTypography>
      
      <Divider sx={{ mb: 3 }} />

      <Grid container rowSpacing={3} columnSpacing={2} justifyContent="flex-start">
        {section.articles.map((article) => (
          <Grid 
            item 
            key={article.uuid} 
            xs={12} sm={6} md={4} // Set breakpoints for responsive layout
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', // Center the card within the grid item
              width: '100%', 
              mb: 2 
            }}
          >
            <Paper
              onClick={() => window.open(article.url, '_blank')}
              sx={{
                p: { xs: 2, sm: 3 },
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                width: '100%', // Full width within the grid item
                maxWidth: '400px', // Optional: Set a max width for large screens
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
                transition: 'background-color 0.3s ease',
                overflow: 'hidden',
              }}
            >
              <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                <Typography sx={{ mr: 1, fontWeight: 'bold', fontSize: { xs: '1rem', md: '1.25rem' } }}>
                  {article.title}
                </Typography>
                {TYPE_ICON[article.type]}
              </Box>
              <MarkdownTypography variant="body2" component="div" sx={{ fontSize: { xs: '0.875rem', md: '1rem' } }}>
                <ReactMarkdown>
                  {article.description}
                </ReactMarkdown>
              </MarkdownTypography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EducationSection;
