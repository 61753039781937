import { formatBirthDateForPost } from '../components/form-inputs/BirthDate';

type Staff = {
  id: string;
  label: string;
};
export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  mobile: null | string;
  language: string;
  timezone: string;
  weight_unit: string;
  temperature_unit: string;
}

interface DiagnosisDetail {
  uuid: string;
  diagnosis_key: string;
  diagnosis_name: string;
  diagnosis_name_en: string;
  diagnosis_name_fr: string;
  diagnosis_name_es: null | string;
  diagnosis_name_de: null | string;
  diagnosis_name_it: null | string;
  diagnosis_name_pt: null | string;
  diagnosis_category: string;
  code: null | string;
}

interface AppConfig {
  features: string[];
}

export type Telecom = {
  system?: string;
  value?: string;
  use?: string;
  rank?: number;
};

type Hin = {
  hin_number: string;
  hin_exp?: string;
};

export interface AccountType {
  uuid: string;
  phoneNumber: null | string;
  email: string;
  firstName: string;
  lastName: string;
  language: string;
  birthDate: null | string;
  staffs: Staff[];
  staffsName: string[];
  hin: null | Hin;
  diagnosis: string[];
  diagnosisDetails: DiagnosisDetail[];
  role_type_code: string;
  company: number[];
  user: User;
  gender: string;
  telecom: Telecom[];
  countryRegion: string;
  invitations: any[];
  extension: any[];
  labels: string[];
  deleted_at: null | string;
  ethnic_group: string;
  zipCode: string;
  diagnosis_annotation: null | string;
  acc_type: string;
  acceptedTerms: boolean;
  displayTerms: boolean;
  app_version: null | string;
  mailOptIn: boolean;
  lastActive: string;
  createdDate: string;
  _lastUpdated: string;
  _versionId: number;
  role_type: string;
  disclaimer_version: null | string;
  secondary_diagnosis: string[];
}

export interface AccountCreationType {
  firstName: string;
  lastName: string;
  email: string | null;
  birthDate: string | null;
  phoneNumber: string | null;
  zipCode: string;
  hin_number: string | null;
  hin_exp: string | null;
  gender: string;
  language: string;
  ethnic_group: string;
  labels: string[];
  primaryDiagnosis: string[];
  secondaryDiagnosis: string[];
  staffs: Staff[];
  pharmacy: string;
  clinic: string;
}

export const AccountCreationToAccount = (obj: AccountCreationType): Partial<AccountType> => {
  const account: Partial<AccountType> = {};
  Object.entries(obj).map(([key, value]) => {
    if (!value) {
      return;
    }

    switch (key) {
      case 'staffs':
        account.staffs = value.map((staff: Staff) => staff.id);
        break;
      case 'labels':
        account[key] = value.map((d) => d.id);
        break;
      case 'primaryDiagnosis':
        account[key] = value.map((d) => d.key);
        break;
      case 'secondaryDiagnosis':
        account[key] = value.map((d) => d.id);
        break;
      case 'hin_number':
        account.hin = {
          hin_number: obj.hin_number,
          hin_exp: obj.hin_exp,
        };        
        break;
      case 'pharmacy':
        account.extension = [{
          url: 'https://ca.takecareapi.com/fhir/StructureDefinition/patient-preferred-pharmacy',
          valueId: value,
        }];
        break;
      case 'birthDate':
        account.birthDate = formatBirthDateForPost(value);
        break;
      case 'clinic':
        account.company = [value];
        break;
      default:
        account[key] = value;
    }
  });

  return account;
};
