import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

const FormikAutocomplete = ({ label, name, options, ...props }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue, setTouched } = helpers;

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={options}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.label}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          {...props}
        />
      )}
      value={
        field.value
          ? options.filter((option) => field.value.includes(option.id))
          : []
      }
      onChange={(_, value) => {
        const selectedValues = value.map((val) => val.id);
        setValue(selectedValues);
      }}
      onBlur={() => setTouched(true)}
      {...props}
    />
  );
};

export default FormikAutocomplete;
