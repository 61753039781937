import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { greyboxApiActions } from '../../../../redux/api';
import DialogTitle from '../../../DialogTitle';

const DeleteMedicationModal = ({ open, handleClose, selectedMedications = [] }) => {
  const { t } = useTranslation();
  const { medication } = greyboxApiActions;
  const [deleteMedication, result] = medication.update();

  const handleDelete = async () => {
    await Promise.all(
      selectedMedications.map((med) =>
        deleteMedication({ id: med.uuid, body: { status: 'entered-in-error' } })
      )
    );
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>{t('Delete Medications')}</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{ borderBottom: '1px solid', borderColor: 'divider', pb: 1 }}
        >
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary">
              {t('Medication')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="textSecondary" align="right">
              {t('Start')}
            </Typography>
          </Grid>
        </Grid>
        {selectedMedications.map((medInfo) => (
          <Grid
            container
            spacing={2}
            key={medInfo.uuid}
            sx={{ borderBottom: '1px solid', borderColor: 'divider', py: 1 }}
          >
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
                {medInfo.name.toLowerCase()}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" align="right">
                {medInfo.startDate}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={result.isLoading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={handleDelete}
          color="primary"
          variant="contained"
          loading={result.isLoading}
        >
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteMedicationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedMedications: PropTypes.arrayOf(PropTypes.object),
};

export default DeleteMedicationModal;
