import React from 'react';
import RichTextEditor from '../RichTextEditor';
import { FormHelperText, FormControl, InputLabel } from '@mui/material';

type PropsType = {
  formik: any;
  name: string;
  label: string;
  readOnly?: boolean;
};

const RichFormText = (props: PropsType) => {
  const {
    formik, name, label, readOnly = false,
  } = props;

  return (
    <FormControl 
      fullWidth 
      error={formik.touched[name] && Boolean(formik.errors[name])} 
      margin="normal"
    >
      <RichTextEditor
        field={formik.getFieldProps(name)}
        form={formik}
        placeholder={label}
        readOnly={readOnly}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormHelperText>{formik.errors[name]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default RichFormText;
