import { useMsal } from '@azure/msal-react';
import {
  LinearProgress,
} from '@mui/material';
import React from 'react';
import AppBar from '../components/AppBar';
import InformationMessageBar from '../components/InformationMessageBar';
import { useIsTakecareAuthenticated } from '../hooks';
import AuthenticatedPages from './AuthenticatedPages';
import { useSelector } from 'react-redux';
import UnauthenticatedPages from './UnauthenticatedPages';

const PageSelector = () => {
  const isAuthenticated = useIsTakecareAuthenticated();
  const { accounts } = useMsal();
  const [loading, setIsLoading] = React.useState(false);
  const { pageLoader } = useSelector((state) => state);

  // The user is logged in but msal is still fetching
  if ((!isAuthenticated && accounts.length > 0)) {
    return (
      <LinearProgress />
    );
  }

  if (isAuthenticated) {
    return (
      <>
        {(loading || pageLoader.isLoading) && <LinearProgress />}
        <AppBar>
          <InformationMessageBar />
          <AuthenticatedPages setIsLoading={setIsLoading} />
        </AppBar>
      </>
    )
  }
    
  return <UnauthenticatedPages />;

};

export default PageSelector;
