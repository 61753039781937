import {
  Card,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TakecareTextLogo from '../../img/takecare-text-logo_svg';
import EmailConfirmation from '../components/invitations/EmailConfirmation';
import TokenValidation from '../components/invitations/TokenValidation';
import Layout from '../components/Layout';
import { emptyObject } from '../helper-functions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(3),
    width: '60%',
  },
  btn: {
    width: '100%',
    height: '46px',
  },
}));

const Invitation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [enableToken, setEnableToken] = useState(false);
  const [emailValidation, setEmailValidation] = useState(null);

  useEffect(() => {
    if (!emptyObject(emailValidation)) {
      setEnableToken(true);
    }
  }, [emailValidation]);

  return (
    <Layout width="sm">
      <Card className={classes.root}>
        <TakecareTextLogo style={{
          width: '180px',
          height: '23px',
          marginBottom: '2vh',
          objectFit: 'contain',
        }}
        />
        <div>
          {enableToken
            ? (
              <Typography>
                {`${t('Please enter the code sent to your email address')}.`}
              </Typography>
            )
            : (
              <Typography>
                {`${t('Please enter your email address to receive an invitation code')}.`}
              </Typography>
            )}
        </div>
        <div className={classes.content}>
          {enableToken
            ? <TokenValidation email={emailValidation} />
            : <EmailConfirmation setEmail={setEmailValidation} />}
        </div>
      </Card>
    </Layout>
  );
};

export default Invitation;
