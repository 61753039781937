// src/components/form-inputs/FormikPhoneField.js

import React from 'react';
import { useField, useFormikContext } from 'formik';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';

const FormikPhoneField = ({ name, label, required, ...props }) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  // Function to format the value for display (remove '+1')
  const formatDisplayValue = (value) => {
    if (!value) return '';
    return value.replace(/^\+1/, '');
  };

  // Function to format the value for storage (add '+1')
  const formatStoredValue = (value) => {
    let numericValue = value.replace(/\D/g, ''); // Remove non-digit characters
    if (!numericValue.startsWith('1')) {
      numericValue = '1' + numericValue;
    }
    return '+' + numericValue;
  };

  const handleChange = (event) => {
    const displayValue = event.target.value;
    const storedValue = formatStoredValue(displayValue);
    setFieldValue(name, storedValue);
  };

  return (
    <InputMask
      mask="(999) 999-9999"
      value={formatDisplayValue(field.value)}
      onChange={handleChange}
      onBlur={field.onBlur}
      name={name}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          label={label}
          required={required}
          error={Boolean(meta.touched && meta.error)}
          helperText={meta.touched && meta.error ? meta.error : ''}
          {...props}
        />
      )}
    </InputMask>
  );
};

export default FormikPhoneField;
