import {
  Box,
  Card, CardContent, CardHeader,
  Skeleton
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../redux/api';
import EducationSection from './EducationSection';


const EducationPanel = ({ readOnly = false }) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { educationSection } = greyboxApiActions;
  const {
    data, isFetching, isError, refetch,
  } = educationSection.list({ clinic: clinic.id });

  if (isFetching) {
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={t('Education documents')}
          subheader={t('Consult education documents')}
        />
        <CardContent>
          <Box sx={{
            p: 2,
            mb: 1,
            height: '300px',
            borderRadius: 2,
            border: (theme) => (`1px solid ${theme.palette.divider}`),
          }}
          >
            <Skeleton variant="rectangular" width="30%" height="10px" />
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={t('Education documents')}
          subheader={t('Consult education documents')}
        />
        <CardContent>
          error
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={t('Education documents')}
        subheader={t('Consult education documents')}
      />
      <CardContent>
        {data.map((section, index) => (
          <EducationCard
            key={section.uuid}
            section={section}
            idx={index}
          />
        ))}
      </CardContent>
    </Card>
  );
};

const EducationCard = (props) => {
  const {
    section, sx = null
  } = props;

  if (section.articles.length === 0) return null;

  return (
    <Box sx={{ p: 2, mb: 1, ...sx }} >
      <EducationSection section={section} />
    </Box>
  );
};

export default EducationPanel;
