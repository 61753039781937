import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PersonIcon from '@mui/icons-material/Person';
import { Button, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogTitle from '../DialogTitle';
import PatientInvitation from './PatientInvitation';
import PractitionerInvitation from './PractitionerInvitation';

const InvitationCreation = ({ open, handleClose }) => {
  const [type, setType] = React.useState(null);
  const { t } = useTranslation();

  const closeAndReset = () => {
    setType(null);
    handleClose();
  };

  if (type === 'P') {
    return <PatientInvitation open={open} handleClose={closeAndReset} />;
  }

  if (type === 'CP') {
    return <PractitionerInvitation open={open} handleClose={closeAndReset} />;
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Select an invitation type')}
      </DialogTitle>
      <DialogContent sx={{ flexDirection: 'column' }}>
        <Button
          onClick={() => setType('P')}
          startIcon={<PersonIcon sx={{ transform: 'scale(2.3) translateX(2px)', mb: 1 }} />}
          value="P"
          sx={{
            mb: 1, width: '50%', height: '100px', flexDirection: 'column', alignItems: 'center',
          }}
        >
          {t('Patient')}
        </Button>
        <Button
          onClick={() => setType('CP')}
          startIcon={<HealthAndSafetyIcon sx={{ transform: 'scale(2.3) translateX(2px)', mb: 1.5 }} />}
          value="CP"
          sx={{
            mb: 1, width: '50%', height: '100px', flexDirection: 'column', alignItems: 'center',
          }}
        >
          {t('Practitioner')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default InvitationCreation;
