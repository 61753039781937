import AddIcon from '@mui/icons-material/Add';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SecurityIcon from '@mui/icons-material/Security';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { languages } from '../../../helpers/languagesConfig';
import { greyboxApiActions } from '../../../redux/api';
import FormTextField from '../../form-inputs/FormTextField';
import RichTextEditor from '../../RichTextEditor';
import AddDocument from './AddDocument';
import EducationSection from './EducationSection';
import ManageRolesDialog from './ManageRolesDialog';

const SectionDialog = (props) => {
  const { open, onClose, sectionId } = props;
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { educationSectionManagement } = greyboxApiActions;
  const [addSection] = educationSectionManagement.add();
  const [updateSection] = educationSectionManagement.put();
  const {
    data, isFetching, isError, refetch,
  } = educationSectionManagement.get(sectionId, { skip: !sectionId });

  const [tabIndex, setTabIndex] = React.useState(0);

  const formik = useFormik({
    initialValues: languages.reduce((values, lang) => ({
      ...values,
      [`title_${lang.code}`]: data?.[`title_${lang.code}`] || '',
      [`description_${lang.code}`]: data?.[`description_${lang.code}`] || '',
    }), {}),
    enableReinitialize: true,
    validationSchema: Yup.object(
      languages.reduce((schema, lang) => ({
        ...schema,
        [`title_${lang.code}`]: lang.code === 'en' ? Yup.string().required(t('Required')) : Yup.string(),
        [`description_${lang.code}`]: Yup.string(),
      }), {})
    ),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      values['clinic_owner'] = clinic.id;
      try {
        if (sectionId) {
          await updateSection({ id: sectionId, body: values });
        } else {
          await addSection({ body: values });
        }
        onClose();
      } finally {
        setSubmitting(false);
      }
    },
  });

  // Effect to set the tab to the first error encountered
  useEffect(() => {
    const firstErrorKey = Object.keys(formik.errors)[0];
    if (firstErrorKey) {
      const firstErrorLang = firstErrorKey.split('_')[1]; // Extract the language code from the field name
      const errorTabIndex = languages.findIndex(lang => lang.code === firstErrorLang);
      setTabIndex(errorTabIndex);
    }
  }, [formik.errors]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  if (isFetching) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Skeleton width="60%" />
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: 400, overflowY: 'auto', padding: '24px' }}>
          <Skeleton variant="rectangular" width="100%" height={30} sx={{ marginBottom: '16px' }} />
          <Skeleton variant="rectangular" width="100%" height={150} />
        </DialogContent>
        <DialogActions>
          <Skeleton width="30%" />
        </DialogActions>
      </Dialog>
    );
  }

  if (isError) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography variant="h6" color="error">{t('Error')}</Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ maxHeight: 400, overflowY: 'auto', padding: '24px' }}>
          <Typography variant="body1" color="error">{t('Failed to load section data')}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} sx={{ marginRight: '8px' }}>{t('Close')}</Button>
          <Button onClick={refetch} variant="contained">{t('Retry')}</Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>
        <Box display="flex" flexDirection="column">
          {sectionId ? t('Edit section') : t('Add section')}
          <Typography variant="caption" color="textSecondary">
            {t('Enter at least one language')}
          </Typography>
        </Box>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ maxHeight: 400, overflowY: 'auto', padding: '24px' }}>
          <Tabs value={tabIndex} onChange={handleTabChange} variant="scrollable" scrollButtons="auto" sx={{ marginBottom: '16px' }}>
            {languages.map((lang) => (
              <Tab key={lang.code} label={t(lang.labelKey)} sx={{ minWidth: '100px' }} />
            ))}
          </Tabs>
          {languages.map((lang, index) => (
            <Box key={lang.code} hidden={tabIndex !== index} sx={{ display: tabIndex === index ? 'block' : 'none', mt: 2 }}>
              <FormTextField
                formik={formik}
                name={`title_${lang.code}`}
                label={`${t('Title')}`}
                fullWidth
                sx={{ marginBottom: '16px' }}
              />
              <RichTextEditor
                field={formik.getFieldProps(`description_${lang.code}`)}
                form={formik}
                placeholder={t('Enter description')}
              />
            </Box>
          ))}
        </DialogContent>
        <DialogActions sx={{ padding: '16px 24px' }}>
          <Button onClick={onClose} sx={{ marginRight: '8px' }}>{t('Cancel')}</Button>
          <LoadingButton
            data-cy="section-submit"
            type="submit"
            loading={formik.isSubmitting}
            variant="contained"
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};



const EducationPanel = ({ patientUuid = null, readOnly = false }) => {
  const { t } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { educationSectionManagementClinic } = greyboxApiActions;
  const {
    data, isFetching, isError, refetch,
  } = educationSectionManagementClinic.list({ clinic: clinic.id });
  const [selectedSection, setSelectedSection] = React.useState(null);
  const [addSectionOpen, setAddSectionOpen] = React.useState(false);
  const [removeSection] = educationSectionManagementClinic.delete();
  const [updateSection] = educationSectionManagementClinic.update();
  const [addDocumentOpen, setAddDocumentOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [sectionToDelete, setSectionToDelete] = React.useState(null);

  const moveSection = async (index, direction) => {
    const newIndex = index + direction;
    if (newIndex < 0 || newIndex >= data.length) return;

    const temp = data[index];

    // Update the order in the backend
    try {
      await updateSection({ id: temp.id, body: { order: newIndex + 1 } });
    } catch (error) {
      console.error('Error updating order:', error);
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await removeSection(sectionToDelete);
      setConfirmDeleteOpen(false);
      setSectionToDelete(null);
    } catch (error) {
      console.error('Error deleting section:', error);
    }
  };

  if (isFetching) {
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={t('Education documents')}
          subheader={t('Add, edit and delete education documents and their sections')}
        />
        <CardContent>
          <Box sx={{
            p: 2,
            mb: 1,
            height: '300px',
            borderRadius: 2,
            border: (theme) => (`1px solid ${theme.palette.divider}`),
          }}
          >
            <Skeleton variant="rectangular" width="30%" height="10px" />
          </Box>
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    return (
      <Card sx={{ width: '100%' }}>
        <CardHeader
          title={t('Education documents')}
          subheader={t('Add, edit and delete education documents and their sections')}
        />
        <CardContent>
          <Box sx={{ p: 2, mb: 1, textAlign: 'center' }}>
            <Typography color="error" variant="body1">
              {t('Failed to load education documents. Please try again later.')}
            </Typography>
            <Button variant="outlined" onClick={refetch} sx={{ mt: 2 }}>
              {t('Retry')}
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }

  const handleMenuClick = async (action, section_clinic) => {
    switch (action) {
      case 'edit':
        setSelectedSection(section_clinic.section);
        setAddSectionOpen(true);
        break;
      case 'delete':
        setSectionToDelete(section_clinic.id);
        setConfirmDeleteOpen(true);
        break;
      case 'addDocument':
        setSelectedSection(section_clinic.section);
        setAddDocumentOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={t('Education documents')}
        subheader={t('Add, edit and delete education documents and their sections')}
      />
      <Button
        onClick={() => setAddSectionOpen(true)}
        data-cy="add-section"
        variant="outlined"
        sx={{ ml: 2 }}
        startIcon={<AddIcon />}
      >
        {t('Add section')}
      </Button>
      {addSectionOpen && (
        <SectionDialog
          open={addSectionOpen}
          onClose={() => {
            setAddSectionOpen(false);
            setSelectedSection(null);
          }}
          sectionId={selectedSection}
        />
      )}
      <CardContent>
        {data.map((section_clinic, index) => (
          <EducationCard
            key={section_clinic.id}
            section_clinic={section_clinic}
            idx={index}
            handleMenuClick={handleMenuClick}
            moveSection={moveSection}
            readOnly={readOnly}
            isFirst={index === 0}
            isLast={index === data.length - 1}
          />
        ))}
        {addDocumentOpen && (
          <AddDocument
            open={addDocumentOpen}
            handleClose={() => {
              setAddDocumentOpen(false);
              setSelectedSection(null);
            }}
            section={selectedSection}
          />
        )}
      </CardContent>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => setConfirmDeleteOpen(false)}
        aria-labelledby="confirm-delete-title"
        aria-describedby="confirm-delete-description"
      >
        <DialogTitle id="confirm-delete-title">
          {t('Confirm Delete')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-description">
            {t('Are you sure you want to delete this section? This action cannot be undone.')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            {t('Cancel')}
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" autoFocus>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

const roleItems = [
  { value: 'P', label: 'Patient' },
  { value: 'PT', label: 'Practitioner' },
  { value: 'CP', label: 'Caregiver' },
];

const EducationCard = (props) => {
  const { t } = useTranslation();
  const {
    section_clinic, handleMenuClick, sx, idx, moveSection, readOnly,
    isFirst, isLast,
  } = props;

  const { educationSectionManagementClinic } = greyboxApiActions;
  const [updateSection] = educationSectionManagementClinic.update();
  const [isDialogOpen, setDialogOpen] = useState(false);

  // Check if the delete permission exists
  const hasDeletePermission = section_clinic.permissions.includes('delete_education_library_section_clinics_management');
  const hasChangePermission = section_clinic.permissions.includes('change_education_library_section_clinics_management');
  const hasChangeSectionPermission = section_clinic.section_permissions.includes('change_education_library_section_management');
  

  const [selectedRoles, setSelectedRoles] = useState(section_clinic.role_acc_type || []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveRoles = async (newRoles) => {
    setSelectedRoles(newRoles);
    await updateSection({ id: section_clinic.id, body: { role_acc_type: newRoles } })
  };

  return (
    <>
      <Card sx={{ p: 2, mb: 2, ...sx }}>
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>{section_clinic.title}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
              <IconButton
                onClick={() => moveSection(idx, -1)}
                disabled={isFirst || !hasChangePermission}
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => moveSection(idx, 1)}
                disabled={isLast || !hasChangePermission}
              >
                <ArrowDownwardIcon />
              </IconButton>
              <IconButton
                onClick={() => handleMenuClick('edit', section_clinic)}
                disabled={!hasChangeSectionPermission}
                sx={{ ml: 1 }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleOpenDialog}
                sx={{ ml: 1 }}
                disabled={!hasChangePermission} // Disable if no permission
              >
                <SecurityIcon />
              </IconButton>
              <IconButton
                onClick={() => handleMenuClick('delete', section_clinic)}
                sx={{ ml: 1 }}
                disabled={!hasDeletePermission}  // Disable if no permission
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Button
            onClick={() => handleMenuClick('addDocument', section_clinic)}
            variant="outlined"
            data-cy={`add-document-${idx}`}
            disabled={!hasChangeSectionPermission}
            size="small"
            sx={{ mt: 1 }}
            startIcon={<AddIcon />}
          >
            {t('Add document')}
          </Button>
          <EducationSection sectionId={section_clinic.section} hasChangePermission={hasChangeSectionPermission}/>
        </CardContent>
      </Card>
      {/* Manage Roles Dialog */}
      <ManageRolesDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        roles={roleItems} // Localize the role names
        selectedRoles={selectedRoles}
        onSave={handleSaveRoles}
      />
    </>
  );
};



export default EducationPanel;
