import LoadingButton from '@mui/lab/LoadingButton';
import { Button, CardActions, CardContent, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../../redux/api';
import { parseThreshold } from '../../form-inputs/Threshold';
import {
  Thresholds,
  parseThresholdsForPost,
  thresholdValidations,
  thresholdsInitValue,
} from '../../form-inputs/Thresholds';

const ThresholdTab = () => {
  const { t } = useTranslation();
  const { uuid } = useParams();
  const {
    threshold,
  } = greyboxApiActions;
  const patientThreshold = threshold.list({ latest: true, localAccount: uuid });
  const { vitalsConfig, thresholds } = useSelector((state) => state.clinic);
  const [createThreshold] = threshold.add();

  const thresholdForm = useFormik({
    initialValues: thresholdsInitValue(
      vitalsConfig, parseThreshold(patientThreshold.data, vitalsConfig),
    ),
    enableReinitialize: true,
    validationSchema: thresholdValidations(Object.keys(vitalsConfig)),
    onSubmit: (values) => {
      thresholdForm.setSubmitting(true);
      const body = parseThresholdsForPost(values, vitalsConfig);

      body.forEach((b) => {
        b.localAccount = uuid;
      });

      createThreshold({ body, feedback: { success: t('Thresholds updated') } }).unwrap()
        .then((res) => {
          thresholdForm.setSubmitting(false);
        }).catch((error) => {
          thresholdForm.setSubmitting(false);
          if (error.status === 400 && error.data) {
            const errors = error.data;
            thresholdForm.setErrors(errors);
          }
        });
    },
  });

  const handleResetToDefault = () => {
    thresholdForm.setValues(thresholdsInitValue(
      vitalsConfig, thresholds
    ));
  };

  return (
    <>
      <Typography variant="h5">
        {t('Thresholds Adjustment')}
      </Typography>
      <form onSubmit={thresholdForm.handleSubmit}>
        <CardContent>
          <Thresholds formik={thresholdForm} isLoading={patientThreshold.isFetching} />
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={handleResetToDefault}
          >
            {t('Reset to Default Values')}
          </Button>
          <Button
            variant="contained"
            onClick={() => thresholdForm.resetForm()}
            disabled={!thresholdForm.dirty}
          >
            {t('Reset')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={!thresholdForm.dirty}
            loading={thresholdForm.isSubmitting || patientThreshold.isFetching}
          >
            {t('Save')}
          </LoadingButton>
        </CardActions>
      </form>
    </>
  );
};

export default ThresholdTab;
