import { createSlice } from '@reduxjs/toolkit';

/**
 * Used when the whole page / section is loading.
 */
const pageLoader = createSlice({
  name: 'pageLoader',
  initialState: {
    isLoading: false,
  },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setIsLoading } = pageLoader.actions;
export default pageLoader.reducer;
