import TrashIcon from '@mui/icons-material/Delete';
import {
  Box, Button, Checkbox, CircularProgress, Stack, Table,
  TableBody, TableCell,
  TableContainer, TableHead, TablePagination, TableRow,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../../redux/api';
import TablePaginationSkeleton from '../../skeletons/TablePaginationSkeleton';
import TableSkeleton from '../../skeletons/TableSkeleton';
import { NoData } from '../../tk-ui';

const useStyles = makeStyles((theme) => ({
  noData: {
    transform: 'scale(4)',
  },
  noDataCell: {
    borderBottom: 'none',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  table: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}));

const AllergyIntoleranceTable = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { uuid } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const { config } = props;
  const { allergyIntolerance } = greyboxApiActions;
  const { edit } = useSelector((state) => state.permissions);
  const [deleteAllergyIntolerance] = allergyIntolerance.update();

  const { data, isFetching } = allergyIntolerance.list(
    {
      patient: `Patient/${uuid}`,
      code: config?.snomedConcept,
      _count: 50,
    }, {
      skip: config.referenceModel !== 'AllergyIntolerance',
    },
  );

  const handleCheckbox = (value) => {
    if (selectedRows.includes(value)) {
      setSelectedRows(selectedRows.filter((item) => item !== value));
    } else {
      setSelectedRows([...selectedRows, value]);
    }
  };

  const handleDelete = () => {
    selectedRows.forEach((item) => deleteAllergyIntolerance(
      {
        id: item,
        body: {
          verificationStatus: {
            coding: [{
              code: 'entered-in-error',
            }],
          },
        },
      },
    ));

    setSelectedRows([]);
  };

  const columns = [
    {
      id: 'clinicalStatus',
      label: t('Value'),
      format: (value) => (value.coding[0].display || '---'),
    },
    {
      id: 'recordedDate',
      label: t('Date'),
      format: (value) => (moment(value).format('YYYY-MM-DD HH:mm') || '---'),
    },

    {
      id: 'verificationStatus',
      label: t('Verification status'),
      format: (value) => (value.coding[0].display || '---'),
    },
  ];

  if (isFetching) {
    return (
      <>
        <div className={classes.header}>
          <TablePaginationSkeleton />
        </div>
        <TableContainer className={classes.table}>
          <TableSkeleton columns={columns} rowCount={rowsPerPage} />
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        pl: 1,
      }}
      >
        <Stack spacing={1} direction="row">
          <Button
            startIcon={<TrashIcon />}
            variant="outlined"
            onClick={() => handleDelete()}
            disabled={selectedRows.length === 0 || !edit}
            size="small"
            color="error"
          >
            {t('Delete')}
          </Button>
        </Stack>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          sx={{ ml: 'auto' }}
          count={data.total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
        />
      </Box>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              {columns.map((column, idx) => (
                <TableCell key={idx} align={column.align || 'center'} style={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching && (
              <TableRow>
                <TableCell colSpan={columns.length + 1} className={classes.noDataCell}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh"
                  >
                    <CircularProgress size={70} />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {data.entry?.length > 0 ? (
              data.entry.map((item) => (
                <TableRow
                  key={item.id}
                  onClick={() => handleCheckbox(item.resource.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.includes(item.resource.id)}
                      color="primary"
                    />
                  </TableCell>
                  {columns.map((column, idx) => (
                    <TableCell key={idx} align={column.align || 'center'}>
                      { column.format(item.resource[column.id]) }
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              !isFetching && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} className={classes.noDataCell}>
                    <Box height="200px" display="flex" justifyContent="center" alignItems="center">
                      <NoData className={classes.noData} />
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllergyIntoleranceTable;
