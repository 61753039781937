import AddIcon from '@mui/icons-material/Add';
import {
  Box, Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { HashLink as Link } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import { NoData } from '../../tk-ui';
import AddClinicalDataModal from './AddClinicalDataModal';
import TableSkeleton from '../../skeletons/TableSkeleton';

const findTimeStamps = (data) => {
  if (!data.isSuccess) return [];
  const uniqueDates = new Set();

  data.data.forEach((item) => {
    uniqueDates.add(item.time);
  });

  return [...uniqueDates].sort((a, b) => (moment(a).isBefore(b) ? 1 : -1));
};

const parseClinicalData = (data, config, dates, i18n) => {
  if (data.isSuccess && dates.length > 0) {
    return (config.map((conf) => (
      {
        id: conf.short_code,
        label: conf[`name_${i18n.resolvedLanguage}`],
        values: data.data.filter((item) => item.short_code === conf.short_code),
      }
    )));
  }
  return [];
};

/**
 * Displays a table of clinical data and a button to add new data. The left column that shows
 * clinical data categories is sticky. Entries are sorted by most recent date.
 */
const ClinicalDataTable = (props) => {
  const { uuid } = props;
  const { t, i18n } = useTranslation();
  const { clinic } = useSelector((state) => state.clinic);
  const { clinicalDataConfig, clinicalData } = greyboxApiActions;
  const { data } = clinicalDataConfig.list({ clinic: clinic.id });
  const [open, setOpen] = useState(false);
  const clinicalDataSelector = clinicalData.list({ patient: uuid });
  const latestClinicalData = clinicalData.list({ patient: uuid, latest: true });
  const { edit } = useSelector((state) => state.permissions);

  const timeStamps = useMemo(() => findTimeStamps(clinicalDataSelector), [clinicalDataSelector]);
  const rows = useMemo(() => parseClinicalData(
    clinicalDataSelector,
    data,
    timeStamps,
    i18n,
  ), [timeStamps]);

  if (clinicalDataSelector.isFetching || latestClinicalData.isFetching) {
    return (
      <Paper sx={{ width: '100%' }}>
        <TableSkeleton rowCount={8} />
      </Paper>
    );
  }

  if (clinicalDataSelector.data.length === 0) {
    return (
      <Paper sx={{ p: 1, width: '100%' }}>
        <Button
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => setOpen(true)}
          disabled={!edit}
        >
          {t('Add')}
        </Button>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '50vh',
        }}
        >
          <NoData style={{ transform: 'scale(6)' }} />
        </Box>
        {open && (
          <AddClinicalDataModal
            open={open}
            handleClose={() => setOpen(false)}
            clinicalDataConfig={data}
            patientUuid={uuid}
          />
        )}
      </Paper>
    );
  }

  return (
    <Paper sx={{ width: '100%', p: 2, height: 'fit-content' }}>
      <Typography variant="h6" gutterBottom>
        {t('Clinical Data')}
      </Typography>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        onClick={() => setOpen(true)}
        color="primary"
        disabled={!edit}
        sx={{ mb: 1 }}
      >
        {t('Add')}
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ borderCollapse: 'separate' }}>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  left: 0,
                  position: 'sticky',
                  zIndex: (theme) => theme.zIndex.appBar + 2,
                  backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
                }}
              />
              <TableCell
                align="center"
                sx={{
                  left: 200,
                  zIndex: (theme) => theme.zIndex.appBar + 2,
                  position: 'sticky',
                  color: (theme) => theme.palette.primary.contrastText,
                  backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
                }}
              >
                {t('Latest')}
              </TableCell>
              {timeStamps.map((date) => (
                <TableCell
                  key={date}
                  align="center"
                  sx={{
                    borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                    color: (theme) => theme.palette.primary.contrastText,
                    backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
                    zIndex: (theme) => theme.zIndex.appBar,
                  }}
                >
                  <div>
                    {moment(date).format('MM/DD/YYYY hh:mm A')}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell
                  scope="row"
                  sx={{
                    minWidth: '200px',
                    position: 'sticky',
                    left: 0,
                    zIndex: (theme) => theme.zIndex.appBar + 2,
                    backgroundColor: (theme) => theme.palette.background.paper,
                  }}
                >
                  <Link
                    to={row.id}
                    style={{
                      paddingLeft: '12.5px',
                      fontSize: '14px',
                      fontWeight: '500',
                      borderRight: null,
                    }}
                  >
                    {row.label}
                  </Link>
                </TableCell>
                <TableCell
                  sx={{
                    left: 200,
                    position: 'sticky',
                    zIndex: (theme) => theme.zIndex.appBar + 2,
                    borderRight: (theme) => `2px dashed ${theme.palette.primary.main}`,
                    borderLeft: (theme) => `2px dashed ${theme.palette.primary.main}`,
                    backgroundColor: (theme) => theme.palette.background.paper,
                  }}
                  align="center"
                >
                  {latestClinicalData.data.find((item) => item.short_code === row.id)?.value || '---'}
                </TableCell>
                {timeStamps.map((date) => (
                  <TableCell
                    key={date}
                    align="center"
                    sx={{
                      minWidth: '75px',
                      borderRight: (theme) => `1px solid ${theme.palette.divider}`,
                      position: 'relative',
                    }}
                  >
                    {row.values.find((data) => data.time === date)?.value || '---'}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {open && (
        <AddClinicalDataModal
          open={open}
          handleClose={() => setOpen(false)}
          clinicalDataConfig={data}
          patientUuid={uuid}
        />
      )}
    </Paper>
  );
};

ClinicalDataTable.propTypes = {
  uuid: PropTypes.string.isRequired,
};

export default ClinicalDataTable;
