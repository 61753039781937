import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckCircleOutline, MdAdd } from "react-icons/md";
import MedicationModal from '../medicationTable/MedicationModal';
import IconButton from '@mui/material/IconButton';

const MedicationList = (props) => {
  const { medication_list, acc_type, activeCodes, uuid } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const check_taken_med = (med) =>
    activeCodes.length > 0 && activeCodes.some((el) => el === med.active_ingredient_codes[0]);

  const handleClick = (med) => {
    if (acc_type === 'PT') {
      setSelected(med.en);
      setOpen(true);
    }
  };

  return (
    <Box sx={{p:1}}>
      {medication_list.map((med, index) => (
        <Box key={index}>
          {check_taken_med(med) ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: 'rgba(0,0,0,0)',
              }}
              data-cy={`suggestedMedItem-${med.en.replace(/\s+/g, '')}`}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: 1.14,
                  textAlign: 'left',
                  color: '#497632',
                  maxWidth: '190px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {med[lang]}
              </Typography>
              <IconButton size='small' disabled sx={{
                color: '#497632',
              }}>
                <MdCheckCircleOutline />
              </IconButton>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                transition: 'background 400ms ease',
              }}
              tabIndex={0}
              data-cy={`suggestedMedItem-${med.en.replace(/\s+/g, '')}`}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  lineHeight: 1.14,
                  textAlign: 'left',
                  color: '#454545',
                  maxWidth: '190px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {med[lang]}
              </Typography>
              <IconButton size='small' onClick={() => handleClick(med)} sx={{
                color: '#454545',
              }}>
                <MdAdd />
              </IconButton>
            </Box>
          )}
        </Box>
      ))}
      <MedicationModal
        acc_type={acc_type}
        patientId={uuid}
        open={open}
        handleClose={() => setOpen(false)}
        search={selected}
      />
    </Box>
  );
};

export { MedicationList };
