import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box, IconButton, Menu, MenuItem, TableBody, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { greyboxApiActions } from '../../redux/api';
import { RootState } from '../../redux/store';
import { DeviceType } from '../../types';
import TableWrapper from '../TableWrapper';
import AddDeviceDialog from './AddDeviceDialog';

const DeviceTable = () => {
  const { uuid } = useParams();
  const hasPatient = Boolean(uuid);
  const { t } = useTranslation();
  const { clinic } = useSelector((state: RootState) => state.clinic);
  const [open, setOpen] = React.useState(false);
  const { device } = greyboxApiActions;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const navigate = useNavigate();
  const { data, isFetching, isLoading } = device.list({
    organization: `Organization/${clinic.id}`,
    clinic: clinic.id,
    page: page + 1,
    page_size: rowsPerPage,

  });
  const [hovering, setIsHovering] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const devices: DeviceType[] = data?.entry.map((item) => item.resource) || [];
  const [deleteDevice] = device.delete();

  const col = [
    {
      id: 'displayName',
      label: t('Name'),
    },
    {
      id: 'status',
      label: t('Status'),
    },
    {
      id: 'availabilityStatus',
      label: t('Availability Status'),
      format: (value) => value?.coding[0].display,
    },
    {
      id: 'serialNumber',
      label: t('Serial Number'),
    },
    {
      id: 'meta',
      label: t('Last Updated'),
      format: (value) => moment(value?.lastUpdated).format('lll'),
    },

  ];

  const handleEdit = (id) => {
    if (hasPatient) {
      navigate(`/patient-profile/${uuid}/device/${id}`);
    } else {
      navigate(`/clinic-settings/device/${id}`);
    }
  };

  if (isLoading) return null;

  return (
    <Box width="100%">
      <Typography gutterBottom variant="h4" sx={{ fontWeight: 'bold', color: (theme) => theme.palette.text.secondary }}>
        {t('Devices')}
      </Typography>
      <TableWrapper
        maxHeight="80vh"
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        total={data.total}
        isFetching={isFetching}
        colCount={col.length}
      >
        <TableHead>
          <TableRow>
            {col.map((item) => (
              <TableCell key={item.id}>{item.label}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {devices.length === 0 && (
            <TableRow>
              <TableCell colSpan={6}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '200px',
                }}
                >
                  <Typography variant="body2" color="text.secondary">
                    {t('No devices')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
          {devices.map((item: DeviceType) => (
            <TableRow
              key={item.id}
              sx={{ height: 68 }}
              hover
              onMouseEnter={() => setIsHovering(item.id)}
              onMouseLeave={() => setIsHovering(null)}
            >
              {col.map((colItem) => (
                <TableCell key={colItem.id}>
                  {colItem.format ? colItem.format(item[colItem.id]) : item[colItem.id]}
                </TableCell>
              ))}
              <TableCell align="right" sx={{ width: 50 }}>
                {hovering === item.id && (
                  <div>
                    <IconButton
                      size="small"
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      sx={{ transition: 'all 0.7s ease-in-out' }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={openMenu}
                      onClose={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem onClick={() => handleEdit(item.id)}>
                        {t('Edit')}
                      </MenuItem>
                      <MenuItem
                        sx={{ color: (theme) => theme.palette.error.main }}
                        onClick={(event) => deleteDevice(item.id).then(() => {
                          setAnchorEl(null);
                        })}
                      >
                        {t('Delete')}
                      </MenuItem>
                    </Menu>
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableWrapper>
      {open && (
        <AddDeviceDialog open={open} onClose={() => setOpen(false)} />
      )}
    </Box>
  );
};

export default DeviceTable;
