import React from 'react';
import { useFormik } from 'formik';
import { TextField, FormControlLabel, Switch, Grid, Box, Button, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const GeneralInfo = ({ workflowInstance }) => {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      name_en: workflowInstance.name_en || '',
      name_fr: workflowInstance.name_fr || '',
      name_de: workflowInstance.name_de || '',
      name_it: workflowInstance.name_it || '',
      name_es: workflowInstance.name_es || '',
      name_pt: workflowInstance.name_pt || '',
      enabled: workflowInstance.enabled || false,
      event: workflowInstance.event || 'Manual', // Default to 'Manual'
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="name_en"
              label={t('Name (English)')}
              type="text"
              fullWidth
              value={formik.values.name_en}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="name_fr"
              label={t('Name (French)')}
              type="text"
              fullWidth
              value={formik.values.name_fr}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="name_de"
              label={t('Name (German)')}
              type="text"
              fullWidth
              value={formik.values.name_de}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="name_it"
              label={t('Name (Italian)')}
              type="text"
              fullWidth
              value={formik.values.name_it}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="name_es"
              label={t('Name (Spanish)')}
              type="text"
              fullWidth
              value={formik.values.name_es}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              margin="dense"
              name="name_pt"
              label={t('Name (Portuguese)')}
              type="text"
              fullWidth
              value={formik.values.name_pt}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formik.values.enabled}
                  onChange={formik.handleChange}
                  name="enabled"
                />
              }
              label={t('Enabled')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {t('Automation')}
            </Typography>
            <FormControl fullWidth margin="dense">
              <InputLabel id="event-label">{t('Event')}</InputLabel>
              <Select
                labelId="event-label"
                id="event"
                name="event"
                value={formik.values.event}
                onChange={formik.handleChange}
              >
                <MenuItem value="Manual">{t('Manual')}</MenuItem>
                <MenuItem value="Patient Created">{t('Patient Created')}</MenuItem>
                <MenuItem value="Vitals Uploaded">{t('Vitals Uploaded')}</MenuItem>
                <MenuItem value="Medication Reminder">{t('Medication Reminder')}</MenuItem>
                <MenuItem value="Appointment Scheduled">{t('Appointment Scheduled')}</MenuItem>
                <MenuItem value="Alert Triggered">{t('Alert Triggered')}</MenuItem>
                <MenuItem value="Check-in Required">{t('Check-in Required')}</MenuItem>
                {/* Add more events as needed */}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default GeneralInfo;
