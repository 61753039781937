import dagre from 'dagre';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

export const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

export const parseJsonToReactFlow = (json, direction = 'TB') => {
  const nodes = [];
  const edges = [];
  const process = json.process;

  // Add process nodes
  Object.keys(process).forEach((key) => {
    const node = process[key];
    nodes.push({
      id: key,
      type: node.type === 'endpoint' ? 'output' : 'default',
      data: { 
        label: key,
        type: node.type,
        action: node.action || null,
        params: node.params || {},
        op: node.op || null,
        var1: node.var1 || null,
        var2: node.var2 || null,
        scheduled: node.scheduled || null
      },
      position: { x: 0, y: 0 }, // Temporary position, will be updated by dagre
    });

    if (node.next) {
      edges.push({
        id: `e${key}${node.next}`,
        source: key,
        target: node.next,
        type: 'smoothstep',
        animated: true,
      });
    }

    if (node.success) {
      edges.push({
        id: `e${key}${node.success}`,
        source: key,
        target: node.success,
        type: 'smoothstep',
        animated: true,
      });
    }

    if (node.fail) {
      edges.push({
        id: `e${key}${node.fail}`,
        source: key,
        target: node.fail,
        type: 'smoothstep',
        animated: true,
      });
    }

  });

  // Add initial edge from entry_key
  if (json.entry_key) {
    edges.push({
      id: `e_entry_${json.entry_key}`,
      source: 'entry',
      target: json.entry_key,
      type: 'smoothstep',
      animated: true,
    });
  }

  // Add initial input node
  nodes.push({
    id: 'entry',
    type: 'input',
    data: { label: 'Start', type: 'start' },
    position: { x: 0, y: 0 },
  });

  return getLayoutedElements(nodes, edges, direction);
};
