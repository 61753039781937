import { Alert, Box } from '@mui/material';
import React from 'react';

const ErrorMessage = ({ message }) => {
    if (!message) return null;

    return (
        <Box sx={{ mb: 2 }}>
            <Alert severity="error">{message}</Alert>
        </Box>
    );
};

export default ErrorMessage;
