import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography, Box, Skeleton, Paper, Grid } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import Contraindications from './Contraindications';
import { greyboxApiActions } from '../../../../redux/api';
import { MedicationList } from './MedicationList';
import { Consultation, DisplayInformation, SuggestedNone } from './MedicationsWarning';
import { usePatientWebSocket } from '../../../../hooks';

const contraindicationValidator = (contraindications = []) =>
  contraindications.some((item) => item.displayWarning);

const classCheck = (suggestion, activeCodes) =>
  suggestion.medications.map((el) =>
    activeCodes.some((item) => el.active_ingredient_codes.includes(item))
  );

const messageMapping = {
  'consult-endocrinologist': <Consultation />,
  'suggest-do-nothing': <SuggestedNone />,
  information: <DisplayInformation />,
};

const SuggestedMedications = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { clinic } = useSelector((state) => state.clinic);
  const { uuid, acc_type, role_type_code } = props;
  const { medication, treatmentPlan } = greyboxApiActions;
  const { data = [], isFetching, refetch } = treatmentPlan.list({
    patient: uuid,
    latest: true,
    clinic: clinic.id,
  });
  const medicationSelector = medication.list({ account: uuid, status: 'active,intended' });
  let activeCodes = [];

  if (!medicationSelector.isFetching) {
    activeCodes = medicationSelector.data
      .flatMap((el) => el.medication.active_ingredient.map((el) => el.active_ingredient_code));
  }

  const { lastJsonMessage } = usePatientWebSocket();

  useEffect(() => {
    refetch();
    medicationSelector.refetch();
  }, []);

  useEffect(() => {
    if (lastJsonMessage !== null && lastJsonMessage.entity === 'Decision_Tree_History') {
      refetch();
    }
  }, [lastJsonMessage]);

  if (isFetching) {
    return null;
  }

  return (
    data.length > 0 && (
      <Box sx={{ mb: 2 }}>
        <Box sx={{ transition: 'all 2s ease', mb: 1 }} data-cy="SuggestedMedications">
          <Typography variant="h5" >
            {t('Suggested treatment plan')}
          </Typography>
          <Typography variant="subtitle2" >
            {t('Recommended based on the patient\'s current health status and treatment history.')}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {data.map((el, idx) => {
            const decision = el.decision_tree_output;

            return (
              <Grid item xs={12} sm={6} key={`grid-${idx}`}>
                <Paper
                  sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#f5faff',
                    p: 2
                  })}
                  variant='outlined'
                >
                  <SectionTitle decision={el} />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                      gap: 2,
                    }}
                  >
                    {decision.data ? (
                      decision.data.map((suggestion, index) => {
                        if (Array.isArray(suggestion)) {
                          return (
                            <Box
                              sx={{
                                backgroundColor: alpha(theme.palette.info.light, 0.3),
                                borderRadius: 5,
                                padding: 2,
                              }}
                              key={`med-${index}`}
                            >
                              <MedicationTile
                                data={suggestion[0]}
                                indexBox={index}
                                uuid={uuid}
                                acc_type={acc_type}
                                role_type_code={role_type_code}
                              />
                              <Box sx={{ width: '100%', textAlign: 'center' }}>
                                <Typography>--- {t('OR')} ---</Typography>
                              </Box>
                              <MedicationTile
                                indexBox={index}
                                data={suggestion[1]}
                                uuid={uuid}
                                acc_type={acc_type}
                              />
                            </Box>
                          );
                        }
                        return (
                          <MedicationTile
                            key={`med-${index}`}
                            indexBox={index}
                            uuid={uuid}
                            acc_type={acc_type}
                            data={suggestion}
                            role_type_code={role_type_code}
                            activeCodes={activeCodes}
                          />
                        );
                      })
                    ) : (
                      <Box>{messageMapping[decision.output_code]}</Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    )
  );
};

function MedicationTile(props) {
  const { i18n } = useTranslation();
  const theme = useTheme();
  const { acc_type, uuid, data, indexBox, role_type_code, activeCodes = [] } = props;
  const activeClass = classCheck(data, activeCodes);
  const isActive = activeClass.some((el) => el === true);

  return (
    <Box
      sx={{
        width: '232px',
        borderRadius: '5px',
        backgroundColor: isActive ? theme.palette.success.light : theme.palette.warning.main,
      }}
    >
      <Box
        sx={{
          p: 1,
          backgroundColor: alpha(theme.palette.background.paper, 0.65),
        }}
      >
        <Typography variant='h6'>
          {data.medication_class[i18n.resolvedLanguage]}
        </Typography>
        <Contraindications
          drugclass={data.medication_class}
          contraindications={data.contraindication}
          indexBox={indexBox}
          onCards
          warning={contraindicationValidator(data.contraindication)}
        />
      </Box>
      <MedicationList
        medication_list={data.medications}
        uuid={uuid}
        acc_type={acc_type}
        role_type_code={role_type_code}
        activeCodes={activeCodes}
      />
    </Box>
  );
}

const SectionTitle = ({ decision }) => {
  const { workflow } = greyboxApiActions;
  const { data, isFetching } = workflow.get(decision.decision_tree);

  return isFetching ? (
    <Skeleton variant="text" width={100} />
  ) : (
    <Typography variant='h5' gutterBottom>
      {data.name}
    </Typography>
  );
};


export default SuggestedMedications;
