import ErrorIcon from '@mui/icons-material/Error';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton, Paper,
  Skeleton,
  Tooltip, Typography
} from '@mui/material';
import moment from 'moment';
import 'moment/locale/fr';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiMailAddLine, RiMailSendLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { greyboxApiActions } from '../../../redux/api';
import AlertModal from '../../alert/AlertModal';
import { DialogChatBox } from '../../chat';
import PatientLinkInvitation from '../../invitations/PatientLinkInvitation';
import ReminderModal from '../components/ReminderModal';
import { LoadingButton } from '@mui/lab';

/**
 * Section displaying the patient Name and diagnosis.
 * Also list all the actions available for the current user for that patient.
 * Those actions will differ depending on the user access and clinic configurations.
 */
const PatientActionPanel = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const { account, patientDashboard, diagnosis, invitation } = greyboxApiActions;
  const { access } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const [chatOpen, setChatOpen] = useState(false);
  const patientSelector = useSelector((state) => state.patient);
  const [alertOpen, setAlertOpen] = useState(false);
  const [reminderOpen, setReminderOpen] = useState(false);
  const [invitationOpen, setInvitationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { data, isFetching, refetch } = account.get(patientSelector.accountId, { skip: !patientSelector.accountId });
  const clinic_invitation = useMemo(() => data.invitations.find((invitation) => invitation.clinic === clinic.id), [data, clinic]);
  const [updateInvitation] = invitation.update();

  const diagnosisSelector = diagnosis.list({ clinic: clinic.id });
  const patientInfo = patientDashboard.list(
    {
      id: patientSelector.accountId,
      fields: access === 'PT' ? 'alert,reminder,message' : 'message',
      clinic: clinic.id,
    }, { skip: !patientSelector.accountId },
  );

  if (isFetching
    || patientSelector.isLoading
    || patientInfo.isFetching
    || diagnosisSelector.isLoading) {
    return (
      <Paper>
        <Box display="flex" flexDirection="column" alignItems="center" padding={1}>
          <Skeleton variant="text" width={200} height={40} />
          <Skeleton variant="text" width={100} height={25} />
        </Box>
        <Box display="flex" sx={{ m: 1 }} flexDirection="row" justifyContent="space-evenly">
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
      </Paper>
    );
  }

  const handleClose = (refresh = false) => {
    setAlertOpen(false);
    if (refresh) {
      patientInfo.refetch();
    }
  };

  const handleUpdateInvitation = async () => {
    if (clinic_invitation && clinic_invitation.status === 'pending') {
      setIsLoading(true);
      try {
        await updateInvitation({
          id: clinic_invitation.uuid,
          modifyDate: moment().toISOString(true),
          feedback: { success: t('Invitation sent') } 
        });
      } catch (error) {
        console.error('Failed to update invitation:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setInvitationOpen(true);
    }
  };

  return (
    <Paper>
      <Box
        sx={{
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}

      >
        <Typography variant="h5">
          {data.firstName !== undefined
            ? `${data.firstName} ${data.lastName}`
            : '---'}
        </Typography>
        {data.hin && (
          <Typography variant="body2">
            {data.hin.hin_number}
          </Typography>
        )}
        {data.birthDate && (
          <Typography variant="body2">
            {moment(data.birthDate).format('YYYY-MM-DD')}
          </Typography>
        )}
        <Typography variant="caption" color="textSecondary" align="center">
          {user.access === 'PT' && (
            <>
              {data.diagnosis
                .filter((d) => diagnosisSelector.data.some((diag) => diag.diagnosis_key === d))
                .map((d, index) => (
                  <span key={d}>
                    {diagnosisSelector.data.find((diag) => diag.diagnosis_key === d).diagnosis_name}
                    {index < data.diagnosis.length - 1 ? ', ' : ''}
                  </span>
                ))}
              {data.secondary_diagnosis
                .filter((d) => diagnosisSelector.data.some((diag) => diag.uuid === d))
                .map((d, index, arr) => (
                  <span key={d}>
                    {index !== 0 ? ', ' : ''}
                    {diagnosisSelector.data.find((diag) => diag.uuid === d).diagnosis_name}
                    {' '}
                  </span>
                ))}

            </>
          )}
        </Typography>
      </Box>
      {data.invitation_status === 'accepted' && (
        <Box display="flex" sx={{ m: 1 }} flexDirection="row" justifyContent="space-evenly">
          {clinic.config.features_enable.alert && access === 'PT' && (
            <Tooltip
              title={`${t('Follow-up')}`}
              arrow
              enterDelay={500}
              enterNextDelay={500}
            >
              <Badge badgeContent={patientInfo.data?.active_alert_count} color="error">
                <IconButton
                  sx={{
                    '&:hover, &.Mui-focusVisible': { backgroundColor: 'error.dark' },
                    color: 'error.contrastText',
                    backgroundColor: 'error.main',
                  }}
                  disabled={!patientInfo.data
                    || patientInfo.data.active_alert_count === 0}
                  onClick={() => setAlertOpen(true)}
                >
                  <ErrorIcon />
                </IconButton>
              </Badge>
            </Tooltip>
          )}
          {clinic.config.features_enable.reminder && access === 'PT' && (
            <Tooltip
              title={`${t('Reminders')}`}
              arrow
              enterDelay={500}
              enterNextDelay={500}
            >
              <Badge badgeContent={patientInfo.data?.active_reminder_count} color="warning">
                <IconButton
                  sx={{
                    '&:hover, &.Mui-focusVisible': { backgroundColor: 'warning.dark' },
                    color: 'warning.contrastText',
                    backgroundColor: 'warning.main',
                  }}
                  disabled={!patientInfo.data
                    || patientInfo.data.active_reminder_count === 0}
                  onClick={() => setReminderOpen(true)}
                >
                  <NotificationsIcon />
                </IconButton>
              </Badge>
            </Tooltip>
          )}
          {clinic.config.features_enable.message && (
            <Tooltip
              title={`${t('Messages')}`}
              arrow
              enterDelay={500}
              enterNextDelay={500}
            >
              <Badge badgeContent={patientInfo.data?.new_message_count} color="info">
                <IconButton
                  sx={{
                    '&:hover, &.Mui-focusVisible': { backgroundColor: 'info.dark' },
                    color: '#FFFFFF',
                    backgroundColor: 'info.main',
                  }}
                  onClick={() => setChatOpen(true)}
                >
                  <MessageIcon />
                </IconButton>
              </Badge>
            </Tooltip>
          )}
        </Box>
      )}
      {(access === 'PT' && (!data.invitation_status || (clinic_invitation && clinic_invitation.status !== 'accepted'))) && (
        <Box display="flex" sx={{ m: 1 }} flexDirection="row" justifyContent="space-evenly">
          {/* Existing buttons here */}
          <Button
            variant="contained"
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> :
              (clinic_invitation && clinic_invitation.status === 'pending' ? <RiMailSendLine /> : <RiMailAddLine />)}
            sx={{
              '&:hover, &.Mui-focusVisible': { backgroundColor: 'primary.dark' },
              backgroundColor: clinic_invitation && clinic_invitation.status === 'pending' ? 'success.main' : 'primary.main',
              color: '#FFFFFF',
            }}
            onClick={handleUpdateInvitation}
            disabled={isLoading}
          >
            {isLoading ? t('Processing...') : (clinic_invitation && clinic_invitation.status === 'pending' ? t('Resend Invitation') : t('Send Invitation'))}
          </Button>
          <PatientLinkInvitation
            patient={data}
            open={invitationOpen}
            handleClose={() => {
              setInvitationOpen(false);
              refetch();
            }}
          />
        </Box>
      )}
      {reminderOpen && (
        <ReminderModal
          open={reminderOpen}
          handleClose={() => setReminderOpen(false)}
          patient={{ uuid: patientSelector.accountId }}
          link={false}
        />
      )}
      {alertOpen && (
        <AlertModal
          open={alertOpen}
          handleClose={handleClose}
          patient={{ uuid: patientSelector.accountId }}
          link={false}
        />
      )}
      {chatOpen && <DialogChatBox open={chatOpen} handleClose={() => setChatOpen(false)} />}
    </Paper>
  );
};

export default PatientActionPanel;
