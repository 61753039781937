import { Box } from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MultiGrid from 'react-virtualized/dist/commonjs/MultiGrid';
import { HashLink as Link } from 'react-router-hash-link';
import { emptyArray, emptyObject } from '../../../helper-functions';
import { greyboxApiActions } from '../../../redux/api';
import { Sorted, Sorting } from '../img';
import { lab_table_style } from './labResults_style';

/*
  GOAL:
    Parse lab result's timestamp, converting to user's browser's timezone (guessed by moment.js)
  INPUT:
    datetime_str - timezone-sensitive date time string, e.g. "2022-02-03T23:11:15-05:00"
    guessed_timezone - guess at client's timezone, e.g. "America/Montreal"
  OUTPUT:
    Obj containing date and time strings, parsed to user's guessed timezone
      e.g. {
        date: "2022-02-03"
        time: "11:11 PM"
      }
*/
const parseTimeStamp = (datetime_str, guessed_timezone) => ({
  date: moment(datetime_str).tz(guessed_timezone).format('YYYY-MM-DD'),
  time: moment(datetime_str).tz(guessed_timezone).format('h:mm A'),
});

/*
  GOAL:
    Order lab results by name based on current sorting order and language
  INPUT:
    lab_results_config - config for each lab result type, of format:
      {
        "potassium": {
              "short_code": "potassium",
              "name_en": "Blood potassium",
              "name_fr": "Potassium sanguin",
              "unit": "mEq/L",
              "dataTypeJSON": {
                  "type": "float"
              }
          },
          ...
        }
    sortOrder - current sort order: null (default) | 'asc' | 'desc'
    lang_code - current language: 'en' | 'fr'
  OUTPUT:
    List of short codes, in order that they should be displayed in lab results table,
    sorted based on specified sortOrder, and current language
      e.g. ["potassium", "HbA1C", ...]
*/
const orderLabResults = (config, sortOrder, lang_code) => {
  const short_codes = config.map((c) => c.short_code);
  const name_key = 'name_' + lang_code;

  if (sortOrder === null) {
    return short_codes; // default order
  } if (sortOrder === 'asc') {
    return short_codes.sort((a, b) => {
      const a_name = config[a][name_key].toLowerCase();
      const b_name = config[b][name_key].toLowerCase();
      return a_name > b_name ? 1 : -1;
    });
  } if (sortOrder === 'desc') {
    return short_codes.sort((a, b) => {
      const a_name = config[a][name_key].toLowerCase();
      const b_name = config[b][name_key].toLowerCase();
      return a_name < b_name ? 1 : -1;
    });
  }
  return null;
};

/*
  GOAL:
    Change sorting lab results order in state, called when
    patient clicks on lab result names column header
  INPUT:
    sortOrder - current lab results sorting order. one of: null (aka default) | asc | desc
    setSortOrder - hook for setting 'sortOrder' in state
  OUTPUT:
    No output
*/
const onSortRows = (sortOrder, setSortOrder) => {
  if (sortOrder === null) {
    setSortOrder('asc');
  } else if (sortOrder === 'asc') {
    setSortOrder('desc');
  } else {
    setSortOrder(null);
  }
};

/*
  GOAL:
    Parse a numeric value so that it fits into a table cell
    -> if lots of decimal places, round to 5 decimals
    -> if long whole number, display in scientific notation to 5 decimal points
  INPUT:
    value: int or float
  OUTPUT:
    int or float or str converted value (for displaying in table)
*/
const formatNumericValue = (value) => {
  let formattedValue = parseFloat(parseFloat(value).toFixed(5)); // round to 5 decimals

  // handle number w/ a lot of decimals, after rounding
  if (formattedValue.toString().replace('.', '').length > 8) {
    formattedValue = value.toExponential(5);
  }

  return formattedValue;
};

/*
  GOAL:
    Parse data fetched from patient's lab results API into
    format convenient to use for populating lab results table.
    For each column in table: create an obj w/ data to be displayed.
  INPUT:
    data - list of lab results for a patient, sorted on decr. time
      e.g.
      {
        "uuid": "d0f4116c-baf7-4749-a2ac-5ffb5624daa6",
        "short_code": "potassium",
        "patient": "2d60ace7-ea67-4086-a297-fb37312ae1d8",
        "value": 40.5,
        "time": "2021-02-03T23:11:15-05:00"
      },
  OUTPUT:
    List of data objs for each column, (on decr. time), of format:
      e.g.
      {
        columnDate: "2021-02-03",
        columnTime: "11:11 PM",
        columnData: {
          creatinine: "120",
          potassium: "40.5",
        }
      }
*/
const formatData = (data, guessed_timezone) => {
  const columns = [];
  let columnsIndex = -1;
  let prevDateAndTime = null;

  if (emptyArray(data)) {
    columns.push({
      columnDate: null,
      columnTime: null,
      columnData: {},
    });
    columnsIndex += 1;
  } else {
    data.forEach((obj) => {
      const { date, time } = parseTimeStamp(obj.time, guessed_timezone);

      if (date + '/' + time !== prevDateAndTime) {
        columns.push({
          columnDate: date,
          columnTime: time,
          columnData: {
            [obj.short_code]: formatNumericValue(obj.value),
          },
        });

        columnsIndex += 1;
        prevDateAndTime = date + '/' + time;
      } else if (!(obj.short_code in columns[columnsIndex].columnData)) {
        columns[columnsIndex].columnData[obj.short_code] = formatNumericValue(obj.value);
      }
    });
  }

  return [columns, columnsIndex + 1];
};

// Format min target, max target and target date for each latest threshold
// Ignore thresholds whose function doesn't starts with "M_"
// (all lab results threshold functions will start with "M_")
const formatThresholdData = (threshold_data, guessed_timezone) => {
  const formatted_thresholds_obj = {};

  threshold_data.forEach((threshold) => {
    if (threshold.function.startsWith('M_')) {
      formatted_thresholds_obj[threshold.function] = {
        target_min: threshold.lowerRange_1 == null ? '—' : formatNumericValue(threshold.lowerRange_1),
        target_max: threshold.upperRange_1 == null ? '—' : formatNumericValue(threshold.upperRange_1),
        threshold_date: parseTimeStamp(threshold.created_date, guessed_timezone),
      };
    }
  });

  return formatted_thresholds_obj;
};

/* Render header cell for lab results names (can click on it to sort rows by name) */
const LabResultsNamesHeaderCell = (props) => {
  const {
    style,
    sortOrder,
    setSortOrder,
  } = props;
  const { i18n, t } = useTranslation();

  return (
    <div
      style={{ ...style, ...lab_table_style.cell, ...lab_table_style.labResultsNameHeaderCell }}
      onClick={() => onSortRows(sortOrder, setSortOrder)}
      role="button"
      tabIndex={0}
      data-cy="LabResultsTable_SortingBtnCell"
    >
      <span style={lab_table_style.labResultsNameHeaderCellText}>
        {t('Lab Results')}
      </span>
      <span style={lab_table_style.labResultsNameHeaderCellIcon}>
        {sortOrder === 'asc' ? (
          <Sorted style={lab_table_style.sortIconAsc} />
        ) : sortOrder === 'desc' ? (
          <Sorted style={lab_table_style.sortIconDesc} />
        ) : (
          <Sorting />
        )}
      </span>
    </div>
  );
};

/* Render header cell for health targets (min and max) */
const HealthTargetsHeaderCell = (props) => {
  const { style } = props;
  const { i18n, t } = useTranslation();

  return (
    <div
      style={{ ...style, ...lab_table_style.cell, ...lab_table_style.healthTargetHeaderCell }}
    >
      <div style={lab_table_style.healthTargetHeaderCellName}>
        {t('Health Targets')}
      </div>
      <div style={lab_table_style.healthTargetHeaderCellNameSubgroups}>
        <div
          style={{
            ...lab_table_style.healthTargetHeaderCellNameSubgroupItem,
            ...lab_table_style.targetRightBorder,
          }}
        >
          {t('Min')}
        </div>
        <div
          style={{
            ...lab_table_style.healthTargetHeaderCellNameSubgroupItem,
            ...lab_table_style.targetRightBorder,
          }}
        >
          {t('Max.')}
        </div>
        <div style={lab_table_style.healthTargetHeaderCellNameSubgroupItem}>
          {t('Start Date')}
        </div>
      </div>
    </div>
  );
};

/* Render "double" cell for health targets (min and max) */
const HealthTargetsDataCell = (props) => {
  const {
    style,
    short_code,
    formatted_targets_obj,
  } = props;

  // Default display values
  let target_min = '—';
  let target_max = '—';
  let threshold_date = {
    date: '—',
    time: '—',
  };

  // latest_thresholds_mapping
  let threshold_exists = false;
  const threshold_function = 'M_' + short_code;
  if (threshold_function in formatted_targets_obj) {
    threshold_exists = true;
    target_min = formatted_targets_obj[threshold_function].target_min;
    target_max = formatted_targets_obj[threshold_function].target_max;
    threshold_date = formatted_targets_obj[threshold_function].threshold_date;
  }

  return (
    <div
      style={{ ...style, ...lab_table_style.cell, ...lab_table_style.healthTargetDataCell }}
    >
      <span style={lab_table_style.targetRightBorder}>
        {target_min}
      </span>
      <span style={lab_table_style.targetRightBorder}>
        {target_max}
      </span>
      {threshold_exists ? (
        <div
          style={lab_table_style.targetDateTime}
        >
          <div>{threshold_date.date}</div>
          <div>{threshold_date.time}</div>
        </div>
      ) : (
        <span>
          —
        </span>
      )}
    </div>
  );
};

/* Render column header cell */
const ResultsColumnHeaderCell = (props) => {
  const {
    style,
    column_config,
  } = props;

  // Patient has no lab results scenario - want to display empty column w/ dash
  if (column_config.columnDate === null && column_config.columnTime === null) {
    return (
      <div
        style={{ ...style, ...lab_table_style.cell }}
        data-cy="LabResultsTable_DataColumnHeader"
      >
        <span>—</span>
      </div>
    );
  }

  // Patient has lab results - display non-empty header cell

  return (
    <div
      style={{ ...style, ...lab_table_style.cell, ...lab_table_style.columnHeaderCell }}
      data-cy="LabResultsTable_DataColumnHeader"
    >
      <div>{column_config.columnDate}</div>
      <div>{column_config.columnTime}</div>
    </div>
  );
};

/* Render row header cell */
const ResultsRowHeaderCell = (props) => {
  const {
    style,
    lab_results_config,
    short_code,
  } = props;
  const { i18n, t } = useTranslation();
  const lang_code = i18n.resolvedLanguage; // 'en' | 'fr'
  const lab_config_obj = lab_results_config.find(
    (lab_config) => lab_config.short_code === short_code,
  );

  return (
    <div
      style={{ ...style, ...lab_table_style.cell, ...lab_table_style.rowHeaderCell }}
      data-cy="LabResultsTable_RowHeader"
    >
      <Link
        to={short_code}
        style={{
          paddingLeft: '12.5px',
          fontSize: '14px',
          fontWeight: '500',
          borderRight: null,
        }}
      >
        {lab_config_obj['name_' + lang_code]}
      </Link>
      <span style={lab_table_style.rowHeaderCellUnit}>
        {lab_config_obj.unit}
      </span>
    </div>
  );
};

/* Render lab results value cell */
const ResultsDataCell = (props) => {
  const {
    style,
    column_config,
    short_code,
  } = props;

  return (
    <div
      style={{ ...style, ...lab_table_style.cell }}
    >
      <span>
        {column_config.columnData[short_code] || '—'}
      </span>
    </div>
  );
};

const CellRenderer = (props) => {
  const {
    columnIndex,
    rowIndex,
    style, // this prop comes from react-virtualized
    dataColumns,
    formatted_targets_obj,
    rowOrder,
    lab_results_config,
    sortOrder,
    setSortOrder,
    RESULTS_ROW_OFFSET,
    RESULTS_COL_OFFSET,
  } = props;

  const { i18n, t } = useTranslation();

  /*
    Determine current lab result short_code, based on row index
    minus RESULTS_ROW_OFFSET, which if the number of sticky rows before data rows
  */
  const short_code = rowIndex >= RESULTS_ROW_OFFSET
    ? rowOrder[rowIndex - RESULTS_ROW_OFFSET]
    : null;

  /*
    Get config obj for current column, based on column index
    minus RESULTS_COL_OFFSET, which is the number of sticky cols before data cols
    (column configs are generated in `format_data` function)
  */
  const column_config = columnIndex >= RESULTS_COL_OFFSET
    ? dataColumns[columnIndex - RESULTS_COL_OFFSET]
    : null;

  if (columnIndex === 0 && rowIndex === 0) {
    /* Render header cell for lab results names (can click on it to sort rows by name) */
    return (
      <LabResultsNamesHeaderCell
        style={style}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
      />
    );
  } if (columnIndex === 1 && rowIndex === 0) {
    /* Render header cell for health targets (min and max) */
    return (
      <HealthTargetsHeaderCell
        style={style}
      />
    );
  } if (columnIndex === 1) {
    /* Render "double" cell for health targets (min and max) */
    return (
      <HealthTargetsDataCell
        style={style}
        short_code={short_code}
        formatted_targets_obj={formatted_targets_obj}
      />
    );
  } if (columnIndex === 1 && rowIndex === 0) {
    /* Render header cell for health targets (min and max) */

    return (
      <div
        style={{ ...style, ...lab_table_style.cell, ...lab_table_style.healthTargetHeaderCell }}
      >
        <div style={lab_table_style.healthTargetHeaderCellName}>
          {t('Health Target')}
        </div>
        <div style={lab_table_style.healthTargetHeaderCellNameSubgroups}>
          <div
            style={{
              ...lab_table_style.healthTargetHeaderCellNameSubgroupItem,
              ...lab_table_style.targetRightBorder,
            }}
          >
            {t('Min')}
          </div>
          <div
            style={{
              ...lab_table_style.healthTargetHeaderCellNameSubgroupItem,
              ...lab_table_style.targetRightBorder,
            }}
          >
            {t('Max.')}
          </div>
          <div style={lab_table_style.healthTargetHeaderCellNameSubgroupItem}>
            {t('Start Date')}
          </div>
        </div>
      </div>
    );
  } if (columnIndex === 1) {
    /* Render "double" cell for health targets (min and max) */

    // Default display values
    let target_min = '—';
    let target_max = '—';
    let threshold_date = {
      date: '—',
      time: '—',
    };

    // latest_thresholds_mapping
    let threshold_exists = false;
    const threshold_function = 'M_' + short_code;
    if (threshold_function in formatted_targets_obj) {
      threshold_exists = true;
      target_min = formatted_targets_obj[threshold_function].target_min;
      target_max = formatted_targets_obj[threshold_function].target_max;
      threshold_date = formatted_targets_obj[threshold_function].threshold_date;
    }

    return (
      <div
        style={{ ...style, ...lab_table_style.cell, ...lab_table_style.healthTargetDataCell }}
        // data-cy="LabResultsTable_DataColumnHeader"
      >
        <span style={lab_table_style.targetRightBorder}>
          {target_min}
        </span>
        <span style={lab_table_style.targetRightBorder}>
          {target_max}
        </span>
        {threshold_exists ? (
          <div
            // style={lab_table_style.columnHeaderCell}
            style={lab_table_style.targetDateTime}
          >
            <div>{threshold_date.date}</div>
            <div>{threshold_date.time}</div>
          </div>
        ) : (
          <span>
            —
          </span>
        )}
      </div>
    );
  } if (rowIndex === 0) {
    /* Render column header cell */
    return (
      <ResultsColumnHeaderCell
        style={style}
        column_config={column_config}
      />
    );
  } if (columnIndex === 0) {
    /* Render row header cell */
    return (
      <ResultsRowHeaderCell
        style={style}
        lab_results_config={lab_results_config}
        short_code={short_code}
      />
    );
  }

  /* Render lab results value cell */
  return (
    <ResultsDataCell
      style={style}
      column_config={column_config}
      short_code={short_code}
    />
  );
};

const LabResultsTable = (props) => {
  const { uuid, setLatestTargets, config } = props;
  const { i18n } = useTranslation();
  const { threshold, labResults } = greyboxApiActions;
  const { data } = threshold.list({ latest: true, localAccount: uuid });
  const labResultsSelector = labResults.list({ patient: uuid });

  /* Define widths and heights for table */
  const NAME_COLUMN_WIDTH = 290;
  const DATA_COLUMN_WIDTH = 100;
  const TARGET_COLUMN_WIDTH = DATA_COLUMN_WIDTH * 3;
  const MAX_GRID_WIDTH = 1240;
  const MAX_GRID_HEIGHT = 600; // (14 measurements + 1 header) * ROW_HEIGHT
  const ROW_HEIGHT = 40;

  /* Define number of cells by which to offset lab results cells */
  const RESULTS_ROW_OFFSET = 1; // results rows are offset by 1 cell: date header
  const RESULTS_COL_OFFSET = 2; // results columns are offset by 2 cells: results name and target

  /* Define lab results rows order */
  const [sortOrder, setSortOrder] = useState(null); // null | 'asc' | 'desc'
  const [rowOrder, setRowOrder] = useState([]); // order of lab result short codes (list)

  /* Determine row order */
  useEffect(() => {
    setRowOrder(
      orderLabResults(config, sortOrder, i18n.resolvedLanguage),
    );
  }, [sortOrder, JSON.stringify(config), i18n.resolvedLanguage]);

  /* Format lab results table for display */
  const guessed_timezone = useMemo(() => moment.tz.guess(), []);
  const [dataColumns, numDataCols] = useMemo(
    () => formatData(labResultsSelector.data || [], guessed_timezone), [labResultsSelector.data],
  );

  /* Count number of data rows */
  const numDataRows = useMemo(() => rowOrder.length, [rowOrder]);

  /* Format latest thresholds by key */
  const formatted_targets_obj = useMemo(() => formatThresholdData(
    data || [],
    guessed_timezone,
  ), [data]);

  /* Calculate grid width (dynamic, depends on number of columns) */
  const gridWidth = useMemo(() => Math.min(
    NAME_COLUMN_WIDTH + TARGET_COLUMN_WIDTH + numDataCols * DATA_COLUMN_WIDTH,
    MAX_GRID_WIDTH,
  ), [numDataCols]);

  /* Calculate grid height (dynamic, depends on number of rows) */
  const gridHeight = useMemo(() => Math.min(
    (numDataRows + RESULTS_ROW_OFFSET) * ROW_HEIGHT,
    MAX_GRID_HEIGHT,
  ), [numDataRows]);

  useEffect(() => {
    setLatestTargets(formatted_targets_obj);
  }, [formatted_targets_obj]);

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <MultiGrid
        fixedColumnCount={RESULTS_COL_OFFSET}
        fixedRowCount={RESULTS_ROW_OFFSET}
        scrollToColumn={0}
        scrollToRow={0}
        cellRenderer={(props) => (
          <CellRenderer
            dataColumns={dataColumns}
            rowOrder={rowOrder}
            lab_results_config={config}
            formatted_targets_obj={formatted_targets_obj}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            guessed_timezone={guessed_timezone}
            RESULTS_ROW_OFFSET={RESULTS_ROW_OFFSET}
            RESULTS_COL_OFFSET={RESULTS_COL_OFFSET}
            {...props}
          />
        )}
        columnWidth={(columnObj) => (
          columnObj.index === 0
            ? NAME_COLUMN_WIDTH
            : columnObj.index === 1
              ? TARGET_COLUMN_WIDTH
              : DATA_COLUMN_WIDTH
        )}
        columnCount={numDataCols + RESULTS_COL_OFFSET}
        height={gridHeight}
        rowHeight={ROW_HEIGHT}
        rowCount={numDataRows + RESULTS_ROW_OFFSET}
        style={lab_table_style.multiGrid}
        styleBottomLeftGrid={lab_table_style.bottomLeftGrid}
        styleTopLeftGrid={lab_table_style.topLeftGrid}
        styleTopRightGrid={lab_table_style.topRightGrid}
        width={gridWidth}
        hideTopRightGridScrollbar
        hideBottomLeftGridScrollbar
      />
    </Box>
  );
};

export default LabResultsTable;

export {
  parseTimeStamp,
  orderLabResults,
  onSortRows,
  formatNumericValue,
  formatData,
};
