import React, { useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Box, Dialog, DialogContent,
  TextField, MenuItem, DialogActions,
  Button,
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';
import { VITAL_CONFIG } from './utils';
import { DatePicker } from '../form-inputs';

const DynamicFields = (props) => {
  const { vital, formik } = props;
  const { t } = useTranslation();
  const { vitalsConfig } = useSelector((state) => state.clinic);

  if (vital in VITAL_CONFIG && VITAL_CONFIG[vital].fields) {
    return (
      <Box display="flex" sx={{ m: 1 }}>
        {
          VITAL_CONFIG[vital].fields.map((field) => (
            field.units ? (
              <TextField
                key={field.id}
                label={field.label}
                sx={{ width: `${100 / VITAL_CONFIG[vital].fields.length}%` }}
                name={field.id}
                value={formik.values[field.id]}
                onChange={formik.handleChange}
                select
              >
                {field.units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {t(unit.label)}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <TextField
                key={field.id}
                label={field.label}
                sx={{ width: `${100 / VITAL_CONFIG[vital].fields.length}%`, mr: 1 }}
                name={field.id}
                value={formik.values[field.id]}
                onChange={formik.handleChange}
                type="number"
              />
            )
          ))
        }
      </Box>
    );
  }

  // Skip the parents since the values are added to the children
  if (vitalsConfig[vital].config && vitalsConfig[vital].config.children) {
    return null;
  }

  return (
    <Box m={1}>
      <TextField
        key={vital}
        label={vitalsConfig[vital].name}
        name={vital}
        fullWidth
        type="number"
        value={formik.values[vital]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[vital] && formik.errors[vital]}
        helperText={formik.touched[vital] && formik.errors[vital]}
        variant="outlined"
      />
    </Box>
  );
};

const createValidationSchema = (vitals) => {
  const schema = {
    time: yup.date().required(),
  };

  vitals.forEach((vital) => {
    if (!(vital in VITAL_CONFIG) || !VITAL_CONFIG[vital].fields) {
      schema[vital] = yup.mixed();
    } else {
      VITAL_CONFIG[vital].fields.forEach((field) => {
        schema[field.id] = field.validation;
      });
    }
  });

  return yup.object(schema);
};

const initialValues = (vitals) => {
  const values = {
    time: moment(),
  };

  vitals.forEach((vital) => {
    if (!(vital in VITAL_CONFIG) || !VITAL_CONFIG[vital].fields) {
      values[vital] = '';
    } else {
      VITAL_CONFIG[vital].fields.forEach((field) => {
        values[field.id] = field.defaultValue || '';
      });
    }
  });
  return values;
};

const AddVitalsDialog = (props) => {
  const { open, handleClose, vital = '' , patientUuid} = props;
  const { t } = useTranslation();
  const {
    steps,
    observation,
    bloodPressure,
    bloodGlucose,
    heartRate,
    weight,
    media,
  } = greyboxApiActions;
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const [postWeight] = weight.add();
  const [postBloodGlucose] = bloodGlucose.add();
  const [postBloodPressure] = bloodPressure.add();
  const [postHeartRate] = heartRate.add();
  const [postObservation] = observation.add();
  const [postSteps] = steps.add();
  const [postMedia] = media.add();
  const displayedVitals = vital === '' ? Object.keys(vitalsConfig) : [vital];

  const formik = useFormik({
    validationSchema: createValidationSchema(displayedVitals),
    initialValues: initialValues(displayedVitals),
    onSubmit: (values) => {
      const postMapping = {
        weight: postWeight,
        glucoseLevel: postBloodGlucose,
        BG: postBloodGlucose,
        tensionHigh: postBloodPressure,
        restingHeartRate: postHeartRate,
        heartRate: postHeartRate,
        steps: postSteps,
        CD: postSteps,
      };

      Promise.all(Object.entries(values).map(([key, value]) => {
        if (value === '') return Promise.resolve();

        if (key === 'glucosePeriod' || key === 'time') return Promise.resolve();
        // Deal with legacy vitals calls
        if (key in postMapping) {
          const params = {
            account: patientUuid,
            time: values.time,
            device: 'manual',
          };

          if (key === 'tensionHigh') {
            params.tensionHigh = values.tensionHigh;
            params.tensionLow = values.tensionLow;
          }

          if (key === 'heartRate') {
            params.heartRate = value;
          }

          if (key === 'glucoseLevel') {
            params.glucoseLevel = value;
            params.measurementPeriod = values.glucosePeriod;
          }

          if (key === 'weight') {
            if (values.weightUnit.toLowerCase() === 'kg') {
              params.weight = value;
            } else {
              params.weight = (value * 0.453592).toFixed(2);
            }
          }

          if (key === 'CD') {
            params.startTime = values.time;
            params.endTime = values.time;
            params.steps = value;
          }

          return postMapping[key]({ body: params });
        }

        const vitalConfig = vitalsConfig[key];
        if (!vitalConfig) {
          return Promise.resolve();
        }

        if (vitalConfig.GB_valueFieldName === 'valueAttachment') {
          const formData = new FormData();
          formData.append('file', value);
          formData.append('patient', patientUuid);
          formData.append('type', 'other');
          return postMedia({ body: formData }).then((res) => {
            const observationParams = {
              category: ['vital-signs'],
              effectiveDateTime: values.time,
              GB_device: 'manual',
              GB_source: 'manual',
              issued: values.time,
              status: 'final',
              subject: patientUuid,
              valueAttachment: res.data.id,
              short_code: key,
            };
            return postObservation({ body: { ...observationParams } });
          });
        }

        const observationParams = {
          category: ['vital-signs'],
          effectiveDateTime: values.time,
          GB_device: 'manual',
          GB_source: 'manual',
          issued: values.time,
          status: 'final',
          subject: patientUuid,
          valueQuantity: {
            value: value,
            unit: vitalConfig.unit,
          },
          short_code: key,
        };

        if (key === 'temperature') {
          let val = value;
          if (values.temperatureUnit === '°F') {
            val = (((value - 32) * 5) / 9).toFixed(2);
          }
          observationParams.valueQuantity = {
            value: val,
            unit: '°C',
          };
        }
        return postObservation({ body: { ...observationParams } });
      })).then(() => {
        handleClose();
      });
    },
  });

  useEffect(() => {
    if (open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle onClose={handleClose}>
        {t('Add Vitals')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ overflowY: 'scroll' }}>
          <DatePicker
            disableFuture
            time
            label={t('Date')}
            formik={formik}
            name="time"
          />
          {displayedVitals.map((k) => (
            <DynamicFields key={k} vital={k} formik={formik} />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('Cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={formik.isSubmitting}
          >
            {t('Add')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddVitalsDialog;
