import { LoadingButton, Skeleton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import React, { Suspense, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { greyboxApiActions } from '../../redux/api';
import DialogTitle from '../DialogTitle';

export const parseThreshold = (values, vitalsConfig) => {
  const thresholds = {};

  if (!values) {
    return thresholds;
  }

  Object.values(vitalsConfig).forEach((v) => {
    const current = values.find((t) => v.threshold_code === t.function);
    if (current) {
      thresholds[v.short_code] = current;
    }
  });

  return thresholds;
};

export const parseThresholdForPost = (values, vital, thresholdsCode) => {
  const parsedThresholds = [];
  if (vital === 'BP') {
    const currentObj = {};
    if (values.min_tensionLow) {
      currentObj.lowerRange_5 = values.min_tensionHigh;
    }
    if (values.max_tensionLow) {
      currentObj.upperRange_5 = values.max_tensionHigh;
    }
    if (values.min_tensionHigh) {
      currentObj.lowerRange_1 = values.min_tensionLow;
    }
    if (values.max_tensionHigh) {
      currentObj.upperRange_1 = values.max_tensionLow;
    }
    parsedThresholds.push({
      function: 'V_BP',
      ...currentObj,
    });
  } else {
    thresholdsCode.forEach((t) => {
      const obj = {
        function: t.code,
        lowerRange_1: values[`min_${t.shortCode}`],
        upperRange_1: values[`max_${t.shortCode}`],
      };
      if (values[`advancedMode_${vital}`]) {
        obj.lowerRange_2 = values[`min_mod_${t.shortCode}`];
        obj.upperRange_2 = values[`max_mod_${t.shortCode}`];
        obj.lowerRange_3 = values[`min_low_${t.shortCode}`];
        obj.upperRange_3 = values[`max_low_${t.shortCode}`];
      }
      parsedThresholds.push(obj);
    });
  }

  return parsedThresholds;
};

const thresholdValidations = (vital, thresholdCodes) => {
  const validationSchema = yup.object();

  if (vital === 'BP') {
    validationSchema.concat(yup.object().shape({
      min_tensionLow: yup.number().min(0),
      min_tensionHigh: yup.number().min(0),
      max_tensionLow: yup.number().min(0),
      max_tensionHigh: yup.number().min(0),
    }));
  } else {
    thresholdCodes.forEach((code) => {
      let schema = yup.object().shape({
        [`min_${code}`]: yup.number().min(0),
        [`max_${code}`]: yup.number().min(0),
        [`min_mod_${code}`]: yup.number().min(0),
        [`max_mod_${code}`]: yup.number().min(0),
        [`min_low_${code}`]: yup.number().min(0),
        [`max_low_${code}`]: yup.number().min(0),
      });
      validationSchema.concat(schema);
    });
  }
  validationSchema[`advancedMode_${vital}`] = yup.boolean()
  return validationSchema;
};

export const getThresholdCodes = (shortCode, configs) => {
  const thresholdCodes = [];

  if (configs[shortCode].config && configs[shortCode].config.children) {
    configs[shortCode].config.children.forEach((child) => {
      if (!configs[child]) return;
      thresholdCodes.push({
        name: configs[child].name,
        code: configs[child].threshold_code,
        shortCode: child,
      });
    });
  } else if (configs[shortCode].threshold_code) {
    thresholdCodes.push({
      code: configs[shortCode].threshold_code,
      name: configs[shortCode].name,
      shortCode,
    });
  }

  return thresholdCodes;
};

const hasAdvancedData = (vital, formikValues) => {
  if (!formikValues) {
    return false;
  }
  return formikValues[`min_mod_${vital}`] ||
    formikValues[`max_mod_${vital}`] ||
    formikValues[`min_low_${vital}`] ||
    formikValues[`max_low_${vital}`];
};

export const thresholdInitValue = (vital, value, codes) => {
  const values = {};

  if (value) {
    if (vital === 'BP') {
      const data = value.BP;
      if (!data) return values;
      if (data.lowerRange_5) values.min_tensionHigh = data.lowerRange_5;
      if (data.upperRange_5) values.max_tensionHigh = data.upperRange_5;
      if (data.lowerRange_1) values.min_tensionLow = data.lowerRange_1;
      if (data.upperRange_1) values.max_tensionLow = data.upperRange_1;
    } else {
      codes.forEach((code) => {
        if (value[code]?.lowerRange_1) values[`min_${code}`] = value[code].lowerRange_1;
        if (value[code]?.upperRange_1) values[`max_${code}`] = value[code].upperRange_1;
        if (value[code]?.lowerRange_2) values[`min_mod_${code}`] = value[code].lowerRange_2;
        if (value[code]?.upperRange_2) values[`max_mod_${code}`] = value[code].upperRange_2;
        if (value[code]?.lowerRange_3) values[`min_low_${code}`] = value[code].lowerRange_3;
        if (value[code]?.upperRange_3) values[`max_low_${code}`] = value[code].upperRange_3;

      });
    }
  }


  values[`advancedMode_${vital}`] = hasAdvancedData(vital, values);

  return values;
};


const AdvancedFields = memo(({ config, formik, t, vital }) => (
  <>
    <Grid item xs={12} sm={6}>
      <TextField
        id={`min_mod_${config.shortCode}`}
        name={`min_mod_${config.shortCode}`}
        label={t('Moderate Min - ') + config.name}
        value={formik.values[`min_mod_${config.shortCode}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`min_mod_${config.shortCode}`] && formik.errors[`min_mod_${config.shortCode}`])}
        helperText={formik.touched[`min_mod_${config.shortCode}`] && formik.errors[`min_mod_${config.shortCode}`]}
        variant="outlined"
        type="number"
        fullWidth
        onChange={formik.handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id={`max_mod_${config.shortCode}`}
        name={`max_mod_${config.shortCode}`}
        label={t('Moderate Max - ') + config.name}
        value={formik.values[`max_mod_${config.shortCode}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`max_mod_${config.shortCode}`] && formik.errors[`max_mod_${config.shortCode}`])}
        helperText={formik.touched[`max_mod_${config.shortCode}`] && formik.errors[`max_mod_${config.shortCode}`]}
        variant="outlined"
        type="number"
        fullWidth
        onChange={formik.handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id={`min_low_${config.shortCode}`}
        name={`min_low_${config.shortCode}`}
        label={t('Low Min - ') + config.name}
        value={formik.values[`min_low_${config.shortCode}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`min_low_${config.shortCode}`] && formik.errors[`min_low_${config.shortCode}`])}
        helperText={formik.touched[`min_low_${config.shortCode}`] && formik.errors[`min_low_${config.shortCode}`]}
        variant="outlined"
        type="number"
        fullWidth
        onChange={formik.handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id={`max_low_${config.shortCode}`}
        name={`max_low_${config.shortCode}`}
        label={t('Low Max - ') + config.name}
        value={formik.values[`max_low_${config.shortCode}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`max_low_${config.shortCode}`] && formik.errors[`max_low_${config.shortCode}`])}
        helperText={formik.touched[`max_low_${config.shortCode}`] && formik.errors[`max_low_${config.shortCode}`]}
        variant="outlined"
        type="number"
        fullWidth
        onChange={formik.handleChange}
      />
    </Grid>
  </>
));

const renderTextField = (config, formik, t, vital) => (
  <>
    <Grid item xs={12} sm={6}>
      <TextField
        id={`min_${config.shortCode}`}
        name={`min_${config.shortCode}`}
        label={t('Min - ') + config.name}
        value={formik.values[`min_${config.shortCode}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`min_${config.shortCode}`] && formik.errors[`min_${config.shortCode}`])}
        helperText={formik.touched[`min_${config.shortCode}`] && formik.errors[`min_${config.shortCode}`]}
        variant="outlined"
        type="number"
        fullWidth
        onChange={formik.handleChange}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        id={`max_${config.shortCode}`}
        name={`max_${config.shortCode}`}
        label={t('Max - ') + config.name}
        value={formik.values[`max_${config.shortCode}`] || ''}
        onBlur={formik.handleBlur}
        error={Boolean(formik.touched[`max_${config.shortCode}`] && formik.errors[`max_${config.shortCode}`])}
        helperText={formik.touched[`max_${config.shortCode}`] && formik.errors[`max_${config.shortCode}`]}
        variant="outlined"
        type="number"
        fullWidth
        onChange={formik.handleChange}
      />
    </Grid>
    {formik.values[`advancedMode_${vital}`] && (
      <Suspense fallback={<Skeleton variant="rectangular" height={56} />}>
        <AdvancedFields config={config} formik={formik} t={t} vital={vital} />
      </Suspense>
    )}
  </>
);

const Threshold = ({ patientUuid, vital, open, onClose, name }) => {
  const { t } = useTranslation();
  const { vitalsConfig } = useSelector((state) => state.clinic);
  const thresholdCodes = getThresholdCodes(vital, vitalsConfig);
  const { threshold } = greyboxApiActions;
  const [addThreshold] = threshold.add();

  const { data, isLoading } = threshold.list({
    latest: true,
    localAccount: patientUuid,
    function__in: thresholdCodes.map((c) => c.code).join(','),
  }, { skip: !thresholdCodes });

  const formik = useFormik({
    initialValues: thresholdInitValue(vital, parseThreshold(data, vitalsConfig), thresholdCodes.map((c) => c.shortCode)),
    enableReinitialize: true,
    validationSchema: thresholdValidations(vital, thresholdCodes.map((c) => c.code)),
    onSubmit: (values) => {
      const p = parseThresholdForPost(values, vital, thresholdCodes);
      Promise.all(p.map((item) => addThreshold({
        body: {
          ...item,
          localAccount: patientUuid,
        },
      }))).then(() => {
        onClose();
      });
    },
  });

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle onClose={onClose}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Typography variant="h6">{t('Adjust thresholds for')} {name}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {[...Array(6)].map((_, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Skeleton variant="rectangular" height={56} />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Typography variant="h6">{t('Adjust thresholds for')} {name}</Typography>
          <FormControlLabel
            control={
              <Switch
                color="primary"
              />
            }
            name={`advancedMode_${vital}`}
            label={t('Enable Advanced Mode')}
            value={formik.values[`advancedMode_${vital}`]}
            onChange={() => formik.setFieldValue(`advancedMode_${vital}`, !formik.values[`advancedMode_${vital}`])}
          />
        </Box>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            {thresholdCodes.map((item) => (
              <React.Fragment key={item.code}>
                {renderTextField(item, formik, t, vital)}
              </React.Fragment>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('Cancel')}</Button>
          <LoadingButton loading={formik.isSubmitting} type="submit" variant="contained" autoFocus>
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};


export default Threshold;
