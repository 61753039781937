import { LoadingButton } from '@mui/lab';
import {
  Button, Dialog, DialogActions, DialogContent
} from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { greyboxApiActions } from '../../../redux/api';
import DialogTitle from '../../DialogTitle';
import MedicalAct from '../../form-inputs/MedicalAct';
import moment from 'moment';

const RegisterMedicalActDialog = ({patientUuid, open, handleClose }) => {
  const { t } = useTranslation();
  const { medicalAct } = greyboxApiActions;
  const { accountId } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
  const [addMedicalAct] = medicalAct.add();

  const formik = useFormik({
    initialValues: {
      medicalActType: '',
      note: '',
      start_datetime: null,
      end_datetime: null,
      duration: moment.duration(15, 'minutes'),
    },
    validationSchema: Yup.object({
      medicalActType: Yup.string().required(t('Required')),
      note: Yup.string(),
      start_datetime: Yup.date().nullable(),
      end_datetime: Yup.date().nullable(),
    }),
    onSubmit: (values) => {
      const body = {
        patient: patientUuid,
        hcp: accountId,
        clinic: clinic.id,
        medical_act_type: values.medicalActType,
        note: values.note,
      };
      if (values.duration) {
        body.end_datetime = moment()
        body.start_datetime = moment().subtract(values.duration, 'minutes')
      } else {
        body.end_datetime = values.end_datetime
        body.start_datetime = values.start_datetime
      }

      addMedicalAct({
        body,
        feedBack: {
          success: t('Follow-up registered'),
        },
      }).then(() => {
        handleClose();
      });
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={handleClose}>
        {t('Record a follow-up')}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MedicalAct formik={formik} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <LoadingButton type="submit" variant="contained" loading={formik.isSubmitting}>
            {t('Register')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RegisterMedicalActDialog;
